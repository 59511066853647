// top组件
import top from './top'
// middle组件
import middle from './middle'
// other 一些不属于首屏的组件 如评论弹窗
import other from './other' 

export default {
  ...top,
  ...middle,
  ...other
}
