
const actions = {
  initPreSearch({ commit }, payload) {
    const { language = {}, abtData = {}, pageType } = payload
    commit('assignState', { necessaryData: payload, language, abtData, pageType })
  },

  updateSearchPageData({ commit }, payload) {
    const { default: defaultWords, hot, paramsGoodsId, scene, store_code, trendHome, trendDetails, trendBoardData } = payload
    // language abtData 暂时不更新
    commit('updateNecessaryData', { default: defaultWords, hot: hot, paramsGoodsId, scene, store_code, trendHome, trendDetails, trendBoardData  })
  },
}

export default actions
