import { Toast } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter'

daEventCenter.addSubscriber({ modulecode: '1-8-7' })

/**
 * 超限
 */

const isFlashOverLimit = (data) => ['1', '2'].includes(data?.aggregateProductBusiness?.singleOrTotalOver) && !!data?.aggregateProductBusiness?.overLimitOriginPriceBuyTip
const isStockOverLimit = (data) => data?.aggregateProductBusiness?.stock_over == 1 && !!data?.aggregateProductBusiness?.overLimitOriginPriceBuyTip

const actions = {
  initOverLimitState({ commit, state }) {
    const carts = state.carts || []
    carts.forEach((item) => {
      if (isFlashOverLimit(item)) {
        commit('addFlashOverLimitId', item.id)
      }
      if (isStockOverLimit(item)) {
        commit('addStockOverLimitId', item.id)
      }
    })
  },
  showOverLimitToast({ state, commit, dispatch }, { id, isShowOverLimitToast = false }) {
    if (typeof window === 'undefined') return
    const carts = state.carts || []
    const overLimitState = state.overLimitState || {}
    const editItem = carts.find((item) => item.id === id)

    if (!editItem) {
      commit('removeFlashOverLimitId', id)
      commit('removeStockOverLimitId', id)
      return
    }
  
    const isShowFlashLimitToast = isFlashOverLimit(editItem) && !overLimitState.flashOverLimitId.has(editItem.id)
    const isShowStockLimitToast = isStockOverLimit(editItem) && !overLimitState.stockOverLimitId.has(editItem.id)
    const isRestFlashLimitToast = !isFlashOverLimit(editItem) && overLimitState.flashOverLimitId.has(editItem.id)
    const isResetStockLimitToast = !isStockOverLimit(editItem) && overLimitState.stockOverLimitId.has(editItem.id)
  
    if ((isShowFlashLimitToast || isShowStockLimitToast) && isShowOverLimitToast) {
      isShowFlashLimitToast && daEventCenter.triggerNotice({ daId: editItem?.aggregateProductBusiness?.singleOrTotalOver == '1' ? '1-8-7-57' : '1-8-7-58' })
      Toast({ content: editItem?.aggregateProductBusiness?.overLimitOriginPriceBuyTip, })
    }
  
    if (isRestFlashLimitToast) {
      commit('removeFlashOverLimitId', editItem.id)
    }
    if (isResetStockLimitToast) {
      commit('removeStockOverLimitId', editItem.id)
    }
  
    dispatch('initOverLimitState', carts)
  }
}

export default actions
