import { transformImg, cloneDeep } from '@shein/common-function'
import { mapRootGetters } from 'public/src/pages/goods_detail/utils/storeUtils.js'
import label_language from 'public/src/pages/common/search_words/components/search_by_image_scanning_result/label_language.js'

export default {
  namespaced: true,
  state: {},
  getters: {
    outfitBlockShow(state, getters, rootState, rootGetters) {
      const { recommend } = rootState.newProductDetail.coldModules || {}
      const realTimeReady = rootState.newProductDetail.common.realTimeReady
      if (!realTimeReady) return false
      const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend'] || {}
      const hideGtlAndOutfit = rootGetters['newProductDetail/common/hideGtlAndOutfit']
      const showNewGtlAndOutfit = rootGetters['newProductDetail/common/showNewGtlAndOutfit']
      const newGtlEntryInfo = rootGetters['newProductDetail/common/newGtlEntryInfo']
      const getTheLookInfoData = rootGetters['newProductDetail/common/getTheLookInfoData']
      const { outfitShowDataByAbt, gtlInOutFitByAbt } = unifiedRecommend || {}
      const { outfitsImg = {} } = recommend || {}
      let outfitImage = (outfitShowDataByAbt && outfitsImg?.url) ? transformImg({ img: outfitsImg?.url }) : '' // 是否展示 outfits 数据
      // 命中非成衣abt,不展示
      if (hideGtlAndOutfit) {
        return false
      }
      // 命中新搭配购后,根据数据源判断是否展示
      if (showNewGtlAndOutfit) {
        return !!newGtlEntryInfo
      }
      // TODO-staging: REMOVE abt[manyrecommend]全量后恒返回true，删除该判断
      if (unifiedRecommend?.nonRepetitive) {
        return (gtlInOutFitByAbt && !!getTheLookInfoData?.length) || !!outfitImage
      }
      return !!outfitImage
    },
    // // outfit 多语言
    // outfitLanguageMap(state, getters, rootState) {
    //   const { pageInfo } = rootState.newProductDetail.coldModules
    //   const { language } = pageInfo || {}
    //   return language && label_language(language)
    // },
    // 大图数据
    // topBigImageInfo(state, getters, rootState, rootGetters) {
    //   const { productInfo, pageInfo, commonInfo } = rootState.newProductDetail.coldModules || {}
    //   const {  detail = {} } = productInfo || {}
    //   const { language } = pageInfo || {}
    //   const { LAZY_IMG, IS_RW, GB_cssRight_rp } = commonInfo || {}
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   const { getEstimatedInfo, isSoldOut, promotionInfo, mallStock } = mapRootGetters(rootGetters, 'common', [
    //     'getEstimatedInfo',
    //     'isSoldOut',
    //     'promotionInfo',
    //     'mallStock'
    //   ])
    //   const abtPicNewCart = fsAbt?.picnewcart?.param?.picnewcart
    //   // 是否展示大图快速加车模块
    //   const isShowQuickAddBtn = () => {
    //     if (isSoldOut || mallStock === 0) return false
    //     if (['A', 'C'].includes(abtPicNewCart)) {
    //       return true
    //     }
    //     return false
    //   }

    //   // 是否打开加车弹窗
    //   const { skuInfo } = rootState.newProductDetail?.common || {}
    //   const saleAttrList = rootGetters['newProductDetail/common/saleAttrList']
    //   const hasAttrs = saleAttrList?.skcSaleAttr?.length
    //   let sku_code = hasAttrs ? skuInfo?.sku_code || '' : saleAttrList?.skuList?.[0]?.sku_code || ''
    //   const isOpenQuickView = (hasAttrs && !sku_code)
    //   const { flashGoods } = mapRootGetters(rootGetters, 'common', ['flashGoods'])

    //   // 是否显示以图搜图
    //   const abtPicSearchImage = fsAbt?.bigpicturesearch?.p?.bigpicturesearch === 'show'

    //   // 图搜时传的推荐规则
    //   const detailPicStrategy = fsAbt?.PicSearchUpgrade?.p?.PicSearchSave3 ?? ''
    //   let abtDetailPicStrategyRule = detailPicStrategy.includes('goodsid') ? detailPicStrategy.replace('goodsid', detail.goods_id) : detailPicStrategy

    //   return {
    //     language,
    //     LAZY_IMG,
    //     IS_RW,
    //     GB_cssRight_rp,
    //     promotionInfo,
    //     getEstimatedInfo,
    //     isShowQuickAddBtn: isShowQuickAddBtn(),
    //     isOpenQuickView,
    //     goods_id: detail.goods_id,
    //     isPaidUser: rootGetters['newProductDetail/isPaidUser'],
    //     flashGoods,
    //     abtPicSearchImage,
    //     abtDetailPicStrategyRule
    //   }
    // },
    // ar入口展示条件
    // showArEntry(state, getters, rootState, rootGetters) {
    //   const { tsp } = rootState.newProductDetail.coldModules || {}
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   const isProductSoldOut = rootGetters['newProductDetail/common/isSoldOut']
    //   if (typeof window !== 'undefined' && !!window?.gbCommonInfo?.CLOSE_AR_ENTRY) return false
    //   // if (typeof window !== 'undefined' && window.location.search.includes('openArEntry')) return true
    //   // 目标商品 + abt
    //   return !isProductSoldOut && tsp?.tsp?.[600012731] && fsAbt?.Tryinar?.param?.Tryinar === 'show'
    // },
    // 首个sku
    // firstSku(state, getters, rootState) {
    //   const { productInfo } = rootState.newProductDetail.coldModules || {}
    //   const { attrSize = {}, detail } = productInfo || {}
    //   return attrSize?.sale_attr_list?.[detail?.goods_id]?.sku_list?.find?.((item) => { return !!item?.sku_code })
    // },
    // 是否展示视频入口
    showVideoEntry(state, getters, rootState, rootGetters) {
      // const { category } = rootState.newProductDetail.coldModules || {}
      // const { tsp: { tsp = {} } = {} } = rootState.newProductDetail.coldModules || {}
      // const { parentCatsObj } = category || {}
      // const { Videotest, Evoluvideo } = rootGetters['newProductDetail/fsAbt'] || {}
      // if (parentCatsObj?.[3842] && Videotest?.param?.Videotest !== 'SHOW') {
      //   return false
      // }
      // if((tsp['60004616'] || tsp['60007185'] || tsp['60007512']) && Evoluvideo?.param?.Evoluvideo !== 'SHOW') {
      //   return false
      // }
      // return true
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const showEvolushein = fsAbt?.evolushein?.p?.evolushein === 'show'
      const { evoluColorList = {}, detail: { goods_sn = '' } = {} } = rootState.newProductDetail.coldModules.productInfo
      const isEvolushein = !!evoluColorList?.[goods_sn] && showEvolushein
      /* 
        1. 非 Evolushein 商品，有视频就展示
        2. 100000105查是否是Evolushein 商品，是的话满足：evolushein = show，才展示视频
      */
      return isEvolushein || !evoluColorList[goods_sn]
    },
    // 商品权益保障利益点
    // picInterestInfo(state, getters, rootState, rootGetters) {
    //   const { pictureinterest, Guarantee } = rootGetters['newProductDetail/fsAbt'] || {}
    //   // 排行榜
    //   const rankingListInfo = rootGetters['newProductDetail/TopOther/rankingListInfo'] || {}
    //   const showRankingInfoLink = rootGetters['newProductDetail/TopOther/showRankingInfoLink'] || false
    //   //品牌
    //   // TODO yidier
    //   const brandMapInfo = rootGetters['newProductDetail/common/brandMapInfo']
      
    //   const isBrandHasTargetTag = rootGetters['newProductDetail/common/isBrandHasTargetTag']
    //   // 安全购
    //   const showShippingGuarantee = Guarantee?.p?.Guarantee === 'show'
    //   // 免费退货
    //   const freeReturnPolicy = !!(rootState.newProductDetail.hotModules?.shipping?.freeReturnPolicy * 1)
    //   // 是否展示权益图
    //   let picInterestAbt = pictureinterest?.p?.pictureinterest
    //   let showPicInterest = ['newlast', 'newfour', 'outfitlast'].includes(picInterestAbt)
      
    //   let data = [
    //     { key: 'ranking', value: showRankingInfoLink, type: 'rank' },
    //     { key: 'brand', value: isBrandHasTargetTag, type: 'brand' },
    //     { key: 'green', value: showShippingGuarantee, type: 'security' },
    //     { key: 'green', value: freeReturnPolicy, type: 'return' },
    //   ]
    //   const interestList = data.filter(item => item.value) || []
    //   // 主题色
    //   const pictureTheme = interestList[0]?.key || ''
    //   const cardCount = interestList.length || 0

    //   // 权益卡片类型
    //   let cardType = ''
    //   switch (cardCount) {
    //     case 3:
    //     case 4: 
    //       cardType = 'little'
    //       break
    //     case 2: 
    //       cardType = 'medium'
    //       break
    //     case 1: 
    //       cardType = 'large'
    //       break
    //     default:
    //       showPicInterest = false
    //       break
    //   }

    //   const pictureLogo = showRankingInfoLink && (cardCount === 4 ? 'picture-interest-star-8e25e0eaed' : 'picture-interest-vector-2cb542bbd9')
    //   const { PUBLIC_CDN = '', IS_RW } = rootState.newProductDetail.coldModules.commonInfo || {}
      
    //   if(IS_RW) {
    //     showPicInterest = false
    //   }
    //   return {
    //     showPicInterest,
    //     picInterestAbt,
    //     showRankingInfoLink,
    //     rankingListInfo,
    //     isBrandHasTargetTag,
    //     brandMapInfo,
    //     showShippingGuarantee,
    //     freeReturnPolicy,
    //     pictureTheme,
    //     cardType,
    //     pictureLogo,
    //     PUBLIC_CDN,
    //     interestList,
    //   }
    // },
    // 视频链接地址
    videoSourceData(state, getters, rootState) {
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { goods_imgs = {} } = productInfo || {}

      const { link, link_expiration_time_ts } = goods_imgs?._videoSourceData || {}
      if (!link || !link_expiration_time_ts) return {}
      // 在客户端判断视频是否过期
      const _isValid = Number(link_expiration_time_ts) >= Number(new Date())
      if (!_isValid) return {}
  
      return { link }
    }
  }
}
