import schttp from 'public/src/services/schttp'
const CACHE = {
  bills: {}
}
const { SiteUID, appLanguage } = gbCommonInfo

function queryOrderDetail(billno) {
  return new Promise(resolve => {
    if (CACHE.bills[billno] === undefined) {
      schttp({
        url: '/api/orders/base/get_order_info/get',
        params: {
          billno: billno
        },
        schttp: { needLogin: true }
      }).then(res => {
        if (res.code == 0 && res.info && res.info.order) {
          CACHE.bills[billno] = res.info.order
          resolve(res.info.order)
        }
      })
    } else {
      resolve(CACHE.bills[billno])
    }
  })
}
export const getOrderDoublePoints = async billno => {
  const orderShowInfo = await queryOrderDetail(billno)
  return orderShowInfo
}
/**
 * 现金支付取消订单挽留文案
 */
export const retainPayMethodLang = (language = {}, paymentMethod) => {
  let retainLang
  switch (paymentMethod) {
    case 'adyen-konbini':
      retainLang = language.SHEIN_KEY_PWA_18741
      break
    case 'dlocal-oxxo':
      retainLang = language.SHEIN_KEY_PWA_18745
      break
    case 'ebanx-baloto':
      retainLang = language.SHEIN_KEY_PWA_18749
      break
    case 'ebanx-oxxo':
      retainLang = language.SHEIN_KEY_PWA_18744
      break
    case 'ebanx-boleto':
      retainLang = language.SHEIN_KEY_PWA_18747
      break
    case 'routepay-oxxo':
      retainLang = language.SHEIN_KEY_PWA_18745
      break 
    case 'routepay-konbini':
      retainLang = language.SHEIN_KEY_PWA_18741
      break
    // case 'ebanx-spei':
    //   retainLang = language.SHEIN_KEY_PWA_16824
    //   break;
    default:
      retainLang = language.SHEIN_KEY_PWA_18743
      break
  }
  return retainLang
}
/**
 * waiting for update 提示语
 */
export const waitingForUpdateTips = (language = {}, paymentMethod, isRW) => {
  let tips
  switch (paymentMethod) {
    case 'adyen-konbini':
      tips = language.SHEIN_KEY_PWA_18748
      break
    case 'dlocal-oxxo':
      tips = language.SHEIN_KEY_PWA_18746
      break
    case 'ebanx-oxxo':
      tips = language.SHEIN_KEY_PWA_18746
      break
    case 'ebanx-baloto':
      tips = language.SHEIN_KEY_PWA_18752
      break
    case 'ebanx-boleto':
      tips = language.SHEIN_KEY_PWA_18751
      break
    case 'ebanx-spei':
      tips = language.SHEIN_KEY_PWA_18740
      break
    case 'PayPal-paypal':
      tips = isRW ? language.SHEIN_KEY_PWA_17312 : language.SHEIN_KEY_PWA_17206
      break
    case 'routepay-oxxo':
      tips = language.SHEIN_KEY_PWA_18746
      break
    case 'routepay-konbini':
      tips = language.SHEIN_KEY_PWA_18748
      break
    default:
      tips = language.SHEIN_KEY_PWA_18742
      break
  }
  return tips
}

// 获取b2b2c文案
function queryOrderStatusText() {
  let storageKey = 'ORDER_STATUS_TEXT'
  let textKey = `${SiteUID}_${appLanguage}_key`
  let allSiteCacheData = null
  let curSiteCacheData = {
    textInfo: [],
    currentTime: 0
  }
  try {
    allSiteCacheData = JSON.parse(localStorage.getItem(storageKey))
    curSiteCacheData = {
      ...curSiteCacheData,
      textInfo: allSiteCacheData?.[textKey] || [],
      currentTime: allSiteCacheData?.currentTime || 0
    }
  } catch (err) {
    curSiteCacheData = {
      textInfo: [],
      currentTime: 0
    }
  }
  return new Promise((resolve, reject) => {
    let { currentTime = 0, textInfo = [] } = curSiteCacheData
    let expired = currentTime + 2 * 60 * 60 * 1000 < new Date().getTime()
    if (textInfo?.length && !expired) {
      resolve(textInfo)
    } else {
      schttp({
        url: '/api/orders/base/getOrderStatusText/get'
      })
        .then(res => {
          if (res?.code == '1000') {
            let result = allSiteCacheData || {}
            if (!expired) {
              result = {
                ...result,
                [textKey]: res?.data?.orderStatusNoticeDetails || []
              }
            } else {
              result = {
                ...result,
                [textKey]: res?.data?.orderStatusNoticeDetails || [],
                currentTime: new Date().getTime()
              }
            }
            localStorage.setItem('ORDER_STATUS_TEXT', JSON.stringify(result))
            resolve(result?.[textKey] || [])
          } else {
            resolve([])
          }
        })
        .catch(() => {
          reject([])
        })
    }
  })
}

function queryCodFrontOrderInfo(params) {
  return new Promise((resolve) => {
    schttp({
      url: '/api/orders/base/queryCodFrontOrderInfo/query',
      params,
      schttp: { needLogin: true }
    })
      .then(res => {
        resolve(res || {})
      })
      .catch(() => {
        resolve({})
      })
  })
}

function submitCodOrderAuditResultsInfo(params = {}) {
  return new Promise((resolve) => {
    schttp({
      method: 'POST',
      url: '/api/orders/base/submitCodOrderAuditResults/update',
      data: params,
      schttp: { needLogin: true }
    })
      .then(res => {
        resolve(res || {})
      })
      .catch(() => {
        resolve({})
      })
  })
}

export const getOrderStatusText = async () => {
  const textInfo = await queryOrderStatusText()
  return textInfo
}

export const transformPercentList = percentList => {
  if (!percentList?.length) return percentList
  let list = JSON.parse(JSON.stringify(percentList))
  list.map(item => {
    item.day = item?.day || item?.detail_time || ''
    item.percent = item.percent || item?.detail_time_percentage || ''
    return item
  })
  return list
}

export const queryCodFrontOrderInfoFun = async (order = {}) => {
  const { codRiskInfo = {} } = order || {}
  const { billNo = '', riskClass = '' } = codRiskInfo || {}
  const orderInfo = await queryCodFrontOrderInfo({
    billno: billNo,
    risk_type: riskClass,
    doc_type: 'risk_type_copy_config'
  })
  return orderInfo
}

export const submitCodOrderAuditResultsFun = async params => {
  const orderInfo = await submitCodOrderAuditResultsInfo(params)
  return orderInfo
}

export const getRefundReasonListEvt = async params => {
  let data = await schttp({
    url: `/api/orders/base/getRefundReasonList/get`,
    params: params
  })
  return data?.info?.reasonLanguages || []
}

/*
 *根据不同的订单状态操作按钮展示不同的顺序
 * 0 1 2 3 4 5 6 8 12 13 16 :Pay now/verify now Edit Address review/review already return item track revoke/Cancel order Repurchase
 *
 * 7:Confirm delivery  review/review already return item Repurchase  track
 * 10/18 Confirm delivery track return item review/review already
 * 9 11 Repurchase track review/review already
 * 14 15 track review/review already Repurchase
 * 17 19 track Repurchase review/review already
 */
// 展示优先级在【Confirm Delivery】、【Track】后,如果当前状态的订单不支持【Confirm Delivery】、【Track】，则试用报告上传按钮优先级最高
export function getOrderButtonSorts({
  orderStatus,
  order_type,
  xtra_order_scene
} = {}) {
  // 付费会员展示操作项
  if ([5, 6]?.includes(+order_type)) {
    // 不展示操作项
    if (![0, 12, 13]?.includes(+orderStatus)) {
      return []
    }
    // 展示pay now跟cancel order
    if (xtra_order_scene == 1 || order_type == 5) {
      return ['pay_now', 'cancel_order']
    }
    // 展示pay now跟edit address
    if (xtra_order_scene == 2) {
      return ['pay_now', 'cancel_order', 'edit_address']
    }
  }
  let sorts = []
  switch (+orderStatus) {
    case 7:
      sorts = [
        'one_click_pay',
        'resume_shipment',
        'confirm_delivery',
        'review',
        'return_item',
        'my_review',
        'urgent_pickup',
        'view_invoices',
        'repurchase',
        'track',
        'upload_report'
      ]
      break
    case 4:
      sorts = [
        'one_click_pay',
        'resume_shipment',
        'edit_address',
        'track',
        'urge_shipment',
        'exchange',
        'view_invoices',
        'revoke',
        'cancel_order',
        'repurchase'
      ]
      break
    case 10:
    case 18:
      sorts = [
        'one_click_pay',
        'resume_shipment',
        'track',
        'urge_delivery',
        'edit_address',
        'confirm_delivery',
        'review',
        'return_item',
        'upload_report',
        'view_invoices',
        'my_review',
        'repurchase'
      ]
      break
    case 9:
      sorts = [
        'one_click_pay',
        'repurchase',
        'track',
        'upload_report',
        'review',
        'my_review'
      ]
      break
    case 11:
      sorts = [
        'one_click_pay',
        'repurchase',
        'track',
        'upload_report',
        'review',
        'my_review',
        'view_invoices'
      ]
      break
    case 14:
    case 15:
      sorts = [
        'one_click_pay',
        'urgent_pickup',
        'track',
        'upload_report',
        'review',
        'my_review',
        'view_invoices',
        'repurchase'
      ]
      break
    case 17:
    case 19:
      sorts = [
        'one_click_pay',
        'track',
        'repurchase',
        'upload_report',
        'review',
        'my_review',
        'view_invoices'
      ]
      break
    default:
      sorts = [
        'pay_now',
        'verify_now',
        'one_click_pay',
        'resume_shipment',
        'edit_address',
        'review',
        'return_item',
        'my_review',
        'urgent_pickup',
        'track',
        'urge_shipment',
        'revoke',
        'cancel_order',
        'repurchase',
        'upload_report',
        'view_invoices'
      ]
      break
  }

  return sorts
}

export const queryRefundOrderExpireAmountStatusEvt = async (billList)=>{
  const data = await schttp({
    method: 'POST',
    url: '/api/user/wallet/RefundOrderExpireAmountStatus/get',
    data: { 
      bill_no_list: billList,
    },
  })
  return data || {}
}
