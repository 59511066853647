const getters = {
  newRecommendStyles(state) {
    const style = state.cartAbtInfo.cartNewRecommend?.param?.cart_newrecommend_style
    if (!style || style === 'off') return []
    return style.split(',')
  },
  showNewGoodsRecommendStyleA(state, getters) {
    if (state.recommendState.newRecommendInfo) {
      return state.recommendState.newRecommendInfo.isAStyle === '1' && !!state.recommendState.newRecommendInfo.newRecommendItemList?.length
    }
    return state.recommendState.showNewGoodsRecommend && getters.newRecommendStyles.includes('A')
  },
  showNewGoodsRecommendStyleB(state, getters) {
    if (state.recommendState.newRecommendInfo) {
      return state.recommendState.newRecommendInfo.isBStyle === '1' && !!state.recommendState.newRecommendInfo.newRecommendItemList?.length
    }
    return state.recommendState.showNewGoodsRecommend && getters.newRecommendStyles.includes('B')
  },
  showNewGoodsRecommendStyleC(state, getters) {
    if (state.recommendState.newRecommendInfo) {
      return state.recommendState.newRecommendInfo.isCStyle === '1' && !!state.recommendState.newRecommendInfo.newRecommendItemList?.length
    }
    return state.recommendState.showNewGoodsRecommend && getters.newRecommendStyles.includes('C')
  },
  newGoodsRecommendFlagId(state) {
    const { newRecommendItemList } = state.recommendState.newRecommendInfo || {}
    if (!newRecommendItemList?.length) return ''
    return state.cartIds.find((id, index) => {
      if (index === 0) return false
      return !!newRecommendItemList.find(item => item.cartItem.id === id)
    })
  },
}

export default getters
