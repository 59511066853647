let _retryScrollTimer = null
let _retryCount = 0

function findFirstMatchingElement(selectors) {
  for (const selector of selectors) {
    const element = document.querySelector(selector)
    if (element) {
      return element
    }
  }
  return null
}
  
// 加车成功页面滚动
export function goodsDetailScroll(options) {
  clearTimeout(_retryScrollTimer)
  
  const { otherOptionsAbt = 'typeB', isReview, ignoreSelectors = [], shouldCartEntryScroll = false } = options || {}
  const classHook = isReview ? '.j-header-bag-is-review' : '.j-header-bag'
  const $headerBag = document.querySelector(classHook)
  
  // 推荐元素选择器列表（顺序代表优先级）
  // 若最尾部楼层有推荐组件，无论有无you may also like，均定位到推荐组件
  // 组合购组件，在推荐组件中优先级最高
  // 加车后如果购物车组件出现，需添加进推荐元素选择器列表，符合优先级的话进行滚动
  
  const rawSelectors = ['[data-floor-tab="newGetTheLook"]', '[data-floor-tab="comboBuy"]']
  if ( otherOptionsAbt === 'family2' ) {
    rawSelectors.push('[data-floor-tab="getTheLook"]', ...(shouldCartEntryScroll ? ['[data-floor-tab="cartEntry"]'] : []), '[data-floor-tab="styleMatching"]')
  }
  rawSelectors.push('[data-floor-tab="cccBelowCommentFloor"]', '[data-floor-tab="youMayAlsoLike"]')
  // 过滤要被忽略的推荐元素
  const selectors = rawSelectors.filter(item => !ignoreSelectors.includes(item))
  // 存在忽略的推荐元素的情况下，推荐内容可能没有完整暴露，需要强制滚动
  const isForceScroll = ignoreSelectors.length > 0
  
  const targetEl = findFirstMatchingElement(selectors)
  
  const lazyLoadDomSelectors = ['[data-floor-tab="youMayAlsoLike"]']
  if (!targetEl && selectors.some(selector => lazyLoadDomSelectors.includes(selector))) {
    // 存在懒加载组件，强制刷新后尝试重新滚动
    appEventCenter.$emit('allLazyComponentRender')
    const MAX_RETRY_COUNT = 5
    if (_retryCount >= MAX_RETRY_COUNT) return // 重试次数超限，不再重试
    console.warn('retry scrollrec count', _retryCount)
    _retryScrollTimer = setTimeout(() => {
      goodsDetailScroll(options)
      _retryCount++
    }, 1500)
  
    return
  }
  _retryCount = 0
  
  $headerBag?.classList?.remove('bounce')
  const isActive = document.querySelector('#j-floor-tab li[key-one="recommend"]')
    ?.classList.contains('floor-tab__item_active') ?? false
  if (!isActive || isForceScroll) {
    // 因历史问题头部未占位，头部 fixed 后导致滚动距离不准确，增加判断是否 fixed 来计算滚动距离
    const headerIsFixed = true
    const detailHeight = document.querySelector('.j-common-logo-header')?.offsetHeight ?? 0
    const floorTabHeight = document.querySelector('#j-floor-tab')?.offsetHeight ?? 0
  
    if (targetEl) {
      // 可能推荐位不存在，dom获取null
      setTimeout(() => {
        // 修复 快速加车成功后不能定位到推荐位
        const targetElTop = targetEl?.getBoundingClientRect().top || 0
        const srollY = window.scrollY
        const scrollDistance =
            targetElTop +
            srollY -
            (headerIsFixed ? detailHeight + 1 : detailHeight * 2 + 1) -
            floorTabHeight +
            2
        window.scrollTo({ top: scrollDistance, behavior: 'smooth' })
      }, 0)
    }
  }
}
