export default {
  namespaced: true,
  state: {},
  getters: {
    goodsName(state, getters, rootState, rootGetters) {
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      return (cacheScreenData && cacheScreenData.goods_name) || rootState.newProductDetail.coldModules.productInfo.detail?.goods_name
    },
    isBlack(state, getters, rootState) {
      const { pageComponents = {} } = rootState.newProductDetail.coldModules.ccc || {}
      return pageComponents.headNameConf?.isBlack
    },
    /** 展示优选卖家标签 */
    // showPreferredSeller(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   const GoodsPreferredSeller = fsAbt?.GoodsPreferredSeller || {}
    //   return GoodsPreferredSeller?.param === 'type=B'
    // },
    // isPreferredSeller(state, getters, rootState, rootGetters) {
    //   const showStore = rootGetters['newProductDetail/common/showStore']
    //   if (!showStore) return false
    //   const { labelList = [] } = rootState.newProductDetail.coldModules.store || {}
    //   // const showPreferredSeller = getters.showPreferredSeller
    //   const hasPreferredSeller = labelList?.find?.(label => label.labelCode === 'preferredSeller')
    //   return Boolean(hasPreferredSeller)
    // },
    badgeInfo(state, getters, rootState) {
      const { beltLabelsAndBadges = {} } = rootState.newProductDetail.coldModules.tsp
      const { cccTspBadges = {} } = beltLabelsAndBadges
      return cccTspBadges.badges?.BEFORE_NAME?.[0] || null
    },
  },
}
