
export const EShippingBeltType = Object.freeze({
/**
 * 默认免邮腰带
 */
  default: 0,
  /**
 * qs强化 / qs强化 + 免邮
 */
  qsStrong: 1,
  /**
* qs强化只有免邮
*/
  qsStrongFreeShipping: 2,
})
