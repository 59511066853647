// import DETAIL_API_LOCATION from 'public/src/pages/product_app/store/modules/product_detail/api.js'
import schttp from 'public/src/services/schttp'
import { isSwitchBffDetail } from 'public/src/pages/common/bff-new/index.js'
import { getCookie } from '@shein/common-function'

const _gbCommonInfo = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

/**
 * todo
 * 迁移自
 * controllers/product_detail/format/utils/index.js => getConfigInfo
 */
export const getDetailCommonInfo = () => {
  const {
    PUBLIC_CDN: PUBLIC_CDN_rp,
    SiteUID,
    WEB_CLIENT,
    LAZY_IMG,
    IMG_LINK,
    IS_RW,
    lang: lang_rp,
    langPath: langPath_rp,
    appLanguage,
    GB_cssRight: GB_cssRight_rp,
    host: host_rp,
    RESOURCE_SDK,
    SIZE_TIPS_CONFIG,
    is_include_critical_css,
    currency,
  } = _gbCommonInfo

  const sizeTipsConfig = SIZE_TIPS_CONFIG || {
    max: 30,
    min: 5,
    catIds: [],
    heightCatIds: [],
    quickAddShowTips: 1,
    showTaxation: 0,
    estimatedPriceConfig: [],
    trueToSizeStrongCatId: []
  }

  const originalUrl = typeof location !== 'undefined' ? `${location.pathname}${location.search}` : ''
  // 如果有属性才去做相关更新
  const commonInfo = {
    originalUrl,
    PUBLIC_CDN_rp,
    SiteUID,
    WEB_CLIENT,
    LAZY_IMG,
    IMG_LINK,
    IS_RW,
    lang_rp,
    langPath_rp,
    appLanguage,
    GB_cssRight_rp,
    host_rp,
    RESOURCE_SDK,
    sizeTipsConfig,
    is_include_critical_css,
    currency,
  }
  return commonInfo
}

/**
 * 获取商详页所需的所有多语言
 */
export const getDetailMultiLanguageBff = async () => {
  const res = await schttp({ // todo 暂时不用bff的多语言服务
    url: '/api/common/i18nBatch/get',
    params: {
      pages: ['product_detail', 'coupon_item', 'product_item']
    },
    timeout: 10000
  })
  const languageMap = Object.values(res)?.reduce((map, value) => {
    map = { ...map, ...value }
    return map
  }, {})

  languageMap._DONE = true
  languageMap._BFF = false

  return languageMap

  // const res = await schttp({ // todo本地vite启动会请求参数有问题？node请求没问题
  //   method: 'POST',
  //   url: DETAIL_API_LOCATION.MULTI_LANGUAGE,
  //   data: {
  //     pages: ['product_detail', 'coupon_item', 'product_item']
  //   },
  //   useBffApi: true,
  // })
  // const languageMap = res?.info?.result || {}

  // languageMap._DONE = true

  // return languageMap
}


/**
 * 客户端是否开启BFFfs
 */

export const isUseBffFs = () => {
  return !!(isSwitchBffDetail(['vBFF_FS'])?.vBFF_FS)
}
