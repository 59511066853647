import { formatFreeShipping } from 'public/src/pages/cart_v2/components/cartPromotionBottom/components/utils.js'
import { isEmpty } from '@shein/common-function'

const getters = {
  // 是否展示新的活动底部bar
  isShowNewPromoBar(state) {
    return ['planA', 'planA_2', 'planB', 'planB_2'].includes(state.cartAbtInfo?.promotionCouponMix?.param?.promotion_coupon_mix)
  },
  bottomPromotions(state, getters) {
    const bottomPromotionContentData = (state.promotionState.bottomPromotionData?.[0]?.contentData || []).map((v) => ({
      ...v,
      sortAbt: v.groupHeadInfo?.data?.sortDoublePriorityInAbt,
    }))

    if (getters.cartShippingTop) return bottomPromotionContentData    
    const freeShip = formatFreeShipping({
      isPlatForm: state.isPlatForm,
      isShowNewFreeShip: getters.isShowNewFreeShip,
      mallCode: getters.singleMallCode,
      mallShippingInfo: state.mallShippingInfo,
      sortDoubleFreeShippingPriorityInAbt: state.promotionState.sortDoubleFreeShippingPriorityInAbt,
    })
    return freeShip?.freeShippingTips ? [freeShip, ...bottomPromotionContentData] : bottomPromotionContentData
  },
  showNewPromoBar(state, getters) {
    return getters.isShowNewPromoBar && !isEmpty(state.promotionState.promotionCouponMix)
  },
  showOldPromoBar(state, getters) {
    return !getters.isShowNewPromoBar && getters.bottomPromotions?.length
  },
}

export default getters
