export const USE_BFF_API_KEYS = [
  'cart_v4', // 需要放在第一个位置
]

export const CART_CLICK_UN_PAID_ORDER_INFO = 'cart_click_unpaid_order_info'

export const POSKEYS = {
  posKeys: [
    'Aod',
    'CccEmptyCart', 
    'OpenCartCouponHelpernew',
    'CartCouponHelpernew',
    'CartGetCoupons',
    'TradeQuickship',
    'Discountlistshowlimitexceededtip',
    'Cartnoteligibleforcoupons',
    'Add',
  ],
  newPosKeys: [
    'componentswitch',
    'recmultiCard',
    'detailgoodsCard',
    'CartAddress',
    'BannerDistrictimage',
    'OrderPageCouponTest',
    'UnderPrice',
    'LowpriceUser',
    'ReturnCouponShow',
    'ShowPromotion',
    'Cartcouponadd',
    'CouponAddToCartToast',
    'recSwitch',
    'addJson',
    'addnewhot',
    'addnewhotJson',
    'EstimatedDetailSwitch',
    'CouponHelperAddMultiple',
    'Cartshowcoupon',
    'Quickshiprecommendthreshold',
    'SheinFindSimilar',
    'Couponaddall',
    'brTaxhint',
    'ShopLineRetention',
    'UserBehaviorTips',
    'CartGoodsLabel',
    'TradeEventAtmosphere',
    'CartActivityColor',
    'TaxSwitch',
    'CartNotCheckedEstimate',
    'CartrowEstimatedCountdown',
    'DiscountInfoSwitch',
    'Discountlogo',
    'CartShare',
    'EstimatedPrice',
    'cartshippingaddpriority',
    'cartSelect',
    'orderCouponNewUser',
    'CartBottomCopy',
    'PayinCart',
    'CartQuickshipVisual',
    'IncentiveInform',
    'cartPromotionsPriority',
    'QSinformation',
    'CartShippingTopSwitch',
    'bottomNumberStyle',
    'evoluSHEINShow',
    'Cartstoreinrow',
    'CancelledorderTip',
    'cartDiscountStyle',
    'storeiconchange',
    'Cartrefund',
    'cartPromotions',
    'detailPriceCountDown',
    'LowestPriceDays',
    'paypalCartSwitch',
    'CartaddGoodsdetailSwitch',
    'defaultlocalsize',
    'cartRightsFuns',
    'CartNewCouponlist',
    'cartPriceStyle',
    'outofstocktips',
    'cartRecommendGuide',
    'promotionCouponMix',
    'CartOOSGoodsPosition',
    'PaymentSafetyExperiment',
    'PaymentSecurity',
    'cartRightsFreereturn',
    'cartGoodsAddPromos',
    'cartShowStoreCode',
    'cartShowBrand',
    'cartStoreFreeGift',
    'cartRightsFreereturn',
    'cartRightsLabel',
    'promotionCouponMix',
    'ActivityOver',
    'CartRecommendTab',
    'CartrowWishlistIcon',
    'NewCartrowVision',
    'CartOrderSFSVisual',
  ]
}
