
import { EShippingBeltType } from 'public/src/pages/goods_detail_v2/utils/shipping'
export default {
  namespaced: true,
  getters: {
    /**
     * 促销腰带数据模型 skc纬度、sku纬度
     */
    promotion_belt(state, getters, rootState, rootGetters) {
      const skuInfo = rootGetters['productDetail/common/sku_info']
      const goods_id = rootGetters['productDetail/common/goods_id']
      const data = skuInfo?.beltInfo?.promotionBelt || rootState.productDetail.hotModules?.beltInfo?.promotionBelt
      if (!data) return
      return {
        ...(data || {}),
        _goods_id: goods_id, // 商品id，用于埋点判断是否为相同skc
      }
    },
    // 显示促销腰带
    show_promotion_belt(state, getters, rootState, rootGetters) {
      const is_sold_out = rootGetters['productDetail/common/is_sold_out']
      if (is_sold_out) return false

      const promotionBelt = getters?.promotion_belt
      if (!promotionBelt) return false

      // 促销腰带类型 0-无(纯图) 1-折扣类型 2-倒计时类型 3-金额折扣 4-异形倒计时 5-异形折扣 6-普通可省金额 7-异形可省金额
      // 如果belt.type为1 / 5的时候，折扣为0就不展示
      const _isDiscountBelt = [1, 5].includes(Number(promotionBelt.type))
      if (!_isDiscountBelt) return true
      return Number(promotionBelt.discountPercent) !== 0
    },
    /**
     * 主图腰带是否展示了
     */
    belt_loaded (state, getters) {
      const { show_promotion_belt, show_free_shipping_belt } = getters
      return show_promotion_belt || show_free_shipping_belt
    },
    shipping_belt_info(state, getters, rootState, rootGetters) {
      // 三种包邮样式：freestyle：strip、addbag、button。腰带、加车按钮角标、吸底
      const { has_quickship_strong_abt, show_old_free_shipping } = getters

      const skuInfo = rootGetters['productDetail/common/sku_info']
      const skuList = rootGetters['productDetail/common/sku_list']
      const ship_info = rootGetters['productDetail/ship_info']
      const isSupportQuickShip = skuInfo ? skuInfo.isSupportQuickShip == 1 : !!skuList?.find?.(item => item.isSupportQuickShip == 1)

      const { shipBeltInfo } = ship_info || {}
      const { content, contentOfQuickShip } = shipBeltInfo || {}

      const res = {
        /**
         * 物流腰带类型
         */
        type: EShippingBeltType.default,
        /**
         * 物流腰带文案
         */
        content: '',
        /**
          新增曝光参数keyinformation：
          1.仅免邮信息
          2.仅quickship信息
          3.仅n日达信息
          4.免邮+quickship
          5.免邮+n日达
         */
        keyinformation: '',
      }

      // 无qs强化，走原有免邮逻辑
      if (!has_quickship_strong_abt) {
        // 非免邮
        if (!show_old_free_shipping || !content) {
          return res
        } else {
          res.content = content
          res.keyinformation = 1
          return res
        }
      }


      if (isSupportQuickShip) {
        res.content = contentOfQuickShip
        res.type = EShippingBeltType.qsStrong
        res.keyinformation = 1 // todo
      } else {
        res.content = content
        res.type = EShippingBeltType.qsStrongFreeShipping
        res.keyinformation = 1 // todo
      }

      return res
    },
    // 是否显示包邮腰带
    show_free_shipping_belt(state, getters) {
      return !!getters.shipping_belt_info?.content
    },
    // 物流腰带QS强化的判断
    has_quickship_strong_abt(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const ship_info = rootGetters['productDetail/ship_info']
      if (ship_info.hasQuickShipTime != 1) return

      const showQuick = fs_abt?.DetailPageQuickShipShow?.param == 'DetailShowQuick' // qs强化abt
      if (!showQuick) return

      // 初始化实验值  腰带N达具体值||腰带强化qs实验命中结果 和独立qs楼层互斥||腰带N达实验命中结果 和独立qs楼层互斥||腰带强化qs实验命中结果 和独立qs楼层共存||腰带N达实验命中结果 和独立qs楼层共存
      const { Quickshipdetailbannershow = '' } = fs_abt?.quickshipdetailshow?.p || {}

      return ['quickshipinde', 'quickshipcoex'].includes(Quickshipdetailbannershow) || Quickshipdetailbannershow.split('_index')?.length > 1 || Quickshipdetailbannershow.split('_coex')?.length > 1
    },
    /**
     * 旧免邮腰带
     */
    show_old_free_shipping(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const ship_info = rootGetters['productDetail/ship_info'] || {}

      // itemfreeshipping： 推全 freeremind
      const { freestyle = 'strip', freestyleactivity = 'Notshow' } = fs_abt?.itemfreeshipping?.p || {}

      if (freestyle !== 'strip') return
      const _hasFreeAll = ship_info.isProductShippingFree === '1'
      if (!_hasFreeAll) return
      const { freeShippingInfo } = ship_info || {}
      const isFreeStyleActivity = freeShippingInfo?.isProductFreeShipping === '1' || freestyleactivity === 'Show'
      if (!isFreeStyleActivity) return

      return true
    },
  }
}
