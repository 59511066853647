const getters = {
// 推荐的支付前置支付方式
  paymentFrontRecommendPayment(state, getters) {
    const bnplInfo = getters.payShowStyle == 'A' ? state.checkoutState.bnplCheckoutInfo?.checkoutBtnInfo || {} : state.checkoutState.bnplCheckoutInfo?.checkoutBubbleInfo || {}
    const paymentItemList = bnplInfo?.paymentItemList || []
    // 默认取推荐的支付方式，没有再取第一个进行兜底
    return paymentItemList.find(item => item.recommend == 1) || paymentItemList[0] || ''
  },
  // 支付前置展示样式
  payShowStyle(state) {
    return state.cartAbtInfo?.PayinCart?.param?.PayShow || 'off'
  },
  // 支付前置推荐支付方式CODE
  paymentFrontPaymentCode(state, getters) {
    return getters.payShowStyle == 'A' && getters.paymentFrontRecommendPayment?.paymentCode || ''
  },
  // 支付前置支付方式列表
  paymentFrontPaymentList(state, getters) {
    const bnplInfo = getters.payShowStyle == 'A' ? state.checkoutState.bnplCheckoutInfo?.checkoutBtnInfo || {} : state.checkoutState.bnplCheckoutInfo?.checkoutBubbleInfo || {}
    return (bnplInfo?.paymentItemList || []).filter(f => !!f.logoUrl)
  },
  // 支付前置展示样式A
  payShowStyleA(state, getters) {
    return getters.payShowStyle === 'A' && getters.paymentFrontRecommendPayment && state.checkoutState.bnplCheckoutInfo?.checkoutBtnInfo?.totalPrice?.amount > 0 ? true : false
  },
  // 支付前置展示样式B
  payShowStyleB(state, getters) {
    return getters.payShowStyle === 'B' && getters.paymentFrontRecommendPayment && state.checkoutState.bnplCheckoutInfo?.checkoutBubbleInfo?.tipText ? true : false
  },
  // pageId=1391863655
  showPaypalBtn(state, getters) {
    let noLogin = !state.loginStatus
    let paypalCartSwitchStatus = state.cartAbtInfo?.paypalCartSwitch?.param?.paypalCartSwitchStatus || 0
    return noLogin && (paypalCartSwitchStatus == 2 || (!getters.payShowStyleA && paypalCartSwitchStatus == 1))
  },
}

export default getters
