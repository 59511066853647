/**
 * 超限
 */
const mutations = {
  addFlashOverLimitId({ overLimitState }, id) {
    overLimitState.flashOverLimitId.add(id)
  },
  addStockOverLimitId({ overLimitState }, id) {
    overLimitState.stockOverLimitId.add(id)
  },
  removeFlashOverLimitId({ overLimitState }, id) {
    overLimitState.flashOverLimitId.delete(id)
  },
  removeStockOverLimitId({ overLimitState }, id) {
    overLimitState.stockOverLimitId.delete(id)
  }
}

export default mutations
