import { getNewRecommendInfo } from '../api'

const action = {
  async getNewRecommendInfo({ state, commit }) {
    if (!state.recommendState.showNewGoodsRecommend) return
    const mainGoodsParams = state.carts
      .filter(f => f.aggregateProductBusiness?.newRecommendGoodsFlag === '1')
      .map(f => ({
        mallCode: f.mall_code,
        cartId: f.id,
        goodsId: f.product?.goods_id,
      }))
      .filter(f => f) || []
    let res = await getNewRecommendInfo({ autoUseCoupon: state.auto_use_coupon }, { mainGoodsParams })
    if (res?.code === '0') {
      commit('updateNewRecommendInfo', res.info)
    } else {
      commit('updateNewRecommendInfo', null)
    }
  },
}

export default action
