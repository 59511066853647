import dialogState from './dialog'
import { getDefaultHotModules } from './defaultState.js'
export default {
  // 静态模型 缓存接口   模型分类按照 controllers/product_detail/format/modules/index.js
  // 注: 做节点自定义更新一定要在这里初始化加上默认值 如：pageInfo 否者导致响应式不生效
  coldModules: {
    brandAndSeries: {},
    otherOptions: {},
    productInfo: {},
    getTheLook: {},
    promotion: {},
    recommend: {},
    category: {},
    pageInfo: {
      fsAbt: {},
      cacheScreenFsAbt: null,
      showAgeLimit: false,
      metaInfo: {},
      language: {},
      productItemsLanguage: {},
      isSsr: false,
      googleSEO: null,
      closeEstimatedAndAbPrice: false,
      complianceMode: false,
      complianceTipsMode: false,
      complianceModeDe: false,
      DETAIL_SECOND_CONFIG: {}
    },
    shipping: {},
    comment: {},
    coupon: {},
    others: {},
    social: {},
    store: {},
    user: {},
    pay: {},
    tsp: {},
    ccc: {},

    // 暂时未分好模型的数据
    commonInfo: {}
  },

  // 动态模型 增量更新数据 由于动态模型是精准更新 这边初始化一定要加上默认值 否者导致响应式不生效
  hotModules: getDefaultHotModules(),

  cacheFsAbt: {},
  // ... 后续接口 一个接口一个字段

  // todo: 优惠卷混合PromitionCenter 暂时无奈之举 后续一定要改造都放到hotModules.promotion里面去⬇️
  promotionInfoWithCenter: [],
  relationGoodsWithCenter: [],
  // todo: ⬆️

  // storeRecommendInfo: {}, // 店铺推荐信息 包括老链路里面的字段 boardList, recommendFilterGoodsIds, recommendAdp,  showQuickTip
  storeBrandSeriesRecommendGroup: {}, // 店铺推荐系列信息

  // 是否展示ar试穿模块
  showTryOnArContent: false,
  getTheLookPdeInfo: {}, // TODO @Zack Shi
  isLogin: false,
  // 推荐位一键购失效状态
  showOneClickPayCardTimeStatus: true,
  // 各类弹窗状态 如: 评论 优惠卷等等 弹窗
  ...dialogState
}
