import CartGuide from '../CartGuide.js'

class BottomPromoGuide extends CartGuide {
  constructor({ priority } = {}) {
    super({
      priority: priority ?? 10,
    })
  }
}

const _bottomPromoGuide = typeof window !== 'undefined' ? window.__cartBottomPromoGuide__ || (window.__cartBottomPromoGuide__ = new BottomPromoGuide()) : {}

export default _bottomPromoGuide
