/**
 * 商详所有接口路由文件
 */
const DETAIL_API_LOCATION = {
  PRODUCT_DETAIL_DETAIL_IMAGE: '/product/get_goods_detail_image', // 细节图
  STORE_UPDATE_FOLLOWING: '/product/store/update_following', // 店铺关注
  PRODUCT_RANKING_INFO: '/ccc/rec/goods', // 榜单信息
  PRODUCT_DETAIL_RECOMMEND_INFO: '/product/detail/recommend/info', // 商详推荐信息
  PRODUCT_GET_NEW_COMPANION: '/product/get_new_companion_module', // 新搭配数据
  MULTI_LANGUAGE: '/system/configs/page_multi_language_mapping', // 多语言接口
  CCC_FEED_BACK: '/ccc/communal/switch', // ccc负反馈配置
  RECOMMEND_COLLECT_SIMILAR: '/product/detail/recommend/collect_similar', // 商详收藏后弹出similar推荐
  RECOMMEND_GET_TOGETHER_BETTER: '/product/get_together_better_deals_price', // 组合购 / gift 价格
  BATCH_CHECK_GOODS_STOCK: '/product/batch_check_goods_stock',                     // 批量校验商品库存数据
  BUYBOX_RECOMMEND: '/detail/recommend/multi_store_products', // buybox推荐
  PRODUCT_STATIC_INFO: '/product/get_goods_detail_static_data', // 商品详情静态信息
  COUPON_ADDITEMINFO: '/coupon/addItemInfo', // 交易中间层未满足门槛券的获取
  CART_COLLECT_RECOMMEND_PRODUCT: '/order/cart_collect_bills_recommend_products', // 加车弹窗152推荐列表
  GOODS_ADD_TO_CART: '/order/add_to_cart', // 加车接口
  GOODS_ADD_TO_CART_BATCH: '/order/cart/batch_add', // 批量加车接口
  GOODS_WHISHLIST_ADD: '/user/wishlist_add', // 收藏接口
  GOODS_WHISHLIST_REMOVE: '/user/wishlist_remove', // 取消收藏接口
  RECOMMEND_SIMILAR_LIST: '/product/recommend/get_similar_list' // 找相似接口
}


export default DETAIL_API_LOCATION
