import { getMetaInfoSEO } from 'public/src/pages/goods_detail_v2/utils/seo.js'
import {
    timeTransformer,
    parseQueryString,
    stringifyQueryString
} from '@shein/common-function'
const _gbCommonInfo = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const _hasDiffSkuPrice = (sku_list = []) => {
  let skuPriceArr = sku_list?.map?.(item => item?.priceInfo?.salePrice?.amount) || []
  for(let i = 0; i < skuPriceArr.length; i ++) {
    if (skuPriceArr[0] != skuPriceArr[i]) return true
  }
  return false
}

/**
 * @field seo相关数据流
 */
export default {
  google_seo(state, getters, rootState) {
    return rootState.productDetail?.hybridModules?.googleSEO
  },
  meta_info(state, getters, rootState) {
    const { productInfo, seoInfo } = rootState.productDetail.coldModules || {}
    return getMetaInfoSEO({
      productInfo,
      seoInfo,
    })
  },
  init_schema_seo(state, getters, rootState, rootGetters) {
    const { mall_code, google_seo, meta_info: metaInfo, common_info, is_ssr } = getters
    const { productInfo, brandDetailInfo } = rootState.productDetail.coldModules || {}

    if (google_seo?.length && is_ssr) {
      const seoJson = google_seo.find(
        item => item?.name === 'goodsDetailSchema'
      )
      try {
        return JSON.parse(seoJson?.innerHTML)
      } catch(e) {
        return {}
      }
    }

    const { host_rp, currency, originalUrl } = common_info
    const price_common_infos = rootGetters['productDetail/price/price_common_infos']
    const sku_list = rootGetters['productDetail/common/sku_list']
    const price = price_common_infos?.price?.salePrice?.amount

    const baseOffers = {
      '@type': 'Offer',
      priceCurrency: currency,
      price, 
      availability:
            +productInfo?.stock > 0 && +productInfo?.is_on_sale != 0
              ? 'https://schema.org/InStock'
              : 'https://schema.org/OutOfStock',
      url: `${host_rp}${originalUrl}`
    }

    const schemaSEO = {
      description: metaInfo?.meta_description,
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: productInfo?.goods_name,
      offers: baseOffers,
      ...(brandDetailInfo?.name
        ? {
          brand: {
            '@type': 'Brand',
            name: brandDetailInfo?.name
          }
        }
        : {}),
      image: productInfo?.goods_img,
      sku: productInfo?.goods_sn
    }

    const comment_overview = rootGetters['productDetail/Reviews/comment_overview']
    if (comment_overview?.comment_rank_average) {
      schemaSEO.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: +comment_overview?.comment_rank_average || 0,
        reviewCount: comment_overview?.commentNumShow || '', // 模糊的评论数
      }
    }

    const promotion_info = rootGetters['productDetail/promotion/promotion_info']
    const hitPromotion = promotion_info.find?.(item => item.typeId == 10)
    if (hitPromotion?.endTimestamp) { // 从common取 不要从主价格取
      schemaSEO.priceValidUntil = timeTransformer({
        time: hitPromotion.endTimestamp * 1000,
        defaultCode: 'A-5'
      })
    }

    if (typeof window === 'undefined' || !sku_list?.length) return schemaSEO
    if (!_gbCommonInfo?.DETAIL_SECOND_CONFIG?.DETAIL_NEW_SEO_FOR_SKU && !_hasDiffSkuPrice(sku_list)) return schemaSEO

    const _query = parseQueryString(location.search) || {}

    const schemaArraySEO = sku_list?.map?.(sku => {
      const queryObj = {
        ..._query,
        skucode: sku?.sku_code
      }
      const url = `${host_rp}${location.pathname}?${stringifyQueryString({
        queryObj
      })}`


      const _priceInfo = sku?.mall_price?.find(d => d.mall_code == mall_code)
      const _stockInfo = sku?.mall_stock?.find(d => d.mall_code == mall_code)
      const product = {
        ...schemaSEO,
        sku: sku?.sku_code,
        offers: {
          ...baseOffers,
          price: _priceInfo?.salePrice?.amount,
          url,
          availability:
                +_stockInfo?.stock > 0
                  ? 'https://schema.org/InStock'
                  : 'https://schema.org/OutOfStock'
        }
      }
      return product
    })

    return schemaArraySEO
  }
}
