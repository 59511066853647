export function detectUserInteraction({
  noInteractionTimeout = 3,
  scrollThreshold = 2,
  onNoInteraction = null,
  onScrollThresholdReached = null
} = {}) {
  let interactionDetected = false
  let scrollThresholdReached = false
  const screenHeight = window.innerHeight || document.documentElement.clientHeight

  const eventTypes = ['mousemove', 'keydown', 'scroll', 'click', 'touchstart', 'touchmove']

  const resetInteractionFlag = () => {
    interactionDetected = true
    clearTimeout(interactionTimer)
    removeListeners(true)
  }

  const checkScrollDistance = () => {
    const scrolledDistance = window.scrollY || window.pageYOffset
    if (scrolledDistance > screenHeight * scrollThreshold && !scrollThresholdReached) {
      scrollThresholdReached = true
      if (typeof onScrollThresholdReached === 'function') {
        onScrollThresholdReached()
      }
      removeListeners() // 停止跟踪
    }
  }

  const removeListeners = (interactionOnly = false) => {
    eventTypes.forEach((eventType) => {
      window.removeEventListener(eventType, resetInteractionFlag, { passive: true })
    })
    if(!interactionOnly) window.removeEventListener('scroll', checkScrollDistance, { passive: true })
  }

  const interactionTimer = setTimeout(() => {
    if (!interactionDetected) {
      if (typeof onNoInteraction === 'function') {
        onNoInteraction()
      }
      removeListeners()
    }
  }, noInteractionTimeout * 1000)

  eventTypes.forEach((eventType) => {
    window.addEventListener(eventType, resetInteractionFlag, { passive: true })
  })

  window.addEventListener('scroll', checkScrollDistance, { passive: true })
}

/**
 * 判断未来时间戳与当前时间的差值是否小于指定的时长。
 * 
 * @param {number} timestamp - 未来时间戳，单位毫秒。
 * @param {number} duration - 指定的时长，单位毫秒。
 * @returns {boolean} 如果未来时间戳与当前时间的差值小于指定的时长，则返回 true，否则返回 false。
 */
export function isTimestampWithinDuration(timestamp, duration) {
  function isValidNumber(str) {
    return !isNaN(parseFloat(str)) && isFinite(str)
  }
  // 检查 timestamp 是否是一个有效的数字
  if (!isValidNumber(timestamp)) {
    throw new Error('timestamp 必须是一个有效的数字')
  }

  // 检查 duration 是否是一个非负数
  if (duration < 0 || !isValidNumber(duration)) {
    throw new Error('duration 必须是一个非负数')
  }

  const currentTime = Date.now() // 获取当前时间的时间戳

  // 确保时间戳在未来
  if (timestamp * 1000 <= currentTime) {
    throw new Error('timestamp 必须在未来')
  }

  const timeDifference = timestamp * 1000 - currentTime // 计算未来时间戳与当前时间的差值
  return timeDifference < duration
}
