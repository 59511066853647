const action = {
  handlePromotionInfo({ commit, state, dispatch }, integrityPromotionInfo) {
    let all_promotion_info = integrityPromotionInfo || {}
    let promotionList = [].concat(state.promotionState.bottomPromotionData || [])
    state.mallCartList?.forEach(mall => {
      mall?.promotionData?.forEach(promotion => {
        promotion?.contentData?.forEach(content => {
          if(content.groupHeadInfo?.data?.promotion_id) {
            if(!(all_promotion_info[content.groupHeadInfo.data.promotion_id])){
              all_promotion_info[content.groupHeadInfo.data.promotion_id] = { data: content.groupHeadInfo.data }
            } else {
              all_promotion_info[content.groupHeadInfo.data.promotion_id].data = content.groupHeadInfo.data
            }
          }
        })
      })
    })
    promotionList.forEach(promotion => {
      promotion.contentData?.forEach(content => {
        if(content.groupHeadInfo?.data?.promotion_id) {
          if(!(all_promotion_info[content.groupHeadInfo.data.promotion_id])){
            all_promotion_info[content.groupHeadInfo.data.promotion_id] = { data: content.groupHeadInfo.data }
          } else {
            all_promotion_info[content.groupHeadInfo.data.promotion_id].data = content.groupHeadInfo.data
          }
        }
      })
    })
    commit('updatePromotionState', { integrityPromotionInfo: all_promotion_info })
    dispatch('handleCartPromotionAnalysis')
  }
}

export default action
