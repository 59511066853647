export class AdaptiveRenderer {
  constructor(checkElementQuerys, maxAttempts = 5, interval = 16) {
    this.checkElementQuerys = checkElementQuerys
    this.maxAttempts = maxAttempts
    this.interval = interval
    this.status = 'pending'
    this.onComplete = null
    this.attempts = 0
    this.observer = undefined
    this.timer = undefined
  }
  timeout (ms) {
    return new Promise(resolve => {
      this.timer = setTimeout(() => {
        resolve('timeout')
      }, ms)
    })
  }
  observe(onComplete) {
    this.status = 'pending'
    this.onComplete = onComplete
    Promise.race([
      this.timeout(3000),
      typeof window.IntersectionObserver === 'function'
        ? this.setupIntersectionObserver()
        : this.setupElementVisibilityCheck()
    ])
      .then((type) => {
        console.log('[AdaptiveRenderer] observe', type)
        this._onComplete()
      })
      .catch(e => {
        this.onFallback()
        console.error(e)
      })
      .finally(() => {
        this.cleanup()
      })
  }
  getTargetElement () {
    try {
      const querys = this.checkElementQuerys
      for (let i = 0; i < querys.length; i++) {
        const ele = document.querySelector(querys[i])
        if (ele) {
          console.log('[AdaptiveRenderer] getTargetElement', querys[i])
          return ele
        }
      }
    } catch(e) {
      console.error(e)
    }

  }

  cleanup() {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  checkFunction() {
    const querys = this.checkElementQuerys
    for (let i = 0; i < querys.length; i++) {
      if (document.querySelector(querys[i])) {
        return true
      }
    }
  }
  setupIntersectionObserver() {
    const observerPromise = new Promise(resolve => {
      const ele = this.getTargetElement()
      if (!ele) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            resolve('setupIntersectionObserver ele not found')
          })
        }, 220)
        return
      }
      this.observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            requestAnimationFrame(() => {
              resolve('setupIntersectionObserver')
            })
          }
        },
        {
          rootMargin: '0px 0px 500px 0px'
        }
      )
      console.log('ele', ele)
      this.observer.observe(ele)
    })
    return observerPromise
  }
  setupElementVisibilityCheck() {
    return new Promise((resolve, reject) => {
      const check = () => {
        if (this.status !== 'pending') {
          return
        }
        if (this.checkFunction()) {
          setTimeout(() => {
            requestAnimationFrame(() => {
              console.log('[AdaptiveRenderer] setupElementVisibilityCheck')
              resolve()
            })
          }, 100)
        } else if (this.attempts < this.maxAttempts) {
          this.attempts++
          requestAnimationFrame(() => {
            setTimeout(() => check(), this.interval)
          })
        } else {
          // 达到最大尝试次数，可能需要降级处理
          reject(new Error('max attempts reached'))
        }
      }
      check()
    })
  }

  _onComplete() {
    this.cleanup()
    if (typeof this.onComplete === 'function' && this.status !== 'complete') {
      this.onComplete()
      this.status = 'complete'
    }
  }

  onFallback() {
    // console.warn('无法确认渲染完成，执行降级策略')
    // 暂无降级，正常处理
    this._onComplete()
  }
}
