import { mapGetters } from 'vuex'


/**
 * 商详首页seo处理mixins
 */
export const getDetailSEOMixins = () => {
  return {
    data() {
      return {
        seoDataBff: {
          breadcrumbList: null,
          schemaSEO: null,
        }
      }
    },
    computed: {
      ...mapGetters('productDetail/common', ['is_use_bff_fs', 'cate_infos_source', 'cate_ids', 'goods_name', 'init_schema_seo', 'google_seo', 'is_ssr']),
      metaInfoBff() {
        const { seoDataBff, is_ssr, google_seo } = this
        if (google_seo && is_ssr) {
          return google_seo
        }

        const { breadcrumbList = '', schemaSEO = '' } = seoDataBff || {}
        return {
          script: [
            { innerHTML: breadcrumbList, type: 'application/ld+json' },
            { innerHTML: schemaSEO, type: 'application/ld+json' }
          ]
        }
      },
    },
    methods: {
      getRealTimeSchemaSEOBff() {

        // console.log('SEOBFF getRealTimeSchemaSEOBff', this.init_schema_seo)

        const {
          init_schema_seo,
          isAfterSsr,
        } = this
        try {
          this.seoDataBff.schemaSEO = JSON.stringify(init_schema_seo)
        } catch(e) {
          this.seoDataBff.schemaSEO = ''
        }

        if (typeof window == 'undefined') return
        if (isAfterSsr && document.getElementById('goodsDetailSchema')) {
          document.getElementById('goodsDetailSchema').innerHTML = this.seoDataBff.schemaSEO
        }

        // if (init_schema_seo) return
        // const breadcrumbList = document.getElementById('breadcrumbList')
        // const goodsDetailSchema = document.getElementById('goodsDetailSchema')
        // if (!breadcrumbList && !goodsDetailSchema) return
        // // Get the first child node of the div element
        // const parentNode = breadcrumbList.parentNode
        // // Remove the child node
        // parentNode.removeChild(breadcrumbList)
        // parentNode.removeChild(goodsDetailSchema)
      },
      getBreadcrumbListBff() {
        try {
          const { cate_infos_source: cateInfos, cate_ids: cateIds, goods_name } = this
          const breadcrumbList = getDetailBreadcrumbList({
            cateInfos,
            cateIds,
            goods_name,
          })
          // console.log('SEOBFF getBreadcrumbListBff', breadcrumbList)

          this.seoDataBff.breadcrumbList = JSON.stringify(breadcrumbList)
        } catch (e) {
          this.seoDataBff.breadcrumbList = ''
        }
      }
    }
  }
}


// 同步ssr阶段方法
// services/product_detail_bff/utils/seo.js
export const getMetaInfoSEO = ({ productInfo, seoInfo } = {}) => {
  const { goods_name, cate_name: cat_name } = productInfo || {}
  const {
    title: pageTitle = '',
    description: pageDescription = '',
    key_words: pageKeywords = '',
  } = seoInfo || {}

  if (!pageTitle) return {}
  try {
    /* 新：修改seo数据 */
    const goodsReg = new RegExp(`\\[goods_name\\]`, 'g')
    const catReg = new RegExp(`\\[cat_name\\]`, 'g')
    return {
      meta_title: pageTitle.replace(goodsReg, goods_name).replace(catReg, cat_name),
      meta_description: pageDescription.replace(goodsReg, goods_name).replace(catReg, cat_name),
      meta_keywords: pageKeywords.replace(goodsReg, goods_name).replace(catReg, cat_name),
    }
  } catch(e) {
    return {}
  }
}

/**
 * 获取面包屑信息
 */
function getDetailBreadcrumbList({ cateInfos, cateIds, goods_name, host_rp, originalUrl }) {

  if (!cateInfos) return ''
  const _cateInfosIds = cateIds?.split?.(',')?.reverse?.() // cateIds // 从末到顶，反转下
  if (!_cateInfosIds?.length) return ''
  
  const catesArr = []
  _cateInfosIds.forEach((cat_id) => {
    catesArr.push({
      cat_id,
      cat_url_name: cateInfos[cat_id].category_url_name
    })
  })
  
  const breadcrumbList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: []
  }
  catesArr.forEach((item, index) => {
    breadcrumbList['itemListElement'].push({
      '@type': 'ListItem',
      position: index + 1,
      name: item.cat_url_name,
      item: `${host_rp}\/${item.cat_url_name}-c-${item.cat_id}.html`
    })
  })
  breadcrumbList['itemListElement'].push({
    '@type': 'ListItem',
    position: catesArr.length + 1,
    name: goods_name,
    item: `${host_rp}${originalUrl}`
  })

  return JSON.stringify(breadcrumbList)
}

