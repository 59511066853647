
import Vue from 'vue'

const mutations = {
  SET_DATA (state, payload) {
    state.context = payload
  },
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  assignList(state, payload) {
    if (!payload || !payload.channelId) return

    const updateFlag = (
      !state.list?.hasOwnProperty(payload.channelId) ||
      !Object.keys(state.list?.[payload.channelId] || {}).length ||
      payload.clientRefresh
    )
    
    if (updateFlag) {
      payload.content.contentChannelSetTime = Date.now()
      Vue.set(state.list, payload.channelId, payload.content)
    }
  },

  assignListChannelState(state, payload) {
    if (!payload || !payload.channelId) return
    Vue.set(state.list[payload.channelId], payload.key, payload.value)
  },

  assignListCompContent(state, payload) {
    if (!payload || !payload.channelId || !payload.index || !payload.element) return
    Vue.set(state.list[payload.channelId].content, payload.index, payload.element)
  },
}

export default mutations
