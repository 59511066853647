export const animateHooks = {
  transition: `all .25s`,
  css: false,
  beforeEnter(el) {
    el.style.transition = animateHooks.transition
    el.style.height = 0
  },
  enter(el) {
    el.style.height = el.scrollHeight + 'px'
  },
  afterEnter(el) {
    el.style.height = el.scrollHeight + 'px'
  },
  beforeLeave(el) {
    el.style.height = el.scrollHeight + 'px'
  },
  leave(el) {
    if (el.scrollHeight !== 0) {
      el.style.transition = animateHooks.transition
      el.style.height = 0
    }
  },
  afterLeave(el) {
    el.style.transition = ''
  }
}

export const getTypeStatus = (typeId, logoType) => {
  return {
    isFreeGift: typeId == 4,
    isFullCoupon: typeId == 10000,
    isAccessoryPromo: [4, 22].includes(+typeId),
    isClubGiftPromo: typeId == 4 && [8, 14].includes(+logoType),
    isCreditReward: typeId == 10003,
    isReturnCoupon: [20, 21].includes(+typeId),
  }
}

/**
 * 
 * @param {*} param
 * @returns {Object}
  {
    isFreeShipping      {Boolean} 是否免邮
    isNew               {Boolean} 是否是新版
    sortAbt             {Number} 轮播排序
    isFirst             {Boolean} 是否首单免邮
    freeShippingTips    {String} 提示语
    coupon              {String} 券码
    isAddItem           {Boolean} 是否凑单
    type                {String} 类型(券 or 活动)
    showCountDown       {Boolean} 是否展示倒计时
    countDownTime       {Number} 时间戳
    addItemPopupInfo    {Object} 凑单数据
    mallCode            {String}
    originPrice         {Object} 免邮门槛金额 - 埋点需要
    shippingMethod      {String} 免邮的运输方式 - 埋点需要
    shippingFreeType    {String} 邮费减免类型 - 埋点需要
  }
 */
export const formatFreeShipping = ({
  isPlatForm,
  isShowNewFreeShip, // 新免邮abt
  mallCode,
  mallShippingInfo, // 免邮总数据
  sortDoubleFreeShippingPriorityInAbt, // 旧版免邮排序
}) => {
  const singleShippingInfo = mallShippingInfo[String(mallCode)] || null // 单mall免邮
  const platformShippingInfo = mallShippingInfo['platform'] // 平台级免邮
  const shippingInfo = isPlatForm ? platformShippingInfo : singleShippingInfo
  // 新版（券和活动，单mall）
  if (isShowNewFreeShip) {
    const { strategyPkData, type } = shippingInfo?.freeShippingStrategy || {}
    return {
      isFreeShipping: true,
      isNew: true,
      sortAbt: strategyPkData?.sortDoubleFreeShippingPriorityInAbt,
      isFirst: strategyPkData?.firstShippingFree,
      freeShippingTips: strategyPkData?.tip,
      coupon: strategyPkData?.coupon,
      isAddItem: strategyPkData?.isAddItem,
      type: type,
      showCountDown: false,
      countDownTime: 0,
      popupInfoId: shippingInfo?.popupInfoId,
      mallCode,
      originPrice: shippingInfo?.originPrice,
      shippingMethod: strategyPkData?.transportFreeList?.map(v => v.shipping_method_name)?.join(','),
      shippingFreeType: strategyPkData?.shippingFreeType,
      isFreight: shippingInfo?.njActivityType == 3,
    }
  }

  // 旧版
  if (shippingInfo?.tip) {
    return {
      isFreeShipping: true,
      isNew: false,
      sortAbt: sortDoubleFreeShippingPriorityInAbt,
      isFirst: shippingInfo?.firstShippingFree,
      freeShippingTips: shippingInfo?.tip,
      isAddItem: shippingInfo?.isOpenDiff == 1,
      type: 'activity',
      showCountDown: shippingInfo?.firstShippingFree && shippingInfo?.showCountDown == 1,
      countDownTime: +shippingInfo?.countDownTime || 0,
      popupInfoId: shippingInfo?.popupInfoId,
      mallCode: isPlatForm ? '' : mallCode,
      originPrice: shippingInfo?.originPrice,
      shippingMethod: shippingInfo?.id,
      shippingFreeType: isPlatForm ? '0' : '3',
      isFreight: shippingInfo?.njActivityType == 3,
    }
  }

  return null
}
