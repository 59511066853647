import orderLogic from 'public/src/pages/common/orderLogic'
import schttp from 'public/src/services/schttp'
import { template, isNumber } from '@shein/common-function'
import {
  getBffBaseData,
  isBffOrderList
} from 'public/src/pages/user/child_pages/orders_bff/list/getBaseData/index.js'
import { getOrderStatusText } from 'public/src/pages/user/child_pages/orders/utils.js'

const isBrowser = typeof window !== 'undefined'
export const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
export const isAndroid = UA && UA.indexOf('android') > 0
// fix: 兼容ios13后ipad使用桌面端wkWebview，导致ua中不存在ipad标识，window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1
export const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA)) || (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)

export const handlerDate = (date, localData) => {
  return orderLogic.orderDateFormat(date, false, localData)
}

// 格式化时间
export const formatTime = (time, localData = null) => {
  let timeString = ''
  if (time) {
    if (/^\d{10,13}$/.test(time)) {
      timeString = handlerDate(time, localData)
    } else if (/^\d{10,13}-\d{10,13}$/.test(time)) {
      const transportTime = time.split('-')
      const startTime = handlerDate(transportTime[0], localData)
      const endTime = handlerDate(transportTime[1], localData)
      timeString = `${startTime} ~ ${endTime}`
    } else {
      timeString = time
    }
  }
  return timeString
}

// 格式化双时效文案
export const formatDoubleTime = (params = {}) => {
  let {
    originShippingDesc,
    originDeliveryDesc,
    transportTimeType,
    transportTime,
    subTransportTime,
    useEnum = false,
    localData = null,
  } = params
  if (!originShippingDesc || !originDeliveryDesc || !subTransportTime) {
    return ''
  }
  if (useEnum) {
    transportTimeType = transportTimeType - 1
  }

  if (transportTimeType == 0) {
    const shippingDesc = originShippingDesc.replace('%s', transportTime)
    const deliveryDesc = originDeliveryDesc.replace(
      '%s',
      formatTime(subTransportTime, localData)
    )

    return `${shippingDesc} (${deliveryDesc})`
  } else {
    const shippingDesc = originShippingDesc.replace('%s',  formatTime(subTransportTime, localData))
    const deliveryDesc = originDeliveryDesc.replace(
      '%s',
      formatTime(transportTime, localData)
    )
    return `${deliveryDesc} (${shippingDesc})`
  }
}

const replaceQuickShip = (quickShipDesc = '', quickShipWord = '') => {
  if (typeof quickShipDesc != 'string') return ''
  return quickShipDesc.replace(/{QSname}/g, quickShipWord)
}

// 根据配置格式化quick_ship_desc文案
export const formatQuickShipDesc = (params = {}) => {
  const { 
    quickShipDesc = '', 
    mallInfo = {},
    language = {}, 
    quickShipAbt = {},
    localData = {}
  } = params
  let handle =  mallInfo?.shipped_transport_time_list
  if(!handle?.length) {
    handle =  mallInfo?.transport_time_list
  }
  const transportTimeList = handle || []
  // 优先取店铺时效
  let qsInfo = transportTimeList.find(item => 
    item.type == 3 && 
    ((item?.transport_time_type == 1 && item?.origin_shipping_desc) || (item?.transport_time_type == 2 && item?.origin_delivery_desc))
  )
  if (!qsInfo) {
    qsInfo = transportTimeList.find(item => item.type == 1)
  }
  let handleDesc = ''
  let NDeliverWord = ''
  let orderQuickshipWord = ''
  if (qsInfo?.transport_time_type == 1) {
    handleDesc = qsInfo.origin_shipping_desc
    orderQuickshipWord = formatTime(qsInfo.transport_time, localData) || ''
    NDeliverWord = splitTransportTime(qsInfo.transport_time, localData) || ''
  } else if (qsInfo?.transport_time_type == 2) {
    handleDesc = qsInfo.origin_delivery_desc
    orderQuickshipWord = formatTime(qsInfo.transport_time, localData) || ''
    NDeliverWord = splitTransportTime(qsInfo.sub_transport_time, localData) || ''
  }
  if (orderQuickshipWord && handleDesc) {
    if (quickShipAbt.allSceneQuickshipWordStatus == 'on') {
      return replaceQuickShip(replaceField(handleDesc, orderQuickshipWord), 
        template(NDeliverWord, language.SHEIN_KEY_PWA_30600)
      )
    }
    return replaceQuickShip(replaceField(handleDesc, orderQuickshipWord), language.SHEIN_KEY_PWA_22276)
  }
  return replaceQuickShip(quickShipDesc, language.SHEIN_KEY_PWA_22276)
}

const replaceField = (quickShipDesc = '', quickShipWord = '') => {
  if (typeof quickShipDesc != 'string') return ''
  return quickShipDesc.replaceAll('%s', quickShipWord)
}

// 获取时效新字段
export const getNewTransportField  = (mallInfo, type) => {
  let handle =  mallInfo?.shipped_transport_time_list
  if(!handle?.length) {
    handle =  mallInfo?.transport_time_list
  }
  return handle?.find(item => item.type == type) || {}
}

// 根据配置格式化quick_ship标签文案
export const formatQuickShipLabel = (params = {}) => {
  const { 
    language = {}, 
    quickShipAbt = {},
    mallInfo = {},
    localData = {}
  } = params
  if (quickShipAbt.allSceneQuickshipWordStatus == 'on') {
    const transportTimeList = mallInfo?.transport_time_list || []
    const qsInfo = transportTimeList.find(item => item.type == 1)
    let orderQuickshipWord = ''
    if (qsInfo?.transport_time_type == 1) {
      orderQuickshipWord =  splitTransportTime(qsInfo.transport_time, localData)
    } else if (qsInfo?.transport_time_type == 2) {
      orderQuickshipWord = splitTransportTime(qsInfo.sub_transport_time, localData)
    }
    if (orderQuickshipWord) {
      return template(orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
    }
  }
  return language.SHEIN_KEY_PWA_22276
}

// 获取普通时效文案
export const getCommonDurationText = ({
  language = {},
  abtInfo = {},
  mallInfo = {},
  localData = {}
})=>{
  if(abtInfo.common_N == 'on') {
    const transportTimeList = mallInfo?.transport_time_list || []
    const handle = transportTimeList.find(item => item?.type == 0) || {}
 
    if(handle.days_delivery_flag == 1) {
      let orderQuickshipWord = ''
      if (handle?.transport_time_type == 0) {
        orderQuickshipWord =  splitTransportTime(handle.transport_time, localData) 
      } else if (handle?.transport_time_type == 1) {
        orderQuickshipWord = splitTransportTime(handle.sub_transport_time, localData)
      }
      if (orderQuickshipWord) {
        return template(orderQuickshipWord, language.SHEIN_KEY_PWA_34685)
      }
    }
  }
}

const splitTransportTime = (time = '', localData = {}) => {
  const handle = time.split('-')
  const str = handle?.length > 1 ? handle[1] : handle[0] 
  return formatTime(str, localData)
}

// 获取催派的时间类型
export const getTimeType = (packageInfo = {}) => {
  let {
    time_type: timeType, // 运输时间: qs和大件:1:运输时间 2:送达时间 | 普通时效:0:运输时间 1:送达时间
    type, // 时效类型：0-普通时效 1-qs时效 2-大件时效
  } = packageInfo || {}
  if (timeType == 999) return timeType
  // 如果不是普通时效，使用新枚举字段
  if (isNumber(type) && type != 0) {
    timeType -= 1
  }
  return timeType
}

export const downloadPdfEvt = async (url = '', newWindow) => {
  if (isIOS) {
    if (newWindow) {
      newWindow.location = url
    } else {
      window.open(url, '_blank')
    }
    return 
  }

  const data = await schttp({
    method: 'GET',
    url: '/api/orders/base/image/get',
    responseType: 'blob',
    params: {
      url
    }
  })
  
  const blob = new Blob([data], { type: 'application/pdf' })
  const fileName = url.split('/').pop().split('?')[0]

  if (window.showSaveFilePicker) {
    try {
      // 请求用户选择保存位置
      const handle = await window.showSaveFilePicker({
        suggestedName: fileName,
        types: [{
          description: 'PDF Files',
          accept: { 'application/pdf': ['.pdf'] },
        }]
      })
      // 写入文件
      const writable = await handle.createWritable()
      await writable.write(blob)
      await writable.close()
    } catch (err) {
      window.open(url, '_blank')
      console.error('Error saving PDF file:', err)
    }
  } else {
    const a = document.createElement('a')
    const URL = window.URL || window.webkitURL
    const herf = URL.createObjectURL(blob)
    a.href = herf
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(herf)
  }
}

export async function prefetchOrderDetailPageData ({ billno, orderType = '', enterType = '' } = {}) {
  const res = await schttp({
    url: '/api/orders/base/orderDetail/query',
    params: {
      billno,
      withI18n: true,
      orderType,
      enterType,
    },
    schttp: {
      needLogin: true
    }
  })
  return res 
}

async function getUnionOrderListPageData ({ orderSearchMsg, statusType } = {}) {
  const { isSearchList = false, page: sPage = '', content: sKeyword = '' } = orderSearchMsg
  const unionData = await schttp({
    method: 'POST',
    url: '/api/orders/base/unionOrderListPageData/get',
    data: {
      // 订单列表 【状态】
      status_type: statusType,
      // 订单搜索
      isSearchList,
      sPage,
      sKeyword
    },
    schttp: { needLogin: true }
  })
  return unionData
}

export async function prefetchOrdersListPageData ({ statusType = '', orderSearchMsg = {} } = {}) {
  const { isSearchList, page, content } = orderSearchMsg
  const prefetch = await Promise.all([
    isBffOrderList
      ? (isSearchList ? getBffBaseData(statusType, { page, keyword: content }) : getBffBaseData(statusType))
      : getUnionOrderListPageData({ statusType, orderSearchMsg }),
    getOrderStatusText()
  ])
  return prefetch
}
