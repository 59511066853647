import { isCouponNewUserStrengthen } from 'public/src/pages/goods_detail_v2/utils/coupon'
import { template } from '@shein/common-function'
import { DetailShowPromotionTypeIds, EPromotionTypeId, isPromotionSpecialFlash, isPromotionAbPrice, isPromotionFlash, isPromotionZoneFlash, isPromotionBrandFlash } from 'public/src/pages/goods_detail_v2/utils/promotion.js'

export default {
  namespaced: true,
  state: {
  },
  getters: {
    skc_promotion_info(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/detail_promotion_floor']?.detailPromotionInfo
    },
    sku_promotion_info(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/common/sku_info']?.detailPromotionInfo || null
    },
    promotion_info(state, getters) {
      return getters.sku_promotion_info || getters.skc_promotion_info || []
    },
    flash_type(state, getters) {
      return getters.promotion_info?.find?.(item => item.typeId == 10)?.flashType
    },
    is_flash(state, getters) {
      return !!getters.promotion_info?.find?.(item => item.typeId == 10)
    },
    is_normal_flash(state, getters) {
      return [1, 4, 5].includes(Number(getters.flash_type))
    },
    is_brand_flash(state, getters) {
      return getters.flash_type == 6
    },
    is_special_flash(state, getters) {
      let flashType = getters.skc_promotion_info?.find?.(item => item.typeId == 10)?.flashType
      return [2, 3].includes(Number(flashType))
    },
    is_zone_flash(state, getters) {
      const { flash_type } = getters
      return isPromotionZoneFlash({ flash_type })
    },
    new_flash_promotion_info(state, getters) {
      return getters.skc_promotion_info?.find?.(
        item => item.typeId == 10 && [2, 3, 4].includes(Number(item.flashType))
      ) || null
    },
    skc_shein_club_promotion_info(state, getters) {
      return getters.skc_promotion_info?.find?.(item => item.typeId == 29) || null
    },
    shein_club_promotion_info(state, getters) {
      return getters.promotion_info.find?.(item => item.typeId == 29) || null
    },
    other_aggregate_member_result(state, getters, rootState, rootGetters) {
      let skuInfo = rootGetters['productDetail/common/sku_info']
      let otherAggregateMemberResult = skuInfo?.sku_code ? skuInfo.otherAggregateMemberResult : rootGetters['productDetail/detail_promotion_floor'].otherAggregateMemberResult
      if (otherAggregateMemberResult && Object.keys(otherAggregateMemberResult || {}).length) {
        return otherAggregateMemberResult
      }
      return null
    },
    s3_promotion_info(state, getters) {
      return getters.promotion_info.find?.(item => item.typeId == 12) || null
    },
    // newProductDetail/common/extraVoucherPromotionInfo
    // 首购礼金
    extra_voucher_promotion_info(state, getters, rootState, rootGetters) {

      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const detialcatevorch = fs_abt?.detialcatevorch?.p?.detialcatevorch

      if (!['show1', 'show2'].includes(detialcatevorch)) return null

      const detail_promotion_floor = getters.skc_promotion_info

      // 首购礼金只有skc纬度
      const extraVoucherPromotion = detail_promotion_floor?.find?.(item => item.typeId == 33)
      const { firstBuyVoucherInfo } = extraVoucherPromotion || {}
      if (!firstBuyVoucherInfo) return null

      const discountAmountWithSymbol = firstBuyVoucherInfo?.voucherAmount?.amountWithSymbol
      const catNameMulti = firstBuyVoucherInfo?.catNameMulti
      if (!discountAmountWithSymbol || !catNameMulti) return null

      const contentItem2EndTimestamp = firstBuyVoucherInfo.endTime

      const language = rootGetters['productDetail/common/language_v2']

      const {
        SHEIN_KEY_PWA_31290,
        SHEIN_KEY_PWA_31291,
        SHEIN_KEY_PWA_31315,
        SHEIN_KEY_PWA_31292,
        SHEIN_KEY_PWA_31293,
        SHEIN_KEY_PWA_31294,
        SHEIN_KEY_PWA_31313,
        SHEIN_KEY_PWA_31314,
        SHEIN_KEY_PWA_31296,
        SHEIN_KEY_PWA_26949
      } = language
  
      const isExtraVoucherFloorType = detialcatevorch === 'show1'
      const isExtraVoucherTextType = detialcatevorch === 'show2'
      const extraVoucherFloorTypeTexts = {
        extraVoucher: SHEIN_KEY_PWA_31293 || 'Extra voucher',
        forNewUeserInCatNameMulti: template(catNameMulti, SHEIN_KEY_PWA_31315 || 'for new ueser in {0}'),
      }
      const extraVoucherTextTypeTexts = {
        extraVoucher: SHEIN_KEY_PWA_31290 || 'Extra voucher {0}',
      }
  
      const extraVoucherPromotionTexts = {
        title: SHEIN_KEY_PWA_31296 || 'Voucher',
        contentTitle: template(catNameMulti, SHEIN_KEY_PWA_31291 || 'For new user in {0}'),
        contentItem1: SHEIN_KEY_PWA_31292,
        contentItem2EndTimeLanguage: SHEIN_KEY_PWA_26949 || 'Expires in {0}',
        contentItem2EndTimestamp,
        // contentItem2EndTimestamp: 1715961599,
        floorTip: SHEIN_KEY_PWA_31293 || 'Extra Voucher',
        tipTitle: SHEIN_KEY_PWA_31294 || 'How to get Vouchers?',
        tipContent1: SHEIN_KEY_PWA_31313 || 'The Category Voucher for New (the "Voucher(s)") will be issued to a new category user. The Voucher value vary among users; A new category user refers to a SHEIN user who has never placed an order or purchased any product from a certain category.',
        tipContent2: SHEIN_KEY_PWA_31314 || `Obtaining Voucher(s) is subject to compliance with SHEIN's terms and conditions. Users who have been restricted due to violations of SHEIN's policies or whose accounts exhibit unusual activities will be ineligible to obtain the Voucher(s). SHEIN reserves the right to withhold Voucher(s) from users who, in SHEIN's reasonable judgment, have engaged in improper or illicit activities to collect Voucher(s). SHEIN's determination of such activities will be final and binding.`,
      }
  
      return {
        isExtraVoucherFloorType,
        isExtraVoucherTextType,
        extraVoucherFloorTypeTexts,
        extraVoucherTextTypeTexts,
        extraVoucherPromotionTexts,
        discountAmountWithSymbol
      }
    },
    // 新人券 newProductDetail/common/newUserCouponInfo 类似作用
    new_user_coupon_info(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const abtNewCoupon = fs_abt?.NewCoupon?.p?.NewCoupon
      const isNew1 = abtNewCoupon === 'New1'
      const isNew2 = abtNewCoupon === 'New2'

      const res = {
        isNew1,
        isNew2,
        tips: '',
        newUserCoupon: null,
      }

      if (!isNew1 && !isNew2) return res

      const coupon_list = rootGetters['productDetail/coupon/origin_coupon_list']
      // 新人券：newUserTips && 强化：newUserCouponInfo
      res.newUserCoupon = coupon_list?.find(d => isCouponNewUserStrengthen(d))
      if (!res.newUserCoupon) return res

      const { newUserCouponInfo } = res.newUserCoupon
      /**
      * 'newUserStrengthenTipsNew1': 'New-Users Only Offers',
      * 'newUserStrengthenTipsNew2': 'New User 50% Off'
      */
      res.tips = isNew1 ? newUserCouponInfo?.newUserStrengthenTipsNew1 : isNew2 ? newUserCouponInfo?.newUserStrengthenTipsNew2 : ''
      return res
    },
    new_user_coupon_info_floor(state, getters, rootState, rootGetters) {
      let language = rootGetters['productDetail/common/language_v2']
      let { newUserCoupon, isNew1 } = getters.new_user_coupon_info || {}
      let { newUserCouponInfo, coupon_rule, ruleDimension } = newUserCoupon || {}
      if (!isNew1 || !newUserCouponInfo) return null
      let { price, priceSymbol } = coupon_rule?.[0]?.price || {}
      let title = ''
      if (ruleDimension == 1) {
        if (!priceSymbol) return null
        title = priceSymbol
      } else {
        if (!price) return null
        title = price + '%'
      }
      title = template(title, language.SHEIN_KEY_PWA_15816)
      return {
        title,
        desc: newUserCouponInfo?.newUserStrengthenTipsNew1
      }
    },
    shein_club_promotion_floor_info(state, getters, rootState, rootGetters) {
      let realTimeReady = rootGetters['productDetail/common/real_time_ready']
      if (!realTimeReady) return null
      let isPaidUser = rootGetters['productDetail/common/is_paid_user']
      let isCanTrial = rootGetters['productDetail/common/is_can_trial']
      let isNeedsToRenew = rootGetters['productDetail/user_info']?.memberStatus === '1'
      let priceInfo = rootGetters['productDetail/price_info']
      let fsAbt = rootGetters['productDetail/common/fs_abt']
      let { shein_club_promotion_info, skc_shein_club_promotion_info, other_aggregate_member_result } = getters || {}
      let unPaidUserShowSheinClub = fsAbt?.sheinclubprice?.p == 'exposure_prime_price'
      
      let isOtherAggregateMemberResult = false
      let aggregateMemberResult = (() => {
        let sheinClubPromotionRes = (() => {
          if (!shein_club_promotion_info) return null
          if (!isPaidUser && !unPaidUserShowSheinClub) return null
          return shein_club_promotion_info?.aggregateMemberResult || null
        })()
        if (sheinClubPromotionRes) return sheinClubPromotionRes
        if (other_aggregate_member_result) {
          isOtherAggregateMemberResult = true
          return other_aggregate_member_result
        }
        return null
      })()
      
      if (!aggregateMemberResult) return null
      if (isOtherAggregateMemberResult && !aggregateMemberResult.promoShipPaidMemberShowTipsObject) return null

      let {
        paidMemberOneHeadUrl,
        paidMemberTwoHeadUrl,
        paidMemberHeadUrl,
        paidMemberShowTipsObject,
        freeTrialPriceTipsObject,
        promoShipPaidMemberShowTipsObject,
      } = aggregateMemberResult || {}
      let { primeLevel, totalSaving } = skc_shein_club_promotion_info?.aggregateMemberResult || {}
      let SHEINclubshowtype = fsAbt?.SHEINclubshowtype?.p?.SHEINclubshowtype || 'Old'
      let isNewSheinClubShowType = ['New1', 'New2'].includes(SHEINclubshowtype)
      
      let imgSrc = {
        'Old': paidMemberHeadUrl,
        'New1': paidMemberOneHeadUrl,
        'New2': paidMemberTwoHeadUrl
      }[SHEINclubshowtype]

      let imgStyle = {
        'Old': { width: '2.08rem' },
        'New1': { width: '2.2133rem' },
        'New2': { width: '2.08rem' },
      }[SHEINclubshowtype]

      let titleImg = { imgSrc, imgStyle }

      let showGradientImg = !isNewSheinClubShowType

      let containerStyle = {
        'padding': '0 .1067rem',
        'background': 'linear-gradient(0deg, rgba(255, 226, 211, 0.5), rgba(255, 226, 211, 0.5)), #FFFFFF',
        'border-radius': '.0267rem',
        'height': '.5867rem',
      }
      if (SHEINclubshowtype == 'New1') {
        containerStyle = {
          'padding': '0 .21333rem 0 .08rem',
          'background': 'linear-gradient(0deg,rgba(255,226,211,.7),rgba(255,226,211,.7)),#fff',
          'border-radius': '.05333rem',
          'height': '.8rem',
        }
      }
      if (SHEINclubshowtype == 'New2') {
        containerStyle = {
          'padding': '.13333rem .21333rem',
          'background': 'linear-gradient(0deg,rgba(255,226,211,.9),rgba(255,226,211,.9)),#fff',
          'border-radius': '.05333rem',
          'height': '.8rem',
        }
      }

      let tempObj = (() => {
        if (isOtherAggregateMemberResult) return promoShipPaidMemberShowTipsObject
        if (!isPaidUser && isCanTrial && fsAbt?.clubtype?.p?.clubtype == 'new' && ![1, 2].includes(Number(priceInfo?.estimatedPriceInfo?.paidNumberPriceType))) return freeTrialPriceTipsObject
        return paidMemberShowTipsObject
      })()
      
      let { paidNumberKey, value, labelType } = tempObj || {}
      let text = template(`<span style="color: #C44A01;font-weight: 700;">${value || ''}</span>`, paidNumberKey)
      if (!isNewSheinClubShowType) {
        text = template(value, paidNumberKey)
      }
      let textStyle = {
        'margin-left': isNewSheinClubShowType ? '.2133rem' : '.0533rem',
        'width': '6.4rem'
      }
      if (text.includes('</&') && text.includes('&/>')) {
        text = text.replaceAll('</&', '<span style="color: #C44A01;font-weight: 700;">')
        text = text.replaceAll('&/>', '</span>')
      }
      let prime_trial_type = 0
      if (!isPaidUser && isCanTrial && fsAbt?.clubtype?.p?.clubtype == 'new') prime_trial_type = 1
      let analysisData = {
        prime_level: primeLevel || '0',
        total_saving: totalSaving || '0.00',
        location: 'page', // 加车注意 popup
        from: 1,
        goods_id: rootGetters['productDetail/common/goods_id'],
        ...(prime_trial_type ? { prime_trial_type } : {}),
        labeltype: labelType || ''
      }

      let jumpUrl = (() => {
        const { langPath } = gbCommonInfo
        if (isNeedsToRenew) return `${langPath}/user/prime?productsource=quickview`
        if (isPaidUser) return `${langPath}${skc_shein_club_promotion_info.scInfo?.scUrl}?productsource=quickview`
        if (isCanTrial && fsAbt?.clubtype?.p?.clubtype == 'new') return `${langPath}/user/primefreetrial?productsource=quickview`
        return `${langPath}/user/prime?productsource=quickview`
      })()

      return {
        titleImg,
        text,
        textStyle,
        containerStyle,
        showGradientImg,
        analysisData,
        jumpUrl
      }
    },
    // be like newProductDetail/common/promotionInfoDesc
    /**
     * 过滤掉在商详不展示的活动
     */
    promotion_info_desc(state, getters, rootState, rootGetters) {

      const is_zone_flash = rootGetters['productDetail/promotion/is_zone_flash']
      const is_special_flash = rootGetters['productDetail/promotion/is_special_flash']
      const detail_promotion_floor = rootGetters['productDetail/detail_promotion_floor']

      const estimated_info = rootGetters['productDetail/price/estimated_info']
      const notHasEstimated = !estimated_info?.value // 不存在到手价

      const promotionInfoList = detail_promotion_floor?.detailPromotionInfo || []
      const promotionInfoListFilter = promotionInfoList.filter(item => {
        if (!DetailShowPromotionTypeIds.includes(Number(item.typeId))) return

        const typeId = String(item.typeId)
        const _isFlash = isPromotionFlash(item)
        if (typeId == EPromotionTypeId.ProDiscountLimitBuy && item.ruleType === 'no_discount' && Number(item.singleNum) && !Number(item.orderNum)) return

        if (_isFlash) {
          if (isPromotionSpecialFlash(item) && !is_special_flash) return
          if (isPromotionZoneFlash(item) && !is_zone_flash) return
          // wiki?pageId=1300481401 新增阿波罗开关，用于控制promotion中是否展示“brand sale“，包括商详上移/和原位置
          // 是否在促销楼层展示品牌特卖活动 1-是，其他-否
          if (isPromotionBrandFlash(item) && item?.aggregatePromotionBusiness?.isShowBrandSaleOnPrmFloor !== '1') return

          // if (isInversion) return // 倒挂中间层过滤闪购
        }

        if (typeId === EPromotionTypeId.ProNewerPriceOrLimitedTimePriceDrop && item.promotion_logo_type != 3) return // 用于过滤同一活动Id--31但非商家直降活动的情况
        if (typeId === EPromotionTypeId.ProAppOnly) return // 不展示APP专享折扣
        if (notHasEstimated && isPromotionAbPrice(item)) return // "AB定价"过滤 
        return true
      })
      return promotionInfoListFilter
    },
  }
}
