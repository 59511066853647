const state = {
  showEmailConfirmDialog: false,
  emailConfirmDialogInfo: {},
  showAccountRegisterDialog: false,
  accountRegisterResult: 0, // 0:注册中 1:注册成功
  showRegisterFailedDialog: false,
  channelSessionParams: null, //渠道会话参数
  countryId: '', // 国家id
  countryIdMap: new Map(), // 国家列表
}
export default state
