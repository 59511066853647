// import { fetchIndexData } from '../../../api'
import { getCccIndexPageContent } from 'public/src/pre_requests/modules/config_index.js'
import { markPoint } from 'public/src/services/mark/index.js'

const actions = {
  FETCH_PAGE_DATA: async ({ state, commit }, payload ) => {
    try {
      if (Object.keys(state.context).length) return
      const res = await getCccIndexPageContent({ url: '/api/home/pageConfig/get', channelId: Number(payload.activeChannelId), oldRoute: payload.oldRoute })
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
      res.clientRender = true
      
      return Promise.resolve(commit('SET_DATA', res))
    } catch (error) {
      console.log(error)
      return Promise.resolve()
    }
  },
  FETCH_PAGE_BFF_DATA: async ({ state, commit }, payload) => {
    try {
      if (Object.keys(state.context).length) return state.context
      const res = await getCccIndexPageContent({ url: '/api/home/bffPageConfig/get', channelId: Number(payload.activeChannelId), contentId: payload.contentId })
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
      res.clientRender = true
      commit('assignList', { channelId: res.content.channelId, content: res.content })
      commit('SET_DATA', res)
      // 把 res 返回
      return Promise.resolve(res)
    } catch (error) {
      console.log(error)
      return Promise.resolve()
    }
  }
}

export default actions
