import { cartPromotionMapHandle } from 'public/src/pages/cart_v2/utils/promotionMapHandle.js'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'

const handleShippingAnalysisParam = (shippingInfoAvailable = {}) =>{
  const { id, is_fullshippingactivity, num_price, charging_type, step_fee, shipping_detail_obj, originPrice } = shippingInfoAvailable
  const analysisParam = {}

  //免邮运费
  if (originPrice && originPrice.amount) {
    analysisParam.origin_price = originPrice && originPrice.amount
    analysisParam.is_fullshippingactivity = is_fullshippingactivity
  }

  //阶梯运费
  if (shipping_detail_obj) {
    analysisParam.step_fee = step_fee?.amount
    analysisParam.charging_type = charging_type
    analysisParam.num_price = num_price
    analysisParam.is_fullshippingactivity = is_fullshippingactivity
  }
  analysisParam.shipping_method = id

  return analysisParam
}

const action = {
  handleMallShippingAnalysisParam({ commit, state, dispatch }) {
    let mallShippingAnalysisParam = {
      platform: null, // 平台级免邮
    }
    const { mallShippingInfo = {} } = state
    for(let key in mallShippingInfo){
      let shipping_free_type = key == 'platform' ? 0 : 3
      if(mallShippingInfo[key].freeType == 2) {
        mallShippingAnalysisParam[key] = handleShippingAnalysisParam(mallShippingInfo[key])
        mallShippingAnalysisParam[key].shipping_free_type = shipping_free_type
      } else {
        mallShippingAnalysisParam[key] = null
      }
    }
    commit('updateState', { key: 'mallShippingAnalysisParam', value: mallShippingAnalysisParam })
    dispatch('handleQueryPaymentFrontShowForCartIndexBi')
  },
  handleCartPromotionAnalysis({ commit, state }) {
    const cartPromotionAnalysis = cartPromotionMapHandle({ integrityPromotionInfo: state.promotionState.integrityPromotionInfo, promotionEventTrackingPoint: state.cartInfo.promotionEventTrackingPoint || [] })
    commit('updatePromotionState', { cartPromotionAnalysis: cartPromotionAnalysis })
  },
  // 购物车查询支付分期前置文案信息
  handleQueryPaymentFrontShowForCartIndexBi({ state, getters }) {
    if (typeof window == 'undefined') return
    const exposeData = function(type) {
      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'if_pay_methods',
          type,
        },
      })
    }
    if (getters.payShowStyle === 'off') exposeData(0)
    if(!(state.loginStatus && state.cartIds?.length && getters.payShowStyle != 'off')) return 

    exposeData((getters.payShowStyleA || getters.payShowStyleB) ? 1 : 0)
  },
}

export default action
