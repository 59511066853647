export const getDefaultHotModules = (reservedField = {}) => ({
      /**
   * priceInfo:
    estimatedPriceCalculateProcess 到手价计算过程
    npcsEstimatedPriceCalProcess N件到手价计算过程
   */
  priceInfo: null,
  detailPromotionFloor: null,
  storeInfo: {},
  productInfo: {
    cccDetailsTemplate: {},
    is_on_sale: '1',
    stock: '999',
    is_saved: '0',
  },
  saleAttr: {
    arEntrance: false,                // 是否展示ar试装
  },
  store: {},
  productLabel: {
    premiumFlagList: [],                // 主图角标
    hotNews: [],                        // 主图/大图人气氛围
    carHotNews: [],                     // 加车按钮人气氛围
  },
  beltInfo: {
    // // 保留款低价腰带
    // sameLabelBelt: {},
    // 促销腰带相关
    promotionBelt: {},
    sameLabelBelt: {}
  },
  // 商品促销信息	
  promotionInfo: {},
  userInfo: {
    isPaidMember: '0',
  },
  // 真实库存信息（包含大件商品标识和低库存标识）
  inventory: [],
  comment: {},
  completeCouponInfo: {}, // 优惠券信息
  brandDetailInfo: {},
  multiPropertyProducts: [],
  // BFF返回的ABT信息（支持/不支持圈品）
  webProductDetailAbtInfo: {},
  shipInfo: {},
  configInfo: {},
  addCartInfo: {},
  ...reservedField
})


