/**
 * 超限
 */
const state = {
  /**
   * 闪购超限商品id
   */
  flashOverLimitId: new Set(),
  /**
   * 库存超限商品id
   */
  stockOverLimitId: new Set()
}

export default state
