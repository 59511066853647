import * as api from '../api'
import { getCountryId } from 'public/src/pages/cart_v2/utils/index.js'

const actions = {
  async getCountryList({ commit, state }) {
    if (typeof window == 'undefined') return
    let paypalCartSwitchStatus = state.cartAbtInfo?.paypalCartSwitch?.param?.paypalCartSwitchStatus || 0
    if (paypalCartSwitchStatus == 0) return 
    if(state.paypalState.countryIdMap.size) return
    const res = await api.getCountryAll()
    const allCountry = new Map()
    let _country = res.code == 0 ? res.info : {}
    if (_country.item_cates) {
      _country.item_cates.forEach((item) => {
        allCountry.set(item.id, item.value)
      })
    }
    commit('updatePaypalState', { countryIdMap: allCountry })
    return
  },
  async handleChannelSessionParams({ commit, state, getters, dispatch }) {
    if (typeof window == 'undefined') return
    if(!getters.showPaypalBtn) return
    await dispatch('getCountryList')
    let countryId = getCountryId() || state.paypalState.countryId
    let hashMap = {}
    let businessModelList = []
    state.carts.filter(item => item.is_checked == 1).forEach(cartItem => {
      const hash = `${cartItem.business_model}:${cartItem.company_id || ''}`
      if (!hashMap[hash]) {
        hashMap[hash] = true
        businessModelList.push({
          businessModel: cartItem.business_model,
          companyId: cartItem.company_id
        })
      }
    })
    commit('updatePaypalState', { channelSessionParams: {
      shipCountry: state.paypalState.countryIdMap.get(countryId) || '',
      orderCurrency: gbCommonInfo.currency,
      payMethodList: ['PayPal-GApaypal'],
      orderDetails: businessModelList
    }
    })
  },
}

export default actions
