/**
 * @field 推荐涉及的abt
 */
export default {
  /** 组合购 abt 集合 */
  combo_buy_abt_info(state, getters, rootState, rootGetters) {
    const product_detail_abt_info =
      rootGetters['productDetail/product_detail_abt_info']
    const buytogethernew = product_detail_abt_info?.buytogethernew?.p || {}

    return {
      // 是否展示新组合购
      isShowComboBuyAbt: buytogethernew?.buytogethernew === 'show',
      // 组合购样式 (new -- 橙色 ｜ old -- 黑色版本)
      isShowNewComboBuyStyleAbt: buytogethernew?.buytogethershownew === 'new',
      // 组合购样式是否压缩 (tall -- 标准样式 ｜ short -- 压缩样式)
      isShowNewComboBuyScreenStyleAbt: buytogethernew?.heightnew === 'short',
      // 新组合购模块橙色版屏效提升版样式
      isShowNewComboBuyOrangeScreenStyleAbt:
        buytogethernew?.heightnew === 'short' &&
        buytogethernew?.buytogethershownew === 'new',
      // 新组合购模块屏效提升版无规格选择器样式 需命中屏效提升
      isHideNewComboBuyScreenStyleSizeAbt:
        buytogethernew?.heightnew === 'short' &&
        buytogethernew?.chooseshownew === 'none'
    }
  },
  get_the_look_abt_info(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['productDetail/product_detail_abt_info']
    const { newoutfit } = fsAbt
    const isOutfitShowYmalSource = newoutfit?.p?.outfitsimilar === 'show-ymal'
    // const isOutfitShowYmalSource = true
    const isShowItemChange = newoutfit?.p?.outfitswitch !== 'none'
    const showCateRecommend = ['show-ymal', 'show'].includes(newoutfit?.param?.outfitsimilar)

    return {
      isOutfitShowYmalSource,
      isShowItemChange,
      showCateRecommend
    }
  },
  // 加车弹窗推荐abt配置
  add_bag_recommend_config(state, getters, rootState, rootGetters) {
    const product_detail_abt_info = rootGetters['productDetail/product_detail_abt_info'] || {}
    const addbagsuccess = product_detail_abt_info.addbagsuccess || {}
    const newoutfit = product_detail_abt_info.newoutfit || {}
    return {
      addbagRecQuickShow: addbagsuccess?.param?.addbagsuccess === 'quickshow', // 打开加车弹窗，才出现
      addbagRecAllShow: addbagsuccess?.param?.addbagsuccess === 'allshow', // 主商品加车都出现
      addbagRecStyleOne: !['tworec', 'onebigrec', 'outfit'].includes(addbagsuccess?.param?.addbagtype), // 推荐弹窗样式一
      addbagRecStyleTwo: addbagsuccess?.param?.addbagtype === 'tworec', // 推荐弹窗样式二
      addbagRecStyleThree: addbagsuccess?.param?.addbagtype === 'onebigrec', // 推荐弹窗样式三
      addbagRecStyleFour: addbagsuccess?.param?.addbagtype === 'outfit', // 推荐弹窗样式四（拉起getThelook）
      addbagOutfitAlone: newoutfit?.param?.newoutfit === 'addbagalone', // 加车成功后-YMAL上方插入outfitd模块
      addbagOutfitInYmal: newoutfit?.param?.newoutfit === 'addbaginymal', // 加车成功后-YMAL插坑outfit卡片
    }
  },
  show_add_bag_rec_drawer_scene(state, getters, rootState, rootGetters) {
    const { addbagRecAllShow, addbagRecQuickShow, addbagRecStyleOne, addbagRecStyleFour } = getters.add_bag_recommend_config
    const allCoupon = rootGetters['productDetail/coupon/cmp_coupon_info_list']
    const needCoupon = addbagRecStyleOne ? !!allCoupon?.length : true
    let count = 0
    if (typeof sessionStorage !== 'undefined') {
      const _KEY = 'productDetail_addBagRecCount'
      count = Number(sessionStorage.getItem(_KEY))
    }
    let isNeedCount = !addbagRecStyleFour ? count < 3 : true
    return {
      showAddBagRecDrawerSceneOne: addbagRecQuickShow && needCoupon && isNeedCount,
      showAddBagRecDrawerSceneTwo: addbagRecAllShow && needCoupon && isNeedCount,
    }
  },
}
