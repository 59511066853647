const filterAttrs = ['size', 'attr_id', 'attr_name', 'attr_value_id', 'attr_value_name', 'attr_value_name_en']
function descriptionDeal (config, state, getters) {
  const result = {
    descriptionList: getters.productDetailsObj,
    descriptionName: config.description == 0 ? state.language.SHEIN_KEY_PWA_14881 : state.language.SHEIN_KEY_PWA_14986,
    description27: null,
    goodsDesc: (state?.getBeautyGoodsDesc?.goodsDesc || '').replace(/\n/g, '<br>'),
    sizeAttrList: []
  }

  if (config.description == 0 && result.descriptionList['27'] && result.goodsDesc) {
    result.description27 = result.descriptionList['27']
  }

  // premium模板的composition名称文案改为outer
  if (config.premiumMaterial == 1 && result.descriptionList['62']) {
    result.descriptionList['62'].name = state.language.SHEIN_KEY_PWA_18075
  }

  const sizeInfoDes = state?.sizeInfoDes?.sizeUnit === 1 ? state.sizeInfoDes.sizeInfoInch : state.sizeInfoDes.sizeInfo
  if (sizeInfoDes instanceof Array && sizeInfoDes.length == 1 && config.description == 0) {
    // 只取第一条的size数据
    for (let key in sizeInfoDes[0]) {
      if (!filterAttrs.includes(key)) {
        result.sizeAttrList.push({
          name: key,
          value: sizeInfoDes[0][key]
        })
      }
    }
  }

  return result
}

function sizeGuideDeal (config) {
  return {
    isShow: config.sizeChart == 1
  }
}

function qtyDeal (config) {
  return {
    isShow: config.countCell == 1
  }
}

function colorDeal (config) {
  return {
    hasDropPop: config.colorType == 1,
    isImgColorType: config.colorType == 2
  }
}

function moreDetailDeal (config, state) {
  const result = {
    isNewComponent: config.detailImg == 0,
    notShow: config.detailImg == 2,
    partList: [],
    mergeList: []
  }

  const key = result.isNewComponent ? 'partList' : 'mergeList'

  if(result.notShow) { 
    result[key] = [] 
  } else {
    result[key] = state.detailImage
  }

  return result
}

function headNameDeal(config) {
  return {
    isBlack: config.premiumHeadName == 1
  }
}

function materialDeal(config, state, getters) {
  let descriptionList = getters.productDetailsObj
  let materialIdList = [160, 62, 1000078, 1000067, 1000069]
  let nameList = [
    state.language.SHEIN_KEY_PWA_18074,
    state.language.SHEIN_KEY_PWA_18077,
    state.language.SHEIN_KEY_PWA_18076,
    state.language.SHEIN_KEY_PWA_18078,
    state.language.SHEIN_KEY_PWA_18079,
  ]
  let materialList = []
  let composition = null
  let index

  Object.keys(descriptionList).forEach(descKey => {
    index = materialIdList.findIndex(materialItem => descKey == materialItem)
    if (index >= 0) {
      materialList[index] = {
        attr_id: descKey,
        name: nameList[index],
        value: descriptionList[descKey].value,
      }
    }
  })

  // inner 和 outer 合并为 composition 展示
  if (materialList[1] || materialList[2]) {
    composition = {
      name: state.language.SHEIN_KEY_PWA_18075,
      value: `${materialList[1] ? `${materialList[1].name}: ${materialList[1].value} ` : ''}${materialList[2] ? `${materialList[2].name}: ${materialList[2].value}` : ''}`,
    }
    materialList[1] = composition
    materialList[2] = null
  }

  return {
    isShow: config.premiumMaterial == 1,
    materialList,
  }
}

function modulesSortDeal(config) {
  return {
    shipAndDetailSortType: config.premiumModulesSort
  }
}

export function getModuleAttribute (moduleAttribute, filterId, originDescriptionList, blackAttrIds = []) {
  const textAttr = {}
  const imgAttr = {}
  const attrIds = {}
  
  moduleAttribute.forEach(moduleItem => {
    const { _whiteAttrGroups = [] } = moduleItem
    const isBlackId = blackAttrIds.some(id => id == moduleItem.attribute_id)
    // 黑名单不计算
    if (isBlackId) return
    if (!attrIds[moduleItem.attribute_id]) {
      attrIds[moduleItem.attribute_id] = true
      // const productDetail = originDescriptionList.find(_ => _.attr_id + '-' + _.attr_value_id == moduleItem.uniqueId) || {}
      const productDetails = originDescriptionList.filter(_ => _.attr_id == moduleItem.attribute_id) || []
      if (productDetails[0]) {
        filterId.push(String(productDetails[0].attr_id))
      }
      productDetails.forEach(productDetail => {

        // 属性值白名单
        if (_whiteAttrGroups.length) {
          const isInWhite = _whiteAttrGroups.some(d => d.attribute_id == productDetail.attr_value_id)
          if (!isInWhite) return
        }
        if (productDetail.attr_image) {
          imgAttr[productDetail.attr_id] = imgAttr[productDetail.attr_id] ? imgAttr[productDetail.attr_id].concat(productDetail) : [productDetail]
          if (textAttr[productDetail.attr_id]) {
            delete textAttr[productDetail.attr_id]
          }
        } else {
          if (!imgAttr[productDetail.attr_id]) {
            textAttr[productDetail.attr_id] = {
              name: productDetail.attr_name,
              value: textAttr[productDetail.attr_id] ? textAttr[productDetail.attr_id].value + ', ' + productDetail.attr_value : productDetail.attr_value
            }
          }
        }
      })
    }
  })

  // topping字段升序
  return moduleAttribute.sort((a, b) => {
    const atp = a.topping || 9999
    const btp = b.topping || 9999
    return atp - btp
  }).reduce((res, curr) => {
    if (imgAttr[curr.attribute_id]) {
      res.imgAttr.push(imgAttr[curr.attribute_id])
    } else if (textAttr[curr.attribute_id]) {
      res.textAttr.push(textAttr[curr.attribute_id])
    }
    return res
  }, { textAttr: [], imgAttr: [] })
}
