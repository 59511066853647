/**
 * 推荐
 */
const mutations = {
  updateShowNewGoodsRecommend(state, payload) {
    state.recommendState.showNewGoodsRecommend = payload
  },
  updateNewRecommendInfo(state, payload) {
    state.recommendState.newRecommendInfo = payload
  },
}

export default mutations
