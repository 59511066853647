import checkoutGetters from './checkoutGetters.js'
import promotionGetters from './promotionGetters.js'
import recommendGetters from './recommendGetters.js'

const getters = {
  ...promotionGetters,
  ...checkoutGetters,
  ...recommendGetters,
  // 是否展示购物车权益
  showCartRights(state) {
    return  state.cartAbtInfo?.cartRightsFuns?.param?.cart_rights_funs == 'on'
  },
  // 免邮活动置顶显示，与置底互斥
  cartShippingTop(state) {
    return state.cartAbtInfo?.cartRightsFuns?.param?.cart_rights_funs === 'on' || state.cartAbtInfo?.CartShippingTopSwitch?.param?.cart_shipping_top_switch === 'on'
  },
  // 是否展示新版的全场活动置底的免邮模块（可以凑单免邮活动，还可以凑单邮费券 wiki.pageId=1322964901）
  // 购物车置底的免邮活动, 只做单mall
  cartShippingAddPriority(state) {
    return 'C'
  },
  // 展示新免邮活动
  isShowNewFreeShip(state, getters) {
    return !state.isPlatForm && ['A', 'B', 'C'].includes(getters.cartShippingAddPriority)
  },
  singleMallCode(state) {
    return state.isPlatForm ? 'platform' : state.carts[0]?.mall_code || ''
  },
  // 购物车吸底活动是否展示节日样式（黑五）
  isShowCartActivityColor(state) {
    return state.cartAbtInfo?.CartActivityColor?.param?.cart_activity_color == 'on'
  },
  showQuickShipTag(state){
    return !state.isPlatForm  && state.cartAbtInfo['TradeQuickship'] && state.cartAbtInfo['TradeQuickship'].param == 'show_QuickShipping' ? true : false
  },
  // 是否调整购物车主图尺寸并展示新的商品行标签样式（UG-101110）
  isNewCartrowVision(state) {
    return state.cartAbtInfo?.NewCartrowVision?.param?.new_cartrow_vision === 'on'
  },
  isNewStoreIcon(state) {
    return state.cartAbtInfo?.storeiconchange?.param?.storeiconchange === 'new'
  },
  // 店铺新abt
  cartShowStore(state) { // 是否显示店铺
    return state.cartAbtInfo.cartShowStoreCode?.param?.cart_show_storecode === 'on'
  },
  isSuggested(state) {
    return state.locals?.IS_SUGGESTED || false
  },
  // 凑单组件点击商品是否跳转商详
  cartAddTradeGoodsDetailSwitch(state) {
    return state.cartAbtInfo?.CartaddGoodsdetailSwitch?.param?.cartadd_trade_goodsdetail_switch === 'on'
  },
  // 购物车楼层弹窗
  cartfloorInform(state) {
    return state.cartAbtInfo?.IncentiveInform?.param?.CartfloorInform === 'on'
  },
  underPriceAbtValue (state) {
    return state.cartAbtInfo.UnderPrice?.param?.UnderPriceShow == 'A' ?  true : false
  },
  isNewReturnFlag(state) {
    return state.cartAbtInfo?.Cartrefund?.param?.cart_nonrefundable === 'on'
  },
  /**
   * 负向信息聚合（不可退商品、不可参与积分活动商品、不可参与包邮商品、不可参与优惠券的商品）
   * return {} {mall_code: {noReturn:{ title: 'xxx', list: []},noPoint:{ title: 'xxx', list: []},noFreeShipping:{ title: 'xxx', list: []},noCoupon:{ title: 'xxx', list: []}}}
   */
  negativeInformation(state){
    let negativeInfo = {}
    let normalCarts = state.carts.filter(item => item.isInvalid != '1')

    const negativeInfoTypeHandle = ({ cartItem, type, title }) => {
      if(!negativeInfo[cartItem.mall_code]) negativeInfo[cartItem.mall_code] = {}
      if(!negativeInfo[cartItem.mall_code][type])  {
        negativeInfo[cartItem.mall_code][type] = {
          title: title,
          list: []
        }
      }
      negativeInfo[cartItem.mall_code][type].list.push(cartItem)
    }

    normalCarts.forEach(cartItem => {
      const negativeInfo = cartItem.aggregateProductBusiness?.negativeInfo || {}
      if (negativeInfo.notPointsText) {
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noPoint', title: negativeInfo.notPointsText })
      }
      if (negativeInfo.notCouponText) {
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noCoupon', title: negativeInfo.notCouponText })
      }
      if (negativeInfo.noFreeShippingText) { // 平台级物流信息
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noFreeShipping', title: negativeInfo.noFreeShippingText })
      }
      // 商品行不显示，则在负向信息弹窗内显示
      if (negativeInfo.showNotReturn !== '1' && negativeInfo.notReturnText) {
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noReturn', title: negativeInfo.notReturnText })
      }
    })

    return negativeInfo
  },
  needFlushPrerenderCache(state) {
    return !!state.cartLeave || !state.loginStatus || !state.cartInfo.checkedCartSumQuantity
  },
  /**
   * 购物车支持券筛选-开启券筛选或券凑单
   * select-券筛选，couponhelper-券凑单（默认）,both-共存
   * @param {*} state 
   * @returns string
   */
  couponSelectType(state) {
    return state.cartAbtInfo?.cartSelect?.param?.CouponSelectType || 'couponhelper'
  },
  // 券助手与券权益、券凑单共存
  cartCouponEntranceLog(state) {
    return state.cartAbtInfo?.Cartcouponadd?.param?.cart_coupon_entrance_log === 'on'
  },
  // 是否开启购物车券筛选与底部feed流联动
  isCartSelectRecommend(state) {
    return state.cartAbtInfo?.cartSelect?.param?.cartSelect_recommend == 'on'
  },
  // 顶部展示免邮信息：多mall展示平台级、单mall展示mall级
  topShippingInfo (state, getters) {
    if(state.isPlatForm) {
      return state.mallShippingInfo[`platform`] || {}
    }
    return getters.singleMallCode ? state.mallShippingInfo[getters.singleMallCode] || {} : {}
  },
  // 购物车筛选标签样式，off-线上平铺，logo-logo样式，text-文字样式
  filterSelectLogo(state) {
    return state.cartAbtInfo?.cartSelect?.param?.ControlSelectlogo || 'off'
  },
  // 购物车引导至推荐
  cartRecommendGuideConfig(state) {
    const { RecommendGuide = 'off', RecommendLine = 20, RecommendTime = 25 } = state.cartAbtInfo?.cartRecommendGuide?.param || {}
    return {
      style: RecommendGuide,       // off-无引导；A-气泡提示；B-提示插入商品行
      line: Number(RecommendLine), // 20行后再触发引导至推荐
      time: Number(RecommendTime)  // 25s后再触发引导至推荐
    }
  },
  // 是否不在购物车商品行名称末端展示收藏按钮，并将收藏收入到左滑功能之中
  wishlistInLeftSliding(state) {
    return state.cartAbtInfo?.CartrowWishlistIcon?.param?.cartrow_wishlist_icon === 'on'
  },
  // 购物车筛选是否常驻置顶
  isFilterControlTop(state) {
    return state.cartAbtInfo?.cartSelect?.param?.control_top == 'on'
  },
  // SFS商品标签及利益点 pageId=1492949172
  CartOrderSFSVisual(state) {
    return {
      visual_tabs: state.cartAbtInfo?.CartOrderSFSVisual?.param?.visual_tabs == 'show_tabs',
      visual_benefit_point: state.cartAbtInfo?.CartOrderSFSVisual?.param?.visual_benefit_point == 'show_benefit_point'
    }
  },
  // 购物车底部推荐点刷触发时机(off \ all \ click \ delete)
  cartLinkedRecTiming(state) {
    return state.cartAbtInfo?.cartLinkedReco?.param?.cartLinkedRecTiming || 'off'
  },
  // 购物车底部推荐点刷触发条件(距离推荐位行数，含失效商品行)
  cartLinkedRecoLine(state) {
    return state.cartAbtInfo?.cartLinkedReco?.param?.cartLinkedRecoLine || '4'
  },
  // 购物车底部推荐点刷触发次数
  cartLinkedRecoCount(state) {
    return state.cartAbtInfo?.cartLinkedReco?.param?.cartLinkedRecoCount || '2'
  },
  priceChangeTips(state) {
    const price_change_tips = state.cartAbtInfo?.priceChangeTips?.param?.price_change_tips || 'off'
    return price_change_tips === 'on'
  },
  cartPriceStyle(state, getters) {
    if (getters.isSuggested) return false // 合规站点走old逻辑
    return state.cartAbtInfo?.cartPriceStyle?.param?.cart_price_style === 'on'
  },
}
export default getters
