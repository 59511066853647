import CartGuide from '../CartGuide.js'

class CouponFilterGuide extends CartGuide {
  constructor({ priority } = {}) {
    super({
      priority: priority ?? 20,
    })
  }
}

const _couponFilterGuide = typeof window !== 'undefined' ? window.__cartCouponFilterGuide__ || (window.__cartCouponFilterGuide__ = new CouponFilterGuide()) : {}

export default _couponFilterGuide
