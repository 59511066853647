import Vue from 'vue'
import { isEqual } from 'lodash'
import checkoutIncentive from 'public/src/pages/cart_v2/components/cartCheckoutIncentive/checkoutIncentive.js'
import overLimitMutaion from './overLimitMutaion'
import recommendMutaion from './recommendMutaion'

const DialogComponentMap = {
  cartCouponHelperShow: 'CouponHelperDrawer', // 优惠券助手
  cartSharePageShow: 'CartSharePage', // 购物车分享页
}
const cartDialogComponentMap = {
  // addOnInfo: 'addForCouponIsLoaded', // 优惠券凑单
  // showShareDraw: 'cartShareDrawIsLoaded', // 优惠券凑单
  fullGiftShow: 'cartFullGiftsDrawIsLoaded',
  addBuyShow: 'cartAddBuyDrawIsLoaded',
  showCanceledOrderDraw: 'canceledOrderProductListIsLoaded',
  showCartFloorDrawer: 'cartFloorDrawerIsLoaded', // 购物车楼层弹窗
  showLowPriceUser: 'cartLowPriceUserDrawIsLoaded', // 低客单弹窗
  showPromotionInterception: 'cartPromotionInterceptionDrawIsLoaded', // 促销拦截弹窗
  showPromotionInterceptionV2: 'cartPromotionInterceptionV2DrawIsLoaded', // 促销拦截弹窗V2
  soldOutDialogShow: 'soldOutDialogIsLoaded', // 缺货商品弹窗
  negativeInformationDraw: 'negativeInformationDrawIsLoaded', // 负向信息弹窗
}

const getPickStatus = (promotion) => {
  if (!promotion?.type_id) return ''
  return [22, 13].includes(+promotion?.type_id) ? 'addBuyShow' : 'fullGiftShow'
}

const mutations = {
  updateState (state, payload) {
    const { key, value } = payload
    if (cartDialogComponentMap[key] && (value.show || value === true)) {
      if(!state.asyncComponentLoadedStatus[cartDialogComponentMap[key]]){
        state.loadingShow = true
        state.asyncComponentLoadedStatus[cartDialogComponentMap[key]] = true
      }
    }
    state[key] = value
  },
  updateCartItemMap(state, payload) {
    const { carts } = payload
    carts.forEach(item => {
      if (state.cartItemMap[item.id] === undefined || !isEqual(state.cartItemMap[item.id], item)) {
        Vue.set(state.cartItemMap, item.id, item)
        return
      }
    })
  },
  initLocalChecked(state) {
    state.localChecked = {}
  },
  updateLocalChecked(state, payload) {
    const { ids, checked } = payload
    ids.forEach(id => {
      Vue.set(state.localChecked, id, checked)
    })
  },
  resetLocals(state) {
    if (state?.locals) return
    if (typeof window === 'undefined') return
    let { host, lang, langPath, currency, appLanguage, GB_cssRight, LAZY_IMG, IMG_LINK, PUBLIC_CDN, SiteUID, RESOURCE_SDK, IS_SUGGESTED,
    } = gbCommonInfo
    state.locals = { host, lang, langPath, currency, appLanguage, GB_cssRight, LAZY_IMG, IMG_LINK, PUBLIC_CDN, SiteUID, RESOURCE_SDK, IS_SUGGESTED,
    }
  },
  handleBatchActive (state, payload) {
    const { value, checkSoldout } = payload

    if (value === undefined) {
      state.batchActive = !state.batchActive
    } else {
      state.batchActive = value
    }

    if (state.batchActive) {
      if (checkSoldout) {
        state.batchEditItemIds = [].concat(state.soldoutItems.map(item => item.id))
      }

      checkoutIncentive.stop({ hide: true })
    } else {
      state.batchEditItemIds = []

      setTimeout(() => {
        checkoutIncentive.initialize(state.checkoutState.cartLureInfo)
      }, 300)
    }
  },
  // 更新券助手弹窗状态
  toggleCartCouponHelperDrawer(state, payload) {
    const { value = false, isUpdateHelperOnly = false } = payload
    if (isUpdateHelperOnly) {
      state.isUpdateHelperOnly = isUpdateHelperOnly
      return
    }
    if (value) {
      state.dialogComponent.other = DialogComponentMap['cartCouponHelperShow']
    }
    state.drawerCouponHelper = !!value
  },
  // 更新领券弹窗状态
  toggleCartGetCouponDrawer(state, payload) {
    const { value = false } = payload
    state.drawerGetCoupon = !!value
  },
  updateFilterState({ filterState }, payload) {
    Object.assign(filterState, payload)
  },
  updateCarouselState({ carouselState }, payload) {
    Object.assign(carouselState, payload)
  },
  updatePromotionState({ promotionState }, payload) {
    Object.assign(promotionState, payload)
  },
  updateOrderState({ orderState }, payload) {
    Object.assign(orderState, payload)
  },
  updateCheckoutState({ checkoutState }, payload) {
    Object.assign(checkoutState, payload)
  },
  updatePaypalState({ paypalState }, payload) {
    Object.assign(paypalState, payload)
  },
  /**
   * 打开通用弹窗
   */
  handleModal (state, payload) {
    const { SHEIN_KEY_PWA_15146 = '', SHEIN_KEY_PWA_15498 = '' } = state.language
    const { show = true, okCallback, cancelCallback, oktext = SHEIN_KEY_PWA_15146, canceltext = SHEIN_KEY_PWA_15498, text = '', okDaId = '', cancelDaId = '' } = payload
    
    const okCb = function () {
      state.modalOptions.show = false
      okCallback && okCallback()
    }

    const cancelCb = function () {
      state.modalOptions.show = false
      cancelCallback && cancelCallback()
    }

    state.modalOptions = {
      show,
      okCallback: okCb,
      cancelCallback: cancelCb,
      oktext,
      canceltext,
      text,
      component: 'commonModalOptions',
      okDaId,
      cancelDaId
    }
  },
  // 未登录状态下，加载paypal弹框组件
  changeAsyncPaypalDialogLoadedStatus (state, payload) {
    const { show } = payload
    if(show) {
      state.asyncComponentLoadedStatus.cartEmailConfirmDrawIsLoaded = true
      state.asyncComponentLoadedStatus.cartAccountRegisterDrawIsLoaded = true
      state.asyncComponentLoadedStatus.cartRegisterFailedDrawIsLoaded = true
    }
  },
  /**
   * 
   * @param {*} state
   * @param {*} payload {
   *  promotionId: 活动id,
   *  fromType: 'pick' | 'addItem' ｜ 'addItem-auto'
   *  }
   */
  changeAppendageDrawerStatus (state, payload) {
    const { promotionId, fromType = 'pick' } = payload
    const promoItem = state.promotionState.integrityPromotionInfo?.[promotionId]?.data
    const status = getPickStatus(promoItem)
    if (!promoItem) return
    if(cartDialogComponentMap[status] && !state.asyncComponentLoadedStatus[cartDialogComponentMap[status]]){
      state.loadingShow = true
      state.asyncComponentLoadedStatus[cartDialogComponentMap[status]] = true
    }
    state.appendageDrawer = {
      promotionId,
      fromType,
      fullGiftShow: status == 'fullGiftShow',
      addBuyShow: status == 'addBuyShow',
    }
  },
  resetAppendageDrawerStatus (state, resetAll = false) {
    if(resetAll) { // 抽屉弹窗关闭动画结束后再重置全部属性，防止关闭时数据突然消失
      state.appendageDrawer = {
        promotionId: '',
        fromType: '',
        fullGiftShow: false,
        addBuyShow: false,
      }
      return
    }
    Object.assign(state.appendageDrawer, { fullGiftShow: false, addBuyShow: false })
  },
  changeNewGoodsRecommendParams(state, payload) {
    if (payload.show && !state.asyncComponentLoadedStatus.cartNewGoodsRecommendDrawIsLoaded) {
      state.loadingShow = true
      state.asyncComponentLoadedStatus.cartNewGoodsRecommendDrawIsLoaded = true
    }
    state.newGoodsRecommendParams = payload
    if (payload.show) {
      checkoutIncentive.pause()
    } else {
      checkoutIncentive.restart()
    }
  },
  changeDiscountDetailDrawerParams(state, payload) {
    if (payload.show && !state.asyncComponentLoadedStatus.cartDiscountDetailDrawIsLoaded) {
      state.loadingShow = true
      state.asyncComponentLoadedStatus.cartDiscountDetailDrawIsLoaded = true
    }
    state.discountDetailDrawerParams = {
      show: payload.show,
      data: payload.data || state.discountDetailDrawerParams.data, // 优惠明细数据
      saData: payload.saData || {}, // 埋点数据
    }
  },
  changeAllDiscountDetailDrawerParams(state, payload) {
    if (payload.show && !state.asyncComponentLoadedStatus.cartAllDiscountDetailDrawIsLoaded) {
      state.loadingShow = true
      state.asyncComponentLoadedStatus.cartAllDiscountDetailDrawIsLoaded = true
    }
    state.allDiscountDetailDrawerParams = {
      show: payload.show,
      anchorType: payload.anchorType || '', // 锚点类型 coupon | gift
    }
  },
  updateAddressLocal (state, payload) {
    state.isAddressLocalUpdate = payload
  },
  updateCustomDrawers (state, payload) {
    const { key, action } = payload
    if (action === 'add') {
      state.customDrawerKeys.unshift(key)
    } else if (action === 'remove') {
      state.customDrawerKeys = state.customDrawerKeys.filter(item => item !== key)
    }
  },
  updateCartAbtInfo(state, payload) {
    Object.assign(state.cartAbtInfo, payload)
  },
}

export default {
  ...mutations,
  ...overLimitMutaion,
  ...recommendMutaion,
}
