
import schttp from 'public/src/services/schttp'

export default {
  namespaced: true,
  state: {
    eu_resp_persopn_detail: null,
  },
  mutations: {
    update_eu_resp_persopn_detail(state, payload) {
      state.eu_resp_persopn_detail = payload
    },
    
  },
  actions: {
    get_eu_resp_persopn({ commit, rootGetters }) {
      const goods_id = rootGetters['productDetail/common/goods_id'] || ''
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const res = await schttp({
          url: '/product/get_eu_resp_person',
          params: {
            goods_id
          },
          useBffApi: true,
        })

        const info = res?.info || {}
        const tipsContents = []
        const keys = ['nameKey', 'addressKey', 'telephoneNumberKey', 'emailAddressKey', 'customerServiceKey']
        for (const key of keys) {
          const valueKey = key.replace('Key', 'Value')
          if(info[valueKey]){
            tipsContents.push({ 
              'key': info[key],
              'value': info[valueKey]
            })
          }
        }
        commit('update_eu_resp_persopn_detail', { ...info, tipsContents })
        resolve()
      })
      
    },
  },
  getters: {
    eu_resp_person(state, getters, rootState, rootGetters) {
      let { euRespPerson } = rootState.productDetail.coldModules.productInfo || {}
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const openGPSRTips = abtInfo?.goodsdetailscompliance?.param?.gpsr_tips === 'on' || false
      return {
        showGPSRTips: euRespPerson?.euRespTitle && openGPSRTips,
        ...euRespPerson,
      }
    },
  },
}
