

/**
 * 数据层转换
 */
export const assistAdaptersHandler = ({ bffRealtimeInfo, assistData } = {}) => {

  if (!assistData) return
  if (bffRealtimeInfo) {
    assistData.modules.user = assistData.modules.user || {}
    assistData.modules.user.saveStatus = bffRealtimeInfo?.productInfo?.is_saved === '1'

    const _isFreeReturn = bffRealtimeInfo.productLabel?.goodsImgBenefitInfo?.isFreeReturn
    if (typeof _isFreeReturn !== 'undefined') { // 物流免费退货政策
      assistData.modules.shipping = assistData.modules.shipping || {}
      assistData.modules.shipping.freeReturnPolicy = Number(_isFreeReturn)
    }
  }

  return assistData

}


export const hotModulesAdaptersHandler = ({ hotModules } = {}) => {
  // console.log('hotModulesAdaptersHandler before', hotModules)

  const bffRealtimeInfo = hotModules?.BFF_INFO
  if (!hotModules || !bffRealtimeInfo) return

  // hotModules.user = hotModules.user || {}
  // hotModules.user.saveStatus = bffRealtimeInfo?.productInfo?.is_saved === '1' // 使用bff的收藏状态

  // console.log('hotModulesAdaptersHandler after', hotModules)

}
