import schttp from 'public/src/services/schttp'
import { markPoint } from 'public/src/services/mark/index.js'

/**
 * 获取Ccc首页配置的内容
 */
export const getCccIndexPageContent = async ({ url, ...data } = {}) => {
  if (!url) {
    markPoint('apiPreFetchParamsError', 'public')
    return Promise.resolve({
      code: -1,
      msg: 'miss url'
    })
  }

  const res = await schttp({
    method: 'POST',
    url,
    data
  })
  return res
}

// 动态注册预请求参数
function generateTabPreRequestParams({ triggerParams }) {
  // triggerParams：调用TMG.triggerQuickRequests传入的自定义参数
  const { preRequestTabData } = triggerParams
  const { channelId, channelName } = preRequestTabData || {}

  if (!channelId) {
    return {}
  }

  return {
    [channelId]: {
      url: '/api/home/switchChannel/get',
      switchChannelName: channelName,
      switchChannelId: channelId,
      channelInfo: preRequestTabData,
    }
  }
}


export const configIndexQuickRequests = {
  quickRequests: {
    getCccIndexPageContent: {
      generateParams: generateTabPreRequestParams,
      request: getCccIndexPageContent,
    },
  },
}
/**
 * 获取Ccc首页配置的内容
 */
export const getBffIndexPageContent = async ({ url, ...data } = {}) => {
  if (!url) {
    markPoint('apiPreFetchParamsError', 'public')
    return Promise.resolve({
      code: -1,
      msg: 'miss url'
    })
  }
  const res = await schttp({
    method: 'GET',
    url,
    params: data,
    useBffApi: true,
  })
  return res

}
// 动态注册预请求参数
function generateBffTabPreRequestParams({ triggerParams }) {
  // triggerParams：调用TMG.triggerQuickRequests传入的自定义参数
  const { preRequestTabData, countryId = '' } = triggerParams
  const { channelId, channelName, position } = preRequestTabData || {}

  if (!channelId) {
    return {}
  }

  return {
    [channelId]: {
      url: '/ccc/home_page',
      channel_id: channelId,
      country_id: countryId,
      position,
      tab_id: channelId,
      tab_name: channelName,
    }
  }
}

export const bffConfigIndexQuickRequests = {
  quickRequests: {
    getBffIndexPageContent: {
      generateParams: generateBffTabPreRequestParams,
      request: getBffIndexPageContent,
    },
  },
}