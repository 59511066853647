export default {
  namespaced: true,
  state: {},
  getters: {
    rule_id(state, getters, rootState, rootGetters) {
      const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
      if (is_use_bff_fs) {
        const { saleAttr } = rootState.productDetail.coldModules || {}
        const { shoesRecRuleId } = saleAttr || {}
        return +shoesRecRuleId || 0
      }

      const { recommend = {} } = rootState.newProductDetail.coldModules
      const { shoesRuleInfo = {} } = recommend
      const { rule_id = 0 } = shoesRuleInfo
      return rule_id
    },
    rule_type(state, getters, rootState, rootGetters) {
      const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
      if (is_use_bff_fs) {
        const { saleAttr } = rootState.productDetail.coldModules || {}
        const { ruleType } = saleAttr || {}
        return ruleType || '0'
      }

      const { recommend = {} } = rootState.newProductDetail.coldModules
      const { shoesRuleInfo = {} } = recommend
      const { rule_type } = shoesRuleInfo
      return rule_type
    },
  },
}
