import { isLogin } from 'public/src/pages/common/utils/index.js'
import { getUnreadCount } from '@customerService/BFF-Robot/index.js'

// 获取客服未读chat消息
export const getInitCsChatMsgNum = async () => {
  if (isLogin()) {
    const res = await getUnreadCount({ params: { types: 'chat' } })
    if (res?.code == '0') {
      return res.info?.chat_unread_count
    }
  }
  return 0
}

const isBrowser = typeof window !== 'undefined'
export const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
export const isAndroid = UA && UA.indexOf('android') > 0
// fix: 兼容ios13后ipad使用桌面端wkWebview，导致ua中不存在ipad标识，window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1
export const IS_IOS = (UA && /iphone|ipad|ipod|ios/.test(UA)) || (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)

export const useShowPrice = (order, showAvgPrice = false) => {
  const { avgPrice, totalPrice, currencyTotalPrice } = order || {}
  const price = totalPrice?.amountWithSymbol || currencyTotalPrice?.amountWithSymbol || ''
  if (showAvgPrice) {
    return avgPrice?.amountWithSymbol || price
  }else {
    return price
  }
}
