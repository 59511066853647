export const getDefaultHotModules = (reservedField = {}) => ({
  pageInfo: {
    fsAbt: {},
    confirmedAgeLimitExpired: false
  },
  productInfo: {
    getPrice: null,
    skuMapCompose: null,
    skcEstimatedInfo: null,
    sheinClubContent: null
  },
  promotion: {
    exclusivePromotionPrice: null,
    newFlashPromotion: null,
    promotionBeltInfo: {},
    addOnItemCate: {}
  },
  coupon: {
    storeCouponList: [],
    showCouponsUnusedCommend: false
  },
  user: {
    isPaidUser: false,
    isFreeReceiveUser: false,
    paidDetail: {},
    saveStatus: false
  },
  pay: {
    afterpayInfo: {}
  },
  getTheLook: {
    getTheLook: []
  },
  shipping: {
    countryInfo: null,
    goodsFreeShippingInfo: null,
    primeShipping: null,
    freeReturnPolicy: null,
    shippingDetail: null
  },
  recommend: {
    unifiedRecommend: {},
    topRankingInfo: {},
    isForwardCategory: false
  },
  ccc: {
    cccVideoPosition: 0,
    cccCatSelectionTag: {},
    cccFlashZoneData: {},
    cccBrandZoneLink: {},
    cccAddCartPrompt: {},
    cccStoreGoodsRecIntervention: {},
    cccBannerContext: {}
  },
  comment: {
    commentOverView: null,
    commentOverViewLocal: null,
    commentsWithImg: null,
    outStoreCommentsInfo: {},
    comments: null
  },
  store: {
    storefollowCount: null,
    storeFollowState: null
  },
  ...reservedField
})
