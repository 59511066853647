
import schttp from 'public/src/services/schttp'
import qs from 'qs'
import DETAIL_API_LOCATION from 'public/src/pages/product_app/store/modules/product_detail/api.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

const IS_PROD = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo?.NODE_SERVER_ENV === 'production' : false
const _useBFFApiParamsDebugMode = (params = {}) => ({
  ...params,
  headers: {
    sheindebug: IS_PROD || !params.useBffApi ? undefined : 676, // 非正式环境传入debug参数方便中间层调试
    ...(params.headers || {}),
  },
})
const schttpBffDebugMode = (params = {}) => schttp(_useBFFApiParamsDebugMode(params))

/**
 * 底部推荐位接口
 */
export const getBottomRecommendDataApi = async ({
  limit = 20,
  page = 1,
  recommendScene,
  shopRecProductIds,
  goodsSn,
  cat_id,
  goods_id,
  pageListType,
  attribute,
  categoriesType,
  filter_cate_id,
  isAddCart,
  featureKey, // 是否夹带特征值
} = {}) => {

  if (!goods_id || !cat_id || !goodsSn) throw 'params error'

  const params = {
    isAppRollBack: 0, // 使用新链路
    /** 页码 */
    page,
    /** 每页多少 */
    limit,
    /** 需要透传给推荐的参数 */
    pageListType,
    /** 筛选条件 */
    attribute,
    /** 商品品类id */
    cat_id,
    /** 类目类型 */
    categoriesType,
    /** 筛选品类id */
    filter_cate_id,
    /** 商品goodsSn */
    goodsSn,
    /** 商品id */
    goods_id,
    recommendScene,
    /** 来源 */
    caller: 'sheinPwa',
    shopRecProductIds,
    /** 当前商品是否有加车操作 */
    isAddCart,
  }

  const res = await schttpBffDebugMode({
    method: 'POST',
    url: 'product/get_detail_recommend_product',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(params),
    useBffApi: true,
    featureKey,
  })

  // console.log('getBottomRecommendData', res)
  return res
}

/**
 * 商品相似推荐列表
 * 商品和分类id， eg：991471_2301	
 */
export const getSimilarListRecommendDataApi = async ({ limit, page = 1, goods_id, cat_id }) => {

  if (!goods_id || typeof cat_id === 'undefined') return
  const goods_list = `${goods_id}_${cat_id}`
  const abtInfo = await getUserAbtData()
  const { similaritemsnew } = abtInfo || {}

  let rule_id = ''
  const matchResult = similaritemsnew?.param?.match(/rule_id=([^&]+)/)
  rule_id = matchResult && matchResult.length >= 2 ? matchResult[1] || '' : ''

  return schttp({
    method: 'POST',
    url: DETAIL_API_LOCATION.RECOMMEND_SIMILAR_LIST,
    params: {
      limit,
      rule_id,
      page,
      goods_list,
    },
    useBffApi: true
  })
}


/**
 * 批量校验商品库存数据

 */
export const batchCheckGoodsStockDataApi = async (productCheckData = []) => {

  if (!productCheckData?.length) return

  return schttp({
    method: 'POST',
    url: '/product/batch_check_goods_stock',
    data: {
      productCheckData,
    },
    useBffApi: true
  })
}

// 相似推荐
export const getFindSimilarDataApi = async (params = {}) => {
  const res = await schttpBffDebugMode({
    method: 'POST',
    url: '/product/recommend/get_similar_list',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(params),
    useBffApi: true,
  })

  return res
}

// 商详静态接口
export const getDetailStaticDataApi = async ({ goods_id, mall_code } = {}) => {
  if (!goods_id) return null
  const result = await schttp({
    url: DETAIL_API_LOCATION.PRODUCT_STATIC_INFO,
    params: {
      goods_id,
      mall_code,
      isUserSelectedMallCode: 1,
    },
    useBffApi: true,
  })
  return result?.info || {}
}
