import commonState from './commonState'
import filterState from './filterState'
import carouselState from './carouselState'
import promotionState from './promotionState'
import orderState from './orderState'
import checkoutState from './checkoutState'
import paypalState from './paypalState'
import overLimitState from './overLimitState'
import recommendState from './recommendState'

const state = {
  ...commonState,
  filterState,
  carouselState,
  promotionState,
  orderState,
  checkoutState,
  paypalState,
  overLimitState,
  recommendState,
}

export default state
