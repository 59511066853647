import schttp from 'public/src/services/schttp'
import { Toast } from '@shein/sui-mobile'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { getFormatBatchAddErrorResult } from 'public/src/pages/goods_detail/RecommendRelatives/CommonCart/utils.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import qs from 'qs'
import { addCartBatch } from 'public/src/pages/goods_detail_v2/innerComponents/top/AddToCartBar/utils.js'
const { SiteUID, shein_suggested_sale_price, lang } = gbCommonInfo

export default {
  namespaced: true,
  state: {
    gift_list: [],
    gift_selected_status: false,
    gift_selected_list: [],
    gift_lowest_price: '',
    gift_selected_prices: '',
    gift_batch_add_status: {},
    is_reserve_selected: false,
  },
  getters: {
  },
  mutations: {
    update_gift_list(state, payload) {
      state.gift_list = payload
    },
    update_gift_selected_list(state, payload) {
      state.gift_selected_status = payload?.gift_selected_status || false
      state.gift_selected_list = payload?.gift_selected_list || []
    },
    update_gift_lowest_price(state, payload) {
      state.gift_lowest_price = payload
    },
    update_gift_selected_prices(state, payload) {
      state.gift_selected_prices = payload
    },
    update_gift_batch_add_status(state, payload) {
      state.gift_batch_add_status = payload
    },
    update_gift_reserve_status(state, payload) {
      state.is_reserve_selected = payload
    },
  },
  actions: {
    async get_gift_list({ state, commit, rootGetters }, payload) {
      commit('update_gift_list', [])
      const giftGoodsSelectId = payload || ''
      if(!giftGoodsSelectId) {
        commit('update_gift_selected_list', {
          gift_selected_status: false,
          gift_selected_list: [],
        })
        return
      }
      // 选品id获取商品列表
      let params = {
        select_id: giftGoodsSelectId,
        gift_goods_type: '1',
        limit: 50,
        page: 1,
      }
      const bffInfo = await schttp({
        method: 'POST',
        url: '/category/get_select_product_list',
        data: qs.stringify(params),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        useBffApi: true,
      })
      let { info: { products = [], lowestPrice = {} } = {} } = bffInfo || {}
      const isPaidUser = rootGetters['productDetail/common/is_paid_user']
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const suggestedSaleType = shein_suggested_sale_price?.[SiteUID] || ''
      products = products?.map(v => {
        return {
          ...goodsTransformation(v, {
            language: language_v2,
            lang: lang ?? 'us',
            isPaid: isPaidUser,
            suggestedSaleType,
          }),
          _cartInfo: {
            selectStatus: false,
            isSoldOut: false,
            skuCode: v.sku_code,
          },
        }
      }) || []
      
      // test
      // products[0].stock = 0
      // products[0]._cartInfo.isSoldOut =  Number(products[0].is_on_sale) === 0 || Number(products[0].stock) === 0
      // products[1]._cartInfo.skuCode = 'I9j62hbmal32'
      // products[2]._cartInfo.skuCode = 'I03ywepj50fy' // 售罄
      // test
      commit('update_gift_list', products)
      commit('update_gift_lowest_price', lowestPrice?.amountWithSymbol || '')
      let flag = state.gift_selected_list.every(a => products.some(b => b.goods_id == a.goods_id))
      // 切换skc，选中的包材商品完全匹配新的包材列表，则保留选中状态，否则清空选中状态
      if(!(state.is_reserve_selected && flag)){
        commit('update_gift_selected_list', {
          gift_selected_status: false,
          gift_selected_list: [],
        })
      }
      // 重置状态
      if(state.is_reserve_selected) {
        commit('update_gift_reserve_status', false)
      }
    },
    // 批量添加赠品到购物车
    async batch_gift_add_to_bag({ rootGetters, commit, dispatch }, payload) {
      let selectedList = []
      if(payload?.gift_selected_list?.length){
        selectedList = payload.gift_selected_list
      }
      if (selectedList?.length) {
        const product_list = selectedList
          .map(d => ({
            mall_code: d.mall_code,
            sku_code: d._cartInfo.skuCode,
            quantity: 1
          }))
        const is_use_bff_fs = rootGetters['productDetail/common/is_use_bff_fs']
        if (is_use_bff_fs) {
          const language_v2 = rootGetters['productDetail/common/language_v2']
          const data = await addCartBatch({ product_list }, {
            language: language_v2,
          })
          dispatch('batch_gift_goods_stock')
          commit('update_gift_selected_list', {
            gift_selected_status: false,
            gift_selected_list: [],
          })
          commit('update_gift_batch_add_status', {
            status: true,
            result: data?.result || {},
            giftSelectedList: selectedList,
          })
          if(data?.result?.code == '0') {
            // 更新右上角购物车数量
            dispatch('fetchCartInfo', null, { root: true })
            const timer = setTimeout(() => {
              clearTimeout(timer)
              // 更新右下角购物车数量
              window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
            }, 2000)
          }
          if(data?.msgInfo?.type !== 'success') {
            Toast(data?.msgInfo?.msgContent || '', 5000)
          }
          return
        }
        const result = await schttp({
          url: '/api/cart/batch_add_mall/create',
          method: 'POST',
          data: { product_list },
        }).catch(() => null)
        const language_v2 = rootGetters['productDetail/common/language_v2']
        dispatch('batch_gift_goods_stock')
        commit('update_gift_selected_list', {
          gift_selected_status: false,
          gift_selected_list: [],
        })
        commit('update_gift_batch_add_status', {
          status: true,
          result,
          giftSelectedList: selectedList,
        })
        if(result?.code === '0') {
          // 更新右上角购物车数量
          dispatch('fetchCartInfo', null, { root: true })
          setTimeout(() => {
            // 更新右下角购物车数量
            window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
          }, 2000)
        }
        if(result?.code === '300402') {
          Toast(language_v2.SHEIN_KEY_PWA_33371 || 'The wrappings were not added completely to the cart. Please check your cart for details.', 5000)
          daEventCenter.triggerNotice({
            daId: '1-6-1-244',
          })
        }else {
          const _formatError = getFormatBatchAddErrorResult(result, language_v2)
          if (_formatError._errorTips) {
            Toast(_formatError._errorTips, 5000)
          }
        }
        
      }
    },
    // 计算多个赠品价格
    get_selected_gift_prices({ state, rootState, rootGetters, commit }) {
      let selectedList = state.gift_selected_list
      commit('update_gift_selected_prices', '')
      if(selectedList.length === 0) return 
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async(resolve) => {
        const { goods_id: goodsId } = rootState.productDetail.hotModules?.productInfo

        const goodsSn = rootGetters['productDetail/common/goods_sn'] || ''
        const mallCode = rootGetters['productDetail/common/mall_code'] || ''
        const isPaidUser = rootGetters['productDetail/common/is_paid_user']
        const selectedGoodsIdSkus = selectedList.map(d => `${d.goods_id},${d.mall_code},${d.sku_code}`).join('-')
        let params = {
          goodsId,
          goodsSn,
          mallCode,
          isAddCart: '1',
          isEnterDetailCall: '1',
          isMainGoodsSoldOut: '0',
          isPaidMember: isPaidUser ? '1' : '0',
          selectedGoodsIdSkus
        }
        try {
          const bffInfo = await schttp({
            method: 'GET',
            url: '/product/get_together_better_deals_price',
            params,
            useBffApi: true,
          })
          let price = bffInfo?.info?.bundlePrice?.amountWithSymbol || ''
          commit('update_gift_selected_prices', price || '')
          return resolve(bffInfo)
        } catch (error) {
          return resolve(null)
        }
      })
      

    },
    // 批量查询赠品库存
    async batch_gift_goods_stock({ state, commit }) {
      let giftList = state.gift_list
      const productCheckData = giftList.map(v => {
        return {
          goodsId: v.goods_id,
          goodsSn: v.goods_sn,
          mallCode: v.mall_code,
          skuCode: v.sku_code,
        }
      })
      const bffInfo = await schttp({
        method: 'POST',
        url: '/product/batch_check_goods_stock',
        data: { productCheckData },
        useBffApi: true,
      })
      let products = bffInfo?.info?.productDetailData || []
      products.forEach((productItem) => {
        let isSoldOut = Number(productItem.isOnSale) === 0 || Number(productItem.stock) === 0
        // eslint-disable-next-line no-cond-assign
        if (isSoldOut) {
          const targetItem = giftList.find(item => item.goods_id === productItem.goodsId)
          if (targetItem) {
            targetItem._cartInfo.isSoldOut = true
            // 更新商卡的售罄状态
            targetItem.stock = productItem.stock
            targetItem.is_on_sale = productItem.isOnSale
          }
        }
      })
      commit('update_gift_list', giftList)
    },
  },
}
