/**
 * @field 推荐
*/
export default {
  buy_box_recommend_info(state, getters, rootState) {
    return rootState.productDetail?.asyncModules?.buyBoxRecommendInfo || {}
  },
  is_main_goods_lowest_price(state, getters, rootState, rootGetters) {
    // 当前商品是否为buy box最低价商品 1-是，其他-否
    const buy_box_recommend_info = getters.buy_box_recommend_info
    const isRetentionProduct = rootGetters['productDetail/Reviews/is_retention_product']

    const isLowestPriceOfMainProduct = +buy_box_recommend_info?.isLowestPriceOfMainProduct === 1
    return !!(isRetentionProduct && isLowestPriceOfMainProduct) ? 1 : 0
  },
}
