export default {
  namespaced: true,
  getters: {
    designer_title: (state, getters, rootState, rootGetters) => {
      const language_v2 = rootGetters['productDetail/common/language_v2'] || {}
      return language_v2.SHEIN_KEY_PWA_24088
    },
    designer_info: (state, getters, rootState) => {
      // state, getters, rootState
      // return {
      //   'avatarPicUrl': 'http://file.ltwebstatic.com/bdms/2021/10/28/1635419869764023520.jpg',
      //   'designerName': 'RAUL OROZCO',
      //   'insAccountName': 'raulorozcomx',
      //   'sheinxDesignId': 150,
      //   'designerH5Url': 'https://api-shein-gray01.shein.com/h5/designer/150'
      // }
      return rootState.productDetail.coldModules?.designerInfoBO || {}
    },
    is_show_designer_info: (state, getters) => {
      return !!getters.designer_info?.designerName
    }
  }
}
