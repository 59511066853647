import { detectUserInteraction, isTimestampWithinDuration } from './incentiveTools.js'

class CartCheckoutIncentive {
  constructor() {
    this.status = 'notStarted'
    this.config = {
      countdownDuration: 10, // 倒计时时长
      noActDuration: 3, // 无操作时长
      disappearDuration: 3, // 弹窗展示时长
      intervals: 3, // 切换间隔时长
      displayNum: 3, // 显示数量
    }
    this.hooks = {}

    this.index = 0
    this.cartLureList = []
    this.blacklistCacheKeys = [] // 黑名单气泡 cacheKey
    this.vaildCartLureList = [] // 有效的购物车诱导
    this.displayedCartLureListMap = new Map() // 已展示的购物车诱导

    this.showTimer = null
    this.hideTimer = null
  }

  updateCartLureList(cartLureList = []) {
    this.cartLureList = cartLureList.filter(cartLure => cartLure.source === '2')
    this.updateVaildCartLureList()
  }

  setBlacklistCacheKeys(cacheKeys) {
    this.blacklistCacheKeys.push(...cacheKeys)
    this.updateVaildCartLureList()
  }

  updateVaildCartLureList() {
    const res = []
    this.cartLureList.forEach(item => {
      if (this.blacklistCacheKeys.includes(item.cacheKey)) return
      res.push(item)
    })
    this.vaildCartLureList = res
    this.callIsStop()
  }

  setDisplayedCartLureMap(cartLure) {
    this.displayedCartLureListMap.set(cartLure.type, cartLure)
  }

  getMixDisplayNum() {
    return Math.min(this.config.displayNum, this.vaildCartLureList.length)
  }

  initialize(cartLureInfo) {
    if (typeof window === 'undefined') return
    if (!(this.status === 'notStarted' || this.status === 'stopped')) return

    if (cartLureInfo.config) this.config = cartLureInfo.config

    this.blacklistCacheKeys = []
    this.updateCartLureList(cartLureInfo.cartLureList)
    this.index = 0

    if (this.vaildCartLureList.length === 0) return

    // 如果所有气泡都展示完毕, 清空已展示的气泡
    if (!this.vaildCartLureList.find(cartLure => !this.displayedCartLureListMap.get(cartLure.type))) {
      this.displayedCartLureListMap.clear()
    }

    this.status = 'notStarted'

    let hasLimitCartLure = !!this.getLimitCartLure()
    if (hasLimitCartLure) {
      this.start()
    } else {
      detectUserInteraction({
        noInteractionTimeout: this.config.noActDuration || 3, // 单位为秒
        scrollThreshold: 2, // 屏幕高度的倍数
        onNoInteraction: () => {
          console.log('用户在首次进入3秒内没有交互。')
          this.start()
        },
        onScrollThresholdReached: () => {
          console.log('用户滚动超过了两屏。')
          this.start()
        }
      })
    }

  }

  getNextCartLure() {
    const next = this.vaildCartLureList.find(cartLure => !this.displayedCartLureListMap.get(cartLure.type))
    return next
  }

  triggerShow() {
    const isStop = this.callIsStop()
    if (isStop) return

    const cartLure = this.getNextCartLure()
    if (!cartLure) {
      this.stop()
      return
    }

    const res = this.hooks.show?.({
      cartLure,
    })
    if (res) {
      this.index++
      this.setDisplayedCartLureMap(cartLure)

      this.callIsStop()

      clearTimeout(this.hideTimer)
      this.hideTimer = setTimeout(() => {
        this.triggerHide()
      }, this.config.disappearDuration * 1000)
    } else {
      if (this.status === 'stopped' || this.status === 'paused') return
      this.showTimer = setTimeout(() => {
        if (this.status === 'stopped' || this.status === 'paused') return
        this.triggerShow()
      }, this.config.intervals * 1000)
    }
  }

  triggerHide() {
    clearTimeout(this.hideTimer)
    this.hideTimer = null

    this.hooks.hide?.()

    this.callIsStop()

    if (this.status === 'stopped' || this.status === 'paused') return
    this.showTimer = setTimeout(() => {
      if (this.status === 'stopped' || this.status === 'paused') return
      this.triggerShow()
    }, this.config.intervals * 1000)
  }

  callIsStop() {
    if (this.status === 'stopped') return true
    if (this.index >= this.getMixDisplayNum()) {
      this.stop()
      return true
    }
    return false
  }

  getLimitCartLure() {
    return this.vaildCartLureList.find(f => f.bubble.timestamp && isTimestampWithinDuration(f.bubble.timestamp * 1000, this.config.countdownDuration * 60 * 1000))
  }

  // 开始
  start() {
    if (this.status !== 'notStarted') return
    this.status = 'running'

    this.triggerShow()
  }

  // 暂停
  pause() {
    this.hooks.hide?.()

    if (this.status !== 'running') return
    this.status = 'paused'

    this.triggerHide()
  }

  // 重启
  restart() {
    if (this.status !== 'paused') return
    this.status = 'running'

    this.triggerHide()
  }

  // 结束
  stop({ hide = false } = {}) {
    if (hide) this.hooks.hide?.()

    this.status = 'stopped'

    // 清除定时器
    clearTimeout(this.showTimer)
    this.showTimer = null
    clearTimeout(this.hideTimer)
    this.hideTimer = null
  }

  setHooks(hooks) {
    this.hooks = {
      show: (...args) => {
        return hooks.show?.(...args)
      },
      hide: (...args) => {
        return hooks.hide?.(...args)
      },
    }
  }
}

// 单例
const _cartCheckoutIncentive = typeof window !== 'undefined' ? window.__cartCheckoutIncentive__ || (window.__cartCheckoutIncentive__ = new CartCheckoutIncentive()) : {
  updateCartLureList: () => {},
}

export default _cartCheckoutIncentive
