function formatList (list = []) {
  list.forEach((item, index) => {
    const commentImg = item.comment_image?.[0]?.member_image_middle || item.img || ''
    // 临时兼容低版本webp图片兼容问题 - compliantWebpImg
    item.firstImg = commentImg?.replace('.webp', '.jpg')
    // 红人图没有comment_id,为了统一适配大图，添加comment_id
    !item.comment_id && (item.comment_id = (item.id + '_' + index))
  })
  return list
}
export default {
  namespaced: true,
  state: {
    commentScrollLoading: false, 
    no_more_comment: false,
    customer_show: false,
  },
  getters: {
    customer_show_list(state, getters, rootState) {
      return formatList(rootState.productDetail.CommentPopup.customer_show_list)
    },
    // 所有买家秀总数之和 红人图 + 有图评论 + 报告数（不包括站外评论）
    all_customer_gallery_sum(state, getters, rootState) {
      return rootState.productDetail.CommentPopup.customer_show_total
    },
    review_abt(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const dataSource = fsAbt?.Reviewchange?.param?.DataSource_Review || 'Gals_FeaturedBuyerShow'
      const shinerNoMore = ['None', 'Gals', 'FeaturedBuyerShow', 'Gals_FeaturedBuyerShow', 'FeaturedBuyerShow_TrailReport'].includes(dataSource)
      const galleyLayout = fsAbt?.Reviewchange?.param?.Layout_Review || 'BigPic'
      const reviewText = fsAbt?.Reviewchange?.param?.Reviewtext || 'None'
      let minNum = fsAbt?.Reviewchange?.param?.Minmum_Num || 'None'
      minNum = minNum === 'None' ? 0 : +minNum
      return {
        galleyLayout: galleyLayout,
        minNum: minNum,
        dataSource: dataSource,
        shinerNoMore: shinerNoMore,
        reviewText: reviewText
      }
    },
    show_SHEINer(state, getters, ) {
      const { customer_show_list, all_customer_gallery_sum, review_abt } = getters
      return customer_show_list?.length && all_customer_gallery_sum >= review_abt.minNum
    },
  },
  mutations: {
    update_customer_show(state, payload) {
      state.customer_show = payload
    },
  }
}
