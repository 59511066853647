import { template } from '@shein/common-function'
export default {
  namespaced: true,
  state: {},
  getters: {
    topInfoBase(state, getters, rootState) {
      const { productInfo = {}, commonInfo = {}, pageInfo } = rootState.newProductDetail.coldModules
      const { host_rp, langPath_rp, IS_RW, SiteUID, GB_cssRight, PUBLIC_CDN_rp } = commonInfo
      return {
        language: pageInfo?.language,
        PUBLIC_CDN_rp,
        GB_cssRight,
        SiteUID,
        IS_RW,
        host_rp,
        langPath_rp,
        detail: productInfo?.detail,
      }
    },
    /** 价格显示位置 */
    // abtPriceLocatTop(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   return fsAbt?.PriceLocat?.p?.PriceLocat === 'A'
    // },
    // designerInfo(state, getters, rootState) {
    //   return rootState.newProductDetail.coldModules.productInfo?.designerInfo || {}
    // },
    // isShowDesignerInfo(state, getters) {
    //   // const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   return getters.designerInfo?.designer_name
    //   // return (
    //   //   fsAbt?.sheinxreinforce?.p?.sheinxreinforce === 'new2' && getters.designerInfo?.designer_name
    //   // )
    // },
    // 最低价提示相关
    lowestPriceTipsConfig(state, getters, rootState, rootGetters) {
      const { pageInfo, tsp = {} } = rootState.newProductDetail.coldModules
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { hitLowestPrice = '' } = tsp?.tspConfigCombo || {}
      const { pricestatues = {} } = fsAbt
      const { lowestprice } = pricestatues?.p || {}
      const { language } = pageInfo
      if (!hitLowestPrice) return { show: false }
      return {
        show: lowestprice == 'show',
        hitLowestPrice,
        contentText: template(hitLowestPrice, language.SHEIN_KEY_PWA_26774),
      }
    },
    /**
     * @returns { Object }
     *  @param { Boolean } show 是否显示
     *  @param { String } text 文案内容
     *  @param { String } type 文案类型 
     *                          [retentionBelt 保留款腰带 / lowestBelt x天最低价腰带 / followBelt 近期低价腰带] 
     *                          [retentionText 保留款文本 / lowestText x天最低价文本 / followText 近期低价文本] 
     */
    bestDealConfig(state, getters, rootState, rootGetters) {
      // 这里聚合了 保留款｜x天最低价｜近期低价  优先级： 「保留款」>「x天最低价」 >「近期降价」
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const isPerformance = rootGetters['newProductDetail/isPerformance']
      const retentionPositionConfig = rootGetters['newProductDetail/common/retentionPositionConfig']
      const isShowFollowBelt = rootGetters['newProductDetail/common/isShowFollowBelt']
      const isSoldOut = rootGetters['newProductDetail/common/isSoldOut']
      const { goodsReady } = rootState.newProductDetail.common

      const { lowestPriceTipsConfig } = getters
      const { pageInfo } = rootState.newProductDetail.coldModules
      const { pricestatues = {} } = fsAbt
      const { language } = pageInfo
      const { showtype = 'banner' } = pricestatues?.p || {} 
      if (isSoldOut || (!goodsReady && isPerformance)) return { show: false }
      let text = '',
          tipsType = '', // 保留款 2｜x天最低价 1｜近期低价 3
          rightText = '',
          type = null
      if (retentionPositionConfig.show) {
        text = retentionPositionConfig.contentText
        rightText = retentionPositionConfig.rightText
        tipsType = 2
        type = 'retention'
      } else if (lowestPriceTipsConfig.show) {
        text = lowestPriceTipsConfig.contentText
        tipsType = 1
        type = 'lowest'
      } else if (isShowFollowBelt) {
        text = language.SHEIN_KEY_PWA_25452
        tipsType = 3
        type = 'follow'
      } else return { show: false }
      
      if ((showtype == 'banner' || !showtype) && tipsType !== 1) type = type + 'Belt'
      if (showtype == 'pricebottom' || tipsType == 1) type = type + 'Text'

      return {
        text,
        type,
        tipsType,
        rightText
      }
    },
    promotionBeltInfo(state, getters, rootState) {
      return rootState.newProductDetail.hotModules.promotion.promotionBeltInfo || {}
    },
    isEvolushein(state, getters, rootState, rootGetters){
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const showEvolushein = fsAbt?.evolushein?.p?.evolushein === 'show'
      const { evoluColorList = {}, detail: { goods_sn = '' } = {} } = rootState.newProductDetail.coldModules.productInfo
      return !!evoluColorList?.[goods_sn] && showEvolushein
    }
  },
}
