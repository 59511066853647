/**
 * 仅首屏数据用到的字段
 */

export const PRODUCT_INFO_INIT = [
  'beauty_ingredients', 
  'goods_id', 
  'goods_name',
  'selectedMallCode', 
  'is_on_sale',
  'stock',
  'cccDetailsTemplate',
  'shoppingSecurityInfo',
  'cat_id',
  'currentSkcImgInfo',
  'allColorDetailImages',
  'videoInfo',
  'videoSort',
]

export const PRODUCT_INFO_ASYNC = [
  'cccDetailsTemplate',
  'attrModuleComponentConfig',
  'productDescriptionInfo',
  'models_info',
]

export const SALE_ATTR_INIT = [
  'mainSaleAttribute',
  'multiLevelSaleAttribute',
]

export const SALE_ATTR_ASYNC = [
  'findMyShadeH5Url',
  'multiLevelSaleAttribute'
]

export const STORE_INFO_INIT = [
  'storeLabels',
  'inFashionLabel',
  'isBrandStore',
  'storeStatus',
  'isFashionLabel',
  'choiceStoreIcon',
  'temporaryFashionLabel',
]

export const PRODUCT_LABEL_INIT = [
  'isShowEvoluOnTitle',
  'featureSubscript',
  'trendInfo',
]

export const BRAND_DETAIL_INFO_INIT = [
  'authenticBrand'
]
