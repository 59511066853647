export default {
  namespaced: true,
  state: {},
  getters: {
    show_parallel_import(state, getters, rootState) {
      return rootState.productDetail.hotModules.storeInfo?.parallelImportInfo?.isShowParallelImportArticle === '1'
    },
    matching_style_block_show(state, getters) {
      const hasData = Object.keys(getters.relation_data)?.some(series => {
        let data = getters.relation_data[series]
        return data.list?.length
      })
      return hasData

    },
    relation_data: (state, getters, rootState, rootGetters) => {
      const multi_property_products_goodsIds = rootGetters['productDetail/Recommend/multi_property_products_goodsIds'] || []
      const val = rootState.productDetail.asyncModules.machingStyleProducts || []
      const result = {}
      val.forEach(theme => {
        const themeName = theme.themeName
        result[themeName] = {
          list: [],
          mainImg: '',
          theme_name: themeName
        }
        theme.series_info.forEach(series => {
          series.products.forEach(product => {
            result[themeName].mainImg = series.series_img
            const isSameFromMultiProperty = multi_property_products_goodsIds.includes(product.goods_id)
            !isSameFromMultiProperty && result[themeName].list.push(product)
          })
        })
      })
      return result
    },
  },
  mutations: {},
}
