import { dprInsert } from 'public/src/pages/common/customDetailDialogBff/utils/tools.js'
import { transformImg, debounce, getSessionStorage, setSessionStorage } from '@shein/common-function'
import { gsap } from 'gsap'

/**
 * 生成keyframes动画
 * @param {String} animationName 动画名称
 * @param {Boolean} isHorizontal 是否水平滚动
 * @param {Number} count 滚动次数(列表真实长度，使用此方法时需要在列表末尾加上一个第一个元素)
 * @param {Number} scrollTime 滚动时间
 * @param {Number} displayTime 静止展示时间
 */
export const buildKeyFramesAndReturnCSSInfo = (animationName, isHorizontal, count, scrollTime, displayTime) => {
  if (count < 2) return {}
  let animateList = []
  const translate = isHorizontal ? 'translateX' : 'translateY'
  const sumTime = (displayTime + scrollTime) * count // 总动画时长
  let sumPercentage = 0
  let translateValue = 0
  let cssStr = `@keyframes ${animationName}${count} { 0%{transform:${translate}(0%);}`
  animateList.push({ transform: `${translate}(0%)` })
  for (let index = 0; index < count; index++) {
    // 静止展示动画区段
    sumPercentage += displayTime / sumTime
    cssStr += `${(sumPercentage * 100).toFixed(2)}%{transform:${translate}(-${(translateValue * 100).toFixed(2)}%);}`
    animateList.push({ transform: `${translate}(-${(translateValue * 100).toFixed(2)}%)`, offset: sumPercentage.toFixed(4) })

    // 滚动动画区段
    sumPercentage += scrollTime / sumTime
    translateValue = (index + 1) / (count + 1)
    cssStr += `${(sumPercentage * 100).toFixed(2)}%{transform:${translate}(-${(translateValue * 100).toFixed(2)}%);}`
    animateList.push({ transform: `${translate}(-${(translateValue * 100).toFixed(2)}%)`, offset: sumPercentage.toFixed(4) })
  }
  cssStr += '}'

  return {
    animDuration: sumTime,
    cssStr,
    animateList,
  }
}

export function getMainImageUrl({ item, customServiceImgDomains, thirdPartyServiceImgDomains }) {
  const isCustomGood = Boolean(Object.keys(item.product?.customization_info || {}).length)
  if (isCustomGood) {
    const effectUrl = dprInsert({ img: item.product?.customization_info?.preview?.effects?.[0] || item.product?.goods_img || '', customServiceImgDomains, thirdPartyServiceImgDomains }) // effects 为空则不做替换
    return transformImg({ img: effectUrl })
  } else {
    return transformImg({ img: item.product?.goods_img })
  }
}

/**
 * 滚动到购物车商品
 * @param {String} id 购物车id
 * @param {Boolean} smooth 是否平滑滚动
 * @param {Number} rate 购物车商品最小展示率[0,1]
 * @param {Boolean} highlightAnimate 是否高亮动画
 */
export const scrollToCartItem = ({ id, rate = 0, timeout = 500, highlightAnimate = false } = {}) => {
  setTimeout(()=>{
    const target = document.querySelector(`.j-cart-item-${id}`)
    // eslint-disable-next-line no-console
    console.log(`scroll to .j-cart-item-${id}, and ${target}`)
    if (!target) return

    const { bottom: targetBottom, height: targetHeight, top: targetTop } = target.getBoundingClientRect()
    const cartHeaderHeight = document.querySelector('.cart-header')?.getBoundingClientRect().height

    // rate === 0，强制滚动
    if (rate !== 0) {
      const windowHeight = window.innerHeight

      const cartCheckoutHeight = document.querySelector('.j-cart-checkout')?.getBoundingClientRect().height

      const topVisible = targetBottom - cartHeaderHeight
      const bottomVisible = windowHeight - targetTop - cartCheckoutHeight

      // target * rate 在视口内，不执行滚动
      if (topVisible > (targetHeight * rate) && bottomVisible > (targetHeight * rate)) {
        // eslint-disable-next-line no-console
        return console.log(`target more then ${rate * 100}% in viewport`)
      }
    }

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    const { top, height } = target.getBoundingClientRect()
    const elCenter = top + height / 2
    const center = window.innerHeight / 2
    const scrollDistance = scrollTop - (center - elCenter)
    window.scrollTo({ top: scrollDistance, behavior: 'smooth' })
    if(highlightAnimate) {
      const cartItemContentDom = target?.querySelector('.cart-item-v2')
      if(cartItemContentDom) {
        const excuteAnimate = debounce({
          func: () => {
            triggerHighlightAnimation(cartItemContentDom, [cancelListener])
          },
          wait: 300
        })
        function cancelListener() {
          window.removeEventListener('scroll', excuteAnimate)
        }
        // 存在滚动行为，等待滚动结束后执行高亮动画
        window.addEventListener('scroll', excuteAnimate)

        // 兜底 手动执行一次，防止滚动事件未触发
        excuteAnimate()
      } else {
        // eslint-disable-next-line no-console
        console.log('.mshe-item not found')
      }
    }
  }, timeout)
}

export function triggerHighlightAnimation(element, callbacks) {
  if (!element) return
  callbacks?.forEach(cb => cb())
  // 添加高亮动画类
  element.classList.add('highlight-animate')
  // 让浏览器有时间渲染动画，然后移除类以便之后可以重新触发动画
  setTimeout(() => {
    element.classList.remove('highlight-animate')
  }, 800) // 动画总时间是 800ms
}

/**
 *
 * @param [Object] amount 金额
 * @return string
 */
export function amountWithSymbolTransform(amount = {}) {
  let text = amount?.amountWithSymbol ? amount.amountWithSymbol.replace(amount.priceShowStyle, `<span class="price-amount">${amount.priceShowStyle}</span>`) : ''
  return text ? `<span class="price-amount-decimal">${text}</span>` : ''
}

/**
 * 处理价格动画
 * @param {Object} newTotalPrice 新的价格对象
 * @param {Object} oldTotalPrice 旧的价格对象
 * @param {Function} callback 回调函数
 * @description 代码来自 actions.js 内部
 */
export const handleTotalPriceAnimate = (newTotalPrice, oldTotalPrice, callback) => {
  if (typeof window === 'undefined') {
    callback(newTotalPrice)
    return
  }
  const currencyRule = window.getCurrencyRule()
  const { decimal_place } = currencyRule
  const isAdd = +newTotalPrice.amount > +oldTotalPrice.amount
  // 完成动画花费的时长
  const duration = 300
  // 一帧的时长
  const TIME_PER_FRAME = 16.7
  // 动画帧数
  const frameCount = duration / TIME_PER_FRAME
  const priceDiff = newTotalPrice.amount - oldTotalPrice.amount
  // 每一帧的价格步长
  const priceStep = +(priceDiff / frameCount).toFixed(decimal_place)
  let currentPrice = +oldTotalPrice.amount
  let isAnimate = true
  let timer = setInterval(() => {
    if (isAnimate) {
      // 防止步长为0时导致死循环
      if (priceStep == 0) {
        currentPrice = +newTotalPrice.amount
        oldTotalPrice = newTotalPrice
      } else {
        currentPrice += priceStep
        if (isAdd) {
          if (currentPrice >= +newTotalPrice.amount) {
            currentPrice = +newTotalPrice.amount
            oldTotalPrice = newTotalPrice
          } else {
            oldTotalPrice = { ...oldTotalPrice, amount: currentPrice, amountWithSymbol: window.GB_transform_price_symbol(currentPrice, '', currencyRule) }
          }
        } else {
          if (currentPrice <= +newTotalPrice.amount) {
            currentPrice = +newTotalPrice.amount
            oldTotalPrice = newTotalPrice
          } else {
            oldTotalPrice = { ...oldTotalPrice, amount: currentPrice, amountWithSymbol: window.GB_transform_price_symbol(currentPrice, '', currencyRule) }
          }
        }
      }
    }
    callback && callback(oldTotalPrice) 
    if (currentPrice == newTotalPrice.amount) {
      clearInterval(timer)
      timer = null
    }
  }, TIME_PER_FRAME)
}

export const getCountryId = () => {
  let addressCookie = localStorage.getItem('addressCookie')
  let countryId = ''
  try {
    addressCookie = JSON.parse(addressCookie)
    countryId = addressCookie?.countryId ?? ''
  } catch {
    return countryId
  } 
  return countryId
}

export function getBrowserName() {
  let userAgent = navigator.userAgent
  if (userAgent.includes('Firefox')) {
    // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
    return 'Firefox'
  } else if (userAgent.includes('SamsungBrowser')) {
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
    return 'SamsungBrowser'
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
    return 'Opera'
  } else if (userAgent.includes('Edge')) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    return 'Microsoft Edge (Legacy)'
  } else if (userAgent.includes('Edg')) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
    return 'Microsoft Edge (Chromium)'
  } else if (userAgent.includes('Chrome')) {
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
    return 'Google Chrome or Chromium'
  } else if (userAgent.includes('Safari')) {
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
    return 'Apple Safari'
  } else {
    return 'unknown'
  }
}

// 删除动画
export const animationDelete = ($el) => {
  return new Promise(resolve => {
    if (!$el) resolve()
    const h = $el.clientHeight || 0
    $el.style.overflow = 'hidden'
    $el.style.opacity = 0
    // 默认 60fps/s
    const fps = 60
    // 花费时间
    const times = 0.5
    const distance = Math.ceil(h / (fps * times) )
    let transitionItem  = ($el, h, distance) => {
      window.requestAnimationFrame(() => {
        let vh = h - distance
        vh = vh < 0 ? 0 : vh
        $el.style.height = `${vh}px`
        if (vh > 0) return transitionItem($el, vh, distance)
        return resolve()
      })
    }
    transitionItem($el, h, distance)
  })
}
export function  getUrlQueryString(name, referer){
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const r = referer.match(reg)
  if (r != null) return unescape(r[2])
  return ''
}


/**
 * 购物车中促销活动强调 滚动、颜色变红、抖动
 * @param {Array} id 活动id
 * @param {Object} options 强调
 */
export const emphasizePromotionHandle = (id, options = {}) => {
  const { shake = false, changeColor = false, scroll = false } = options
  const containerDom = document.querySelector(`.j-promotion-header-${id}`)
  if (!containerDom) return

  // 是否滚动到标题处
  if (scroll) {
    const { top, height } = containerDom.getBoundingClientRect()
    const offsetCenter = window.innerHeight / 2
    const needScroll = (top + height) <= offsetCenter ? false : true
    if(needScroll){
      const hdHeight = document.querySelector('.j-cart-header-wrap')?.getBoundingClientRect()?.height || 0
      // 居中展示
      let scrollTopHeight = top - hdHeight - (offsetCenter + height) / 2
      if (window.scrollTo) {
        window.scrollTo({ top: scrollTopHeight, behavior: 'smooth' })
      }
    } else {
      if (window.scrollTo) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }

  // 颜色变红
  if (changeColor) {
    setTimeout(() => {
      const $target = containerDom.querySelector('.cart_item-tip')
      $target && $target.classList.add('cart_tip-red')
    }, 50)
  }

  // 是否抖动
  if (shake) {
    const $target = containerDom.querySelector('.cart_item-banner')
    $target && $target.classList.add('promotion-header-shake')
    setTimeout(() => {
      $target && $target.classList.remove('promotion-header-shake')
    }, 1000)
  }
}

/**
 * 购物车中促销活动强调 滚动、颜色变红、抖动
 * @param {Array} id 活动id
 * @param {Object} options 强调
 */
export const cartEmphasizePromotionAll = (ids = [], options = {}) => {
  ids.forEach((id, index) => {
    if (index > 0) options.scroll = false // 只滚动到第一个活动
    emphasizePromotionHandle(id, options)
  })
}

/**
 * 判断元素是否有部分存在于可视区域
 */
export const isElementPartiallyInViewport = (element) => {
  if (!element) return
  const rect = element.getBoundingClientRect()
  const winH = window.innerHeight || document.documentElement.clientHeight
  const winW = window.innerWidth || document.documentElement.clientWidth

  const vertInView = rect.top <= winH && rect.top + rect.height >= 0
  const horInView = rect.left <= winW && rect.left + rect.width >= 0

  return (vertInView && horInView)
}

/**
 * 在容器中将元素滚动到可视区域
 * @param {String} container - 容器
 * @param {String} target - 目标元素
 * @param {Function} callback - 回调函数
 */
export const scrollToElement = ({ container, target, callback }) => {
  if (container && target) {
    let targetPosition = target.offsetTop - container.offsetTop
    let scrollToPosition = targetPosition + (target.offsetHeight / 2) - (container.offsetHeight / 2)
    
    let maxScrollPosition = container.scrollHeight - container.clientHeight
    scrollToPosition = Math.max(0, Math.min(scrollToPosition, maxScrollPosition))
    if ('scrollBehavior' in document.documentElement.style) {
      container.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      })
    } else {
      container.scrollTop = scrollToPosition
    }
    callback?.({ target, container })
  }
}

/**
 * 滚动到购物车商品行并高亮
 */
export const scrollToItem = ({
  container = document,
  cartItemId,
}) => {
  const scrollEl = container instanceof Element ? container : document.querySelector(container)
  const target = scrollEl.querySelector(`.j-cart-item-${cartItemId}`)
  scrollToElement({
    container,
    target,
    callback({ target }) {
      triggerHighlightAnimation(target)
    }
  })
}

/**
 * 收藏动画
 */
export const animationM2W = (el, { to, duration = 0.3, delay = 500 } = {}) => {
  return new Promise(resolve => {
    if (!el || !to) return
    let tl = gsap.timeline({ duration })
    setTimeout(() => {
      const { width, height, top, left } = el.getBoundingClientRect()
      const { width: toWidth, height: toHeight, top: toTop, left: toLeft } = to.getBoundingClientRect()
      const cloneEl = el.cloneNode(true)
      cloneEl.style.position = 'fixed'
      cloneEl.style.zIndex = 9999999
      cloneEl.style.width = `${width}px`
      cloneEl.style.height = `${height}px`
      cloneEl.style.top = `${top}px`
      cloneEl.style.left = `${left}px`
      cloneEl.style.overflow = 'hidden'
      document.body.appendChild(cloneEl)
      tl.to(cloneEl, { 
        width: toWidth,
        height: toHeight,
        top: toTop,
        left: toLeft,
        onComplete: () => {
          document.body.removeChild(cloneEl)
          tl.kill()
          resolve()
        },
      })
    }, delay)
  })
}

export function updateSessionOrderCache(billno) {
  const cachedOrderIdList = getSessionStorage('CART_CACHE_ORDER_ID_LIST') || []
  const value = Array.from(new Set([...cachedOrderIdList, billno]))
  setSessionStorage({ key: 'CART_CACHE_ORDER_ID_LIST', value })
}

const getAlphafloat = (a, alpha) => {
  if (typeof a !== 'undefined') { return a / 255 }
  if ((typeof alpha != 'number') || alpha < 0 || alpha > 1){
    return 1
  }
  return alpha
}
/**
 *  const c1 = "#f80"
    const c2 = "#f808"
    const c3 = "#0088ff"
    const c4 = "#0088ff88"
    const c5 = "#98736"
    console.log(hexToRGBA(c1))   //  rgba(255, 136, 0, 1)
    console.log(hexToRGBA(c2))   //  rgba(255, 136, 0, 0.53125)
    console.log(hexToRGBA(c3))   //  rgba(0, 136, 255, 1)
    console.log(hexToRGBA(c4))   //  rgba(0, 136, 255, 0.53125)
    console.log(hexToRGBA(c5))   //  Uncaught Error: Invalid HEX
    console.log(hexToRGBA(c1, 0.5))   //  rgba(255, 136, 0, 0.5)
    console.log(hexToRGBA(c3, 0.5))   //  rgba(0, 136, 255, 0.5)
 * @param {String} hex 十六进制字符串
 * @param {Number} alpha 0 ~ 1
 * @returns 
 */
export const hexToRGBA = (hex, alpha) => {
  if (!/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) { throw new Error('Invalid HEX') }
  const chunkSize = Math.floor((hex.length - 1) / 3)
  const hexArr =  hex.slice(1)?.match(new RegExp(`.{${chunkSize}}`, 'g'))
  const [r, g, b, a] = hexArr.map((hexStr) => {
    return parseInt(hexStr.repeat(2 / hexStr.length), 16)
  })
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
}
