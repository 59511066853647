import getters from './getters'
import actions from './actions'
export default {
  namespaced: true,
  state: {
    locals: {},
    goodsId: '',
    isOutSite: false, // 是否为站外详情页 可以替代旧链路 outSiteGoodsDetail   // TODO NEW BFF 有新的了
    isFoldDetail: false, // 是否折叠详情
    currentLocalUnit: '', // 当前本地页面尺码状态
    currentLocalCountry: null, // 当前国家
    currentMySize: '', // 当前推荐尺码信息
    currentMyBraSize: '', // 当前推荐 Bra 尺码信息
    /** 当前goodsReady */
    goodsReady: false,
    firstPageDataReady: false, // 第一次进入商详页数据是否加载完毕 后序重新加载不改变
    topContentReady: false,
    colorsReady: false, // 颜色块是否加载完毕
    realTimeReady: false,
    /** 是否切换色块 */
    fromSwitchColor: false,
    /** 视频播放时长 */
    videoDuration: '',
    /** 是否播放vimeo状态 */
    isPlayVimeo: false,
    /** 补图首图比例 */
    fixedRatio: '3-4',
    /** 列表跳转缓存的图片 */
    cacheGoodsImgs: null,
    /** 列表跳转缓存的首屏信息 */
    cacheScreenData: null,
    /** 当前详情选择尺码信息 */
    externalSizeInfoIndex: {},
    /** 当前详情 skuCode */
    externalSkuCode: '',
    /** 详情页加车数据 */
    addToBagForm: {
      attrs: [],
      goods_id: '',
      quantity: 1,
      sku_id: ''
    },
    /** 当前选中的 sku 信息 */
    skuInfo: {},
    /** 当前商品是否支持快速发货 */
    quickShip: false,
    /** 切换颜色或推荐切换商品的加载状态 */
    loading: false,
    // sheinClubUserInfo: null,
    // 加车后推荐弹窗出现次数
    addCartRecommendCount: 0,
    // 加车失败提示
    addToBagFailMsg: '',
    detailFetchMoreInfoStatus: false, // 用来记录是否加载了第二批次数据
    detailPageOnloadStatus: false, // 用来标记详情页内跳转，以解决bottom组件懒加载的detailPageOnload问题  // TODO NEW BFF 有新的了
    saveStatus: false, // 收藏状态
    // 促销限制类型
    promotionLimitType: null,
    // getBeautyGoodsDesc: {}, // 美妆商品描述
    // relation_goods: [], // 关联商品 色块用的
    sizeRule: {}, // 尺码规则
    storeFollowInfo: {
      // abt命中才展示
      show: false,
      // 用户关注状态是否变更中
      followStateChanging: false,
      // 店铺关注数量
      followCount: '',
      // 店铺30日销量（tsp）
      salesVolume: '',
      // 用户关注状态，-1: 未登录，0: 未关注，1: 已关注
      userFollowState: -1,
      // 埋点信息
      abtAnaInfo: '',
    }, // 店铺关注信息
    // storeAndBrandDataReady: false, // 品牌系列店铺数据是否加载完毕
    notifyMe: false, // 缺货通知状态
    attribute: '', // 主商品属性信息
    mainAttribute: '', // 主商品信息
    oneClickPayVerifyDoneStatus: false, // 一键支付验证是否完成
    // 一键购信息
    oneClickPayState: {
      support: false,
      billInfo: null,
      stage: 1,
    },
    quickAddState: false, // 快速加车打开状态
    addCompleteStatus: false, // 快速加车成功回调
    addSkcCompleteStatus: false, // 当前商品是否加车成功过（切换skc要清空）
    descriptionStatus: false, // description 弹窗打开状态
    userManualStatus: false, // userManual 弹窗打开状态
    addToBagFail: false, // 是否显示加车失败提示
    addToBagSuc: false,
    addToBagLoading: false, // 是否显示加车 loading
    addToBagCompletedImg: false, // 快速加车完成后的动画状态
    addToBagMainGoods: {}, // 加车成功后的商品信息
    addLoadingAttachTop: false, // 大图加车的现实动画状态
    shoplookLightbox: false, // styleGallery 打开状态
    // 曝光的推荐数据集合
    exposeGoods: {
      status: false,
      ymal: [],
      similar: [],
    },
    // 曝光推荐集合状态
    initExposeStatus: {
      addBagStatus: 0,
      addWishStatus: 0,
      goodsId: ''
    },
    similarItemsStatus: false, // 站外找相似用的状态


    // 找相似浮层数据 TODO 新数据流推全可下线
    findSimilarFloatInfo: {
      show: false,
      products: [],
      from: '',
      filterSize: '',
      hasMoreProducts: false,
    },
    // 找相似浮层时间戳 TODO 新数据流推全可下线
    findSimilarApiTimeStamp: '',


    // 看了又看相似推荐列表
    similarItemsRecommend: [],
    // // 组合购推荐数据
    // frequentlyBoughtRecommend: [],
    // // otherOptions
    // otherOptionsRecommend: [],
    // 新组合购推荐数据
    comboBuyRecommend: [],
    // 是否满足组合购展示条件
    hasValidComboBuyRecommend: false,
    asyncScrollStatus: false, // 记录加车滚动状态
    quickAddLargeImgMode: false, // 大图模式状态
    // 同步加车弹窗的颜色切换
    syncAddCartColorSwitch: true,
    reportStatus: false, //report 展示状态
    reportStatusDsa: false, //report dsa 展示状态
    reportStatusOld: false, //reportOld 展示状态
    reportFormStatus: false, //reportForm 展示状态
    codPolicyStatus: false, //codPolicy 展示状态
    guaranteeStatus: false, // 安全购弹窗展示状态
    // recommendView: false, // 记录是否浏览过推荐
    detailOPos: false, // detailOPos 展示状态 // TODO NEW BFF 有新的了
    detailFPos: false, // detailFPos 展示状态  // TODO NEW BFF 有新的了
    recommendGoodsIdMap: Object.create(null),
    realTimeFirstReady: false, // 实时接口首次是否加载完毕,
    isLoginChange: false, // 是否登陆状态更改
    similarItemsList: null, // 相似商品 列表
    bestSellerList: null, // 热销商品
    getTheLookList: null, // 搭配推荐
    commentQuickAddState: false, // 评论页快速加车打开状态
    buyNowQuickAddState: false, // 立即购买快速加车打开状态
    attrsFoldQuickAddState: false, // 属性折叠快速加车打开状态
    buyMultipleQuickAddState: false,
    buyMultipleQuickAddFrom: 0, // 1: 点击N件到手价标签 2: 点击主加车按钮
    buyNowBtnLoading: false, // 立即购买loading
    closeCommentNeedScrollRecomend: false, // 评论关闭后是否需要滚动到推荐
    asyncScrollFromReview: false, //记录加车是否来自评论
    newGtlAndOutfitInfo: [], // 新的getTheLook数据
    buyBoxRecInfo: [], // buyBox推荐数据
    ymalsCateInfo: null, // ymals分类数据
    hideStrangeBelt: false, // 隐藏异性腰带
    performanceOpen: false, /** 性能模式 */
    customerShow: false, // 买家秀弹窗
    addBagRecData: [], // 加车推荐弹窗数据
    bffCouponInfo: null, // 交易中间层优惠券信息
    ofbwCatData: [], // ofbw分类数据
    ofbwListData: [], // ofbw列表数据
    addBagRecCouponListData: [], // 加车推荐优惠券列表数据
    fetchRecDataPromiseStatus: Promise.resolve('failure'), // 加车推荐接口请求状态 
    addOnitemGoodsId: '', // 缓存凑单弹窗里的凑单推荐商品id
    delayDestroy: false, // 延迟页面组件销毁
    newGtlInfoReady: false
  },
  getters,
  actions,
  mutations: {
    setLocals(state, payload) {
      if (payload) {
        // server
        return state.locals = {
          RESOURCE_SDK: payload.RESOURCE_SDK
        }
      }
      const {  RESOURCE_SDK } = gbCommonInfo
      state.locals = { RESOURCE_SDK }
    },
    updateHideStrangeBelt(state, payload) {
      state.hideStrangeBelt = payload
    },
    updateNewGtlInfo(state, payload) {
      state.newGtlAndOutfitInfo = payload
    },
    updateBuyBoxRecInfo(state, payload) {
      state.buyBoxRecInfo = payload
    },
    updateYmalsCateInfo(state, payload) {
      state.ymalsCateInfo = payload
    },
    updateRealTimeFirstReady(state, payload) {
      state.realTimeFirstReady = payload
    },
    updateRecommendGoodsIdMap(state, payload) {
      state.recommendGoodsIdMap = payload
    },
    // updateOtherOptionsRecommend(state, payload) {
    //   state.otherOptionsRecommend = payload
    // },
    // updateFrequentlyBoughtRecommend(state, payload) {
    //   state.frequentlyBoughtRecommend = payload
    // },
    updateSimilarItemsRecommend(state, payload) {
      state.similarItemsRecommend = payload
    },
    updateFindSimilarApiTimeStamp(state, payload) { // TODO vBFF_FS
      state.findSimilarApiTimeStamp = payload
    },
    updateFindSimilarFloatInfo(state, payload) { // TODO vBFF_FS
      state.findSimilarFloatInfo = payload
    },
    updateComboBuyRecommend(state, payload = {}) {
      state.comboBuyRecommend = payload?.list || []
      state.hasValidComboBuyRecommend = !!payload.show
    },
    updateInitExposeStatus(state, payload) {
      state.initExposeStatus = payload
    },
    updateExposeGoods(state, payload) {
      state.exposeGoods = payload
    },
    updateOneClickPayState(state, payload) {
      state.oneClickPayState = payload
    },
    updateOneClickPayVerifyDoneStatus(state, payload) {
      state.oneClickPayVerifyDoneStatus = payload
    },
    updateMainAttribute(state, payload) {
      state.mainAttribute = payload
    },
    updateAttriBute(state, payload) {
      state.attribute = payload
    },
    updateNotifyMe(state, payload) {
      state.notifyMe = payload
    },
    // updateStoreAndBrandDataReady(state, payload) {
    //   state.storeAndBrandDataReady = payload
    // },
    updateStoreFollowInfo(state, payload) {
      // console.log('TESTCODE: initStoreFollowInfo', payload)
      state.storeFollowInfo = payload
    },
    updatePromotionLimitType(state, payload) {
      state.promotionLimitType = payload
    },
    updateAddToBagFailMsg(state, payload) {
      state.addToBagFailMsg = payload
    },
    updateAddCartRecommendCount(state, payload) {
      state.addCartRecommendCount = payload
    },
    updateGoodsReady(state, payload) {
      state.goodsReady = payload
    },
    updateFirstPageDataReady(state, payload) {
      state.firstPageDataReady = payload
    },
    updateRealTimeReady(state, payload) {
      state.realTimeReady = payload
    },
    updateTopContentReady(state, payload) {
      state.topContentReady = payload
    },
    updateColorsReady(state, payload) {
      state.colorsReady = payload
    },
    updateIsOutSite(state, payload) {  // TODO NEW BFF 有新的了
      state.isOutSite = payload
    },  
    updateIsFoldDetail(state, payload){
      state.isFoldDetail = payload
    },
    updateFromSwitchColor(state, payload) {
      state.fromSwitchColor = payload
    },
    updateVideoDuration(state, payload) {
      state.videoDuration = payload
    },
    updatePlayVimeoStatus(state, payload) {
      state.isPlayVimeo = payload
    },
    updateCurrentLocalUnit(state, payload) {
      state.currentLocalUnit = payload
    },
    updateCurrentLocalCountry(state, payload) {
      state.currentLocalCountry = payload
    },
    updateFixedRatio(state, payload) {
      state.fixedRatio = payload
    },
    updateCurrentMySize(state, payload) {
      state.currentMySize = payload
    },
    updateCurrentMyBraSize(state, payload) {
      state.currentMyBraSize = payload
    },
    updateCacheGoodsImgs(state, payload) {
      state.cacheGoodsImgs = payload
    },
    updateCacheScreenData(state, payload) {
      state.cacheScreenData = payload
    },
    updatePerformance(state, payload) {
      state.performanceOpen = payload
    },
    updateExternalSizeInfoIndex(state, payload) {
      state.externalSizeInfoIndex = payload
    },
    updateExternalSkuCode(state, payload) {
      state.externalSkuCode = payload
    },
    updateAddToBagForm(state, payload) {
      state.addToBagForm = payload
    },
    updateSkuInfo(state, payload) {
      state.skuInfo = payload
    },
    updateQuickShip(state, payload) {
      state.quickShip = payload
    },
    updateLoading(state, payload) {
      state.loading = payload
    },
    // updateSheinClubUserInfo(state, payload) {
    //   state.sheinClubUserInfo = payload
    // },
    updateSaveStatus(state, payload) {
      state.saveStatus = payload
    },
    updateDetailPageOnloadStatus(state, payload) { // TODO NEW BFF 有新的了
      state.detailPageOnloadStatus = payload
    },
    updateDetailFetchMoreInfoStatus(state, payload) {
      state.detailFetchMoreInfoStatus = payload
    },
    // updateRelationGoods(state, payload) {
    //   state.relation_goods = payload
    // },
    updateSizeRule(state, payload) {
      state.sizeRule = payload
    },
    // updateGetTheLookInfo(state, payload) {
    //   state.getTheLookInfo = payload
    // },
    // updateGetBeautyGoodsDesc(state, payload) {
    //   state.getBeautyGoodsDesc = payload
    // },
    updateQuickAddState(state, payload) {
      state.quickAddState = payload
    },
    updateAddCompleteStatus(state, payload) {
      state.addCompleteStatus = payload
    },
    updateAddSkcCompleteStatus(state, payload) {
      state.addSkcCompleteStatus = payload
    },
    updateDescriptionStatus(state, payload) {
      state.descriptionStatus = payload
    },
    updateUserManualStatus(state, payload) {
      state.userManualStatus = payload
    },
    updateAddToBagFail(state, payload) {
      state.addToBagFail = payload
    },
    updateaAddToBagSuc(state, payload) {
      state.addToBagSuc = payload
    },
    updateAddToBagLoading(state, payload) {
      state.addToBagLoading = payload
    },
    updateAddToBagCompletedImg(state, payload) {
      state.addToBagCompletedImg = payload
    },
    updateAddToBagMainGoods(state, payload) {
      state.addToBagMainGoods = payload
    },
    updateAddLoadingAttachTop(state, payload) {
      state.addLoadingAttachTop = payload
    },
    updateShoplookLightbox(state, payload) {
      state.shoplookLightbox = payload
    },
    updateSimilarItemsStatus(state, payload) {
      state.similarItemsStatus = payload
    },
    updateAsyncScrollStatus(state, payload) {
      state.asyncScrollStatus = payload
    },
    updateQuickAddLargeImgMode(state, payload) {
      state.quickAddLargeImgMode = payload
    },
    updateSyncAddCartColorSwitch(state, payload) {
      state.syncAddCartColorSwitch = payload
    },
    updateReportStatus(state, payload) {
      state.reportStatus = payload
    },
    updateReportStatusOld(state, payload) {
      state.reportStatusOld = payload
    },
    updateReportStatusDsa(state, payload) {
      state.reportStatusDsa = payload
    },
    updateReportFormStatus(state, payload) {
      state.reportFormStatus = payload
    },
    updateCodPolicyStatus(state, payload) {
      state.codPolicyStatus = payload
    },
    updateGuaranteeStatus(state, payload){
      state.guaranteeStatus = payload
    },
    // updateRecommendView(state, payload) {
    //   state.recommendView = payload
    // },
    updateDetailOPos(state, payload) { // TODO NEW BFF 有新的了
      state.detailOPos = payload
    },
    updateDetailFPos(state, payload) {
      state.detailFPos = payload
    },
    updateIsLoginChange(state, payload) {
      state.isLoginChange = payload
    },
    updateSimilarItemsList(state, payload) {
      state.similarItemsList = payload
    },
    updateBestSellerList(state, payload) {
      state.bestSellerList = payload
    },
    updateGetTheLookList(state, payload) {
      state.getTheLookList = payload
    },
    updateCommentQuickAddState(state, payload) {
      state.commentQuickAddState = payload
    },
    updateBuyNowQuickAddState(state, payload) {
      state.buyNowQuickAddState = payload
    },
    updateAddBagRecData(state, payload) {
      state.addBagRecData = payload
    },
    updateBffCouponInfo(state, payload) {
      state.bffCouponInfo = payload
    },
    updateOfbwCatData(state, payload) {
      state.ofbwCatData = payload
    },
    updateOfbwListData(state, payload) {
      state.ofbwListData = payload
    },
    updateAddBagRecCouponListData(state, payload) {
      state.addBagRecCouponListData = payload
    },
    updateFetchRecDataPromiseStatus(state, payload) {
      state.fetchRecDataPromiseStatus = payload
    },
    updateAddOnitemGoodsId(state, payload) {
      state.addOnitemGoodsId = payload
    },
    updateAttrsFoldQuickAddState(state, payload) {
      state.attrsFoldQuickAddState = payload
    },
    updateBuyMultipleQuickAddState(state, payload) {
      state.buyMultipleQuickAddState = payload.show || false
      state.buyMultipleQuickAddFrom = payload.from || 0
    },
    updateBuyNowBtnLoading(state, payload) {
      state.buyNowBtnLoading = payload
    },
    updateAsyncScrollFromReview(state, payload) {
      state.asyncScrollFromReview = payload
    },
    updateCloseCommentNeedScrollRecomend(state, payload) {
      state.closeCommentNeedScrollRecomend = payload
    },
    updateCustomerShow(state, payload) {
      state.customerShow = payload
    },
    updatedDelayDestroy(state, payload) {
      state.delayDestroy = payload
    },
    updateNewGtlInfoReady(state, payload) {
      state.newGtlInfoReady = payload
    }
  },
}
