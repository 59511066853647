export const detailTPMHandle = ({ detail, currency }) => {
  requestIdleCallback(() => {
    window.TPM?.publish?.('viewcontent', {
      id: detail.goods_id,
      sku: detail.goods_sn,
      spu: detail.product_relation_id,
      category: detail.cat_id,
      price: detail?.salePrice?.amount,
      usd_price: detail?.salePrice?.usdAmount,
      discount: detail?.unit_discount,
      img: detail.goods_img,
      url_name: detail.goods_url_name,
      currency: currency
    })
  })
}
